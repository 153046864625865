<template>
  <v-col class="pa-0 ma-0 py-0 my-0" cols="12" md="12" sm="12" v-resize="onResize"> {{ ActDescBtn }}

    <!--  Modales -->
    <!-- ------------------------------------------------------   alerta -->
    <v-snackbar v-model="aalert.estado" :color="aalert.color" :type="aalert.type" id="alerMov" :vertical="true"
      :timeout="120000" top="top">
      {{ aalert.nombre }}
      <v-btn dark text @click="aalert.estado = false">Close</v-btn>
    </v-snackbar>
    <!-- ------------------------------------------------------  fn alerta -->


    <!-- ------------------------------------------------------ Modal- crear Producto-->
    <v-dialog transition="fab-transition" label="Modal agregar producto" v-model="dialogCrearProducto" persistent
      max-width="90%" max-height="80%">
      <CrearProducto @EmitCrearProducto="RecCrearProducto" />
    </v-dialog>
    <!-- ------------------------------------------------------ fn Modal- crear producto-->

    <!-- ------------------------------------------------------ Modal- nueva Produecto-->
    <v-dialog transition="fab-transition" label="Modal agregar producto" v-model="dialogAddProducto" persistent
      max-width="90%" max-height="80%">

      <AgregarProducto :statusFoms="dialogAddProducto" ref="RefAgregarProducto" @EmitAgregarProducto="RecAgregarProducto" :unidadminima="PropsUnidadminima" />
    </v-dialog>
    <!-- ------------------------------------------------------ fn Modal- nueva producto-->




    <!-------------------------------------------------------- Modal Producto Sugerido -->
    <v-dialog transition="fab-transition" label="Modal agregar factura" v-model="dialogVerOtroAlmacen" persistent
      max-width="90%" max-height="80%">
      <v-card>
        <v-toolbar flat color="indigo" dense>

          <i style="font-size: 20px;" class=""> </i>
          <v-toolbar-title style="color:white;" class="font-weight-light"> <b>LISTADO DE ALMACENES</b></v-toolbar-title>
          <i style="font-size: 20px;" class=""> </i>

          <v-spacer></v-spacer>
          <v-btn color="grey darken-4" fab small @click="dialogVerOtroAlmacen = false">
            <i style="font-size: 20px; color:white;" class="fa fa-close"></i>
          </v-btn>
        </v-toolbar>

        <v-card-text>
          <br>

          <v-row>





            <v-col v-if="OtroAlmacen != null" class="pa-0 ma-0 py-0 my-0 black--text" cols="12" md="12" sm="12">
              <p class="pa-0 ma-0 py-0 my-0" style="border: 1px dashed  #616161;"><b>PRODUCTOS:</b>
                {{ OtroAlmacen.unidadProducto.productos.descripcion }}</p>
              <p class="pa-0 ma-0 py-0 my-0" style="border: 1px dashed  #616161;">
                <b>COSTO:</b>{{ currencyFormatter(OtroAlmacen.unidadProducto.costo) }}
              </p>

              <v-card style="border: 1px solid  #616161;">
                <v-data-table :headers="[
                  { text: 'ALMACEN', value: 'almacen', sortable: false, class: ['no-gutters black--text'] },
                  { text: 'EXISTENCIA', value: 'existencion', sortable: false, class: ['no-gutters black--text'] },
                  { text: 'VENTAS', value: 'venta', sortable: false, class: ['no-gutters black--text'] },
                  { text: 'CANT. PENDIENTE A ENTREGAR', value: 'pendienteEntregar', sortable: false, class: ['no-gutters black--text'] },
                ]" dense :items="OtroAlmacen.ListaAlmacen" :items-per-page="15">

                  <template v-slot:item.venta="props">
                    <span style="font-size:13px;">{{ currencyFormatter(props.item.venta) }}</span>
                  </template>

                  <template v-slot:item.almacen="props">
                    <span style="font-size:13px;">{{ props.item.almacen }}</span>
                  </template>

                  <template v-slot:item.existencion="props">
                    <span style="font-size:13px;">{{ props.item.existencion }}</span>
                  </template>

                   <template v-slot:item.pendienteEntregar="props">
                    <span style="font-size:13px;">{{ currencyFormatter(props.item.pendienteEntregar) }}</span>
                  </template>

                </v-data-table>
              </v-card>
            </v-col>

          </v-row>


        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
        </v-card-actions>

      </v-card>
    </v-dialog>
    <!-------------------------------------------------------- fn Modal Producto Sugerido -->

    <!-- ------------------------------------------------------  Modal Loader -->

    <v-dialog v-model="Reparaloading" persistent width="300">
      <v-card color="grey darken-2" dark>
        <v-card-text>
          <br>
          <v-progress-linear indeterminate color="white" class="mb-0"></v-progress-linear>
        </v-card-text>
      </v-card>
    </v-dialog>

    <!-- ------------------------------------------------------ fn Modal loader -->


    <!-- fn Modales -->


    <!--------------------------------------------------- tb add producto  -->
    <v-card>

      <v-toolbar flat dense color="#BDBDBD">

        <v-card-actions>


          <v-btn-toggle rounded dense>
            <v-tooltip top v-if="CelldAddProducto == true">
              <template v-slot:activator="{ on }">
                <!-- OpenAddProducto -->
                <v-btn v-on="on" dark @click.prevent="funcionValidaPermisoPv('AccBtnAgregarProductoTabla')" color="green">
                  <i style="font-size: 20px;" class="fa fa-plus-square"></i><!-- fab x-small dark  -->
                  Agregar Productos
                </v-btn>


              </template>
              <span>Agregar Productos</span>
            </v-tooltip>



            <!--CelldCrearProducto == true  -->
            <v-tooltip v-if="false" top>
              <template v-slot:activator="{ on }">

                <v-btn v-on="on" dark color="#90A4AE" @click.prevent="dialogCrearProducto = !dialogCrearProducto">
                  <i style="font-size: 28px;" class="fa fa-file-o"> </i>
                  Crear Producto
                </v-btn>

              </template>
              <span>Crear Productos</span>
            </v-tooltip>



          </v-btn-toggle>

        </v-card-actions>


        <!-- id="TbBord"   <v-toolbar-title class="font-weight-light"><v-card-title>FACTURAS INICIALES</v-card-title></v-toolbar-title> -->
        
      </v-toolbar>
      <v-data-table @current-items="PosicionFocus" class="elevation-1" id="TbBord" dense :headers="HeaderDate"
        :items="ListProd" item-key="indx"  :footer-props="arrayPropiedases" 
        @pagination="cargarPaginacionAll" :items-per-page="TbPaginator.itemsPerPage" :page.sync="TbPaginator.page" :pageCount="TbPaginator.pageCount"
       :loading="loaderTabla"
        >

        <template v-slot:item.valor="props">
          
        <span v-if="PropsFacturaProveedor == true" style="display:none;">{{ props.item.indx =
          ListProd.indexOf(props.item)}} 
          <!-- {{calcularImporte(props.item)}}   -->
          </span>
          
          <span v-if="PropsFacturaProveedor == false" style="display:none;">{{ props.item.indx =
          ListProd.indexOf(props.item)
          }} 
          <!-- {{ props.item.valor = (props.item.cantidad *
            (props.item.costo + props.item.impuesto)) - calcularDescuento(props.item)
            }}  -->
            </span>

          <v-text-field v-if="PropsDecimalPlaces==2" label="" id="formdisabledInput" style="font-size:13px;" dense disabled hide-details outlined
            autocomplete="off" :value="currencyFormatter(props.item.valor)">
          </v-text-field>

          <AutoNumericNuevoWithOptions v-if="PropsDecimalPlaces!=2" :options="{ decimalPlaces: PropsDecimalPlaces }" id="formdisabledInput"  disabled label="" style="font-size:13px;" dense  hide-details outlined
            autocomplete="off" :value="props.item.valor">
          </AutoNumericNuevoWithOptions>

        </template>


        <template v-slot:item.action="{ item }">


          <v-btn id="formdisabledInput"  :disabled="PropsInputDisabled" v-if="btnEliminar == true" fab @click.prevent="eliminarItem(item)" x-small color="red darken-3"><i
              style="font-size: 20px;" class="fa fa-trash-o"></i></v-btn>

          <v-tooltip top v-if="btnVerAlm == true">
            <template v-slot:activator="{ on }">
              <v-btn v-on="on" fab @click.prevent="VerOtroAlmacenItem(item)" x-small color="yellow accent-4"><i
                  style="font-size: 20px;" class="fa fa-eye"></i></v-btn>
            </template>
            <span>Ver otros Almacenes</span>
          </v-tooltip>

        </template>


        <template v-slot:item.factura="props">
          <!-- <span style="font-size:13px;" >{{props.item.producto.descripcion}} :value="props.item.factura"  </span> -->
          <v-text-field label="" id="formdisabledInput" style="font-size:13px;" dense hide-details outlined
            autocomplete="off" v-model.number="props.item.factura"
            @blur="props.item.noFactura=props.item.factura+''"
            >
          </v-text-field>

        </template>


        <template v-slot:item.unidadProducto="props">
          <span v-if="props.item.unidadProducto != null && props.item.unidadProducto.hasOwnProperty('costo')"
            style="display:none;">{{ props.item.unidadProducto.codigoProducto =
            GetCodigoProdUni(props.item.unidadProducto) }}</span>
          <!--@focus=""@change="" @blur="" v-on:keydown="OnKeyupAddRowProduc(props.item)" @keyup="KeyupAddRowProducCombox"-->
          <v-combobox hide-details id="formdisabledInput" :disabled="PropsInputDisabled" :ref="'codigo' + props.item.indx" v-model="props.item.unidadProducto" label=""
            outlined dense autocomplete="off" :items="RproductoBuscados" item-text="codigoProducto"
            v-on:change="changeSelectProduc(props.item)" @blur="BlurTabSelectProduc(props.item)"
            v-on:keyup.down="OnKeyupAddRowProducCombox(props.item)" style="font-size: 13px;">

            <template v-slot:selection="{ attrs, item, parent, selected }">

              <span class="black--text"
                v-if="props.item.unidadProducto != null && !props.item.unidadProducto.hasOwnProperty('codigoProducto')">{{
                item
                }}</span>
              <span class="black--text"
                v-if="props.item.unidadProducto != null && props.item.unidadProducto.hasOwnProperty('codigoProducto')">{{
                item.codigoProducto
                }}</span>

            </template>

            <template slot="item" slot-scope="item" margin-top="50px">
              <span class="black--text"> {{ item.item.productos.descripcion }}-{{ item.item.unidades.descripcion }} </span>
            </template>

          </v-combobox>
        </template>

        <template v-slot:item.producto.descripcion="props">
          <span v-if="props.item.producto!=null" style="display:none;">{{ props.item.propsDesProdUnid = props.item.producto.descripcion+" "+props.item.unidad.descripcion}}</span>
          <v-text-field id="formdisabledInput"  :disabled="PropsInputDisabled" v-if="props.item.producto!=null" label=""  style="font-size:13px;" dense
            disabled hide-details outlined autocomplete="off" :value="props.item.propsDesProdUnid">
          </v-text-field>
        </template>

        
        <template v-slot:item.fechavencimiento="props">
          <v-text-field :disabled="PropsInputDisabled" v-if="props.item.producto != null && PropsDevolucion" id="formdisabledInput" dense hide-details
            style="font-size:13px;" outlined type="date" label="" v-model="props.item.fechavencimiento"
            :ref="'fechavencimiento' + props.item.indx">
          </v-text-field>
          <v-text-field v-if="props.item.producto != null && !PropsDevolucion" id="formdisabledInput" dense hide-details
            style="font-size:13px;" outlined type="date" label="" @blur="fechaVencimientoAVencer(props.item)" 
            :disabled="!props.item.producto.controlVencimiento" v-model="props.item.fechavencimiento"
            :ref="'fechavencimiento' + props.item.indx"
            >
          </v-text-field>
        </template>


        <!--@input="calcularLineas(props.item)"  -->
        <template v-slot:item.cantidadpedida="props">
          <AutoNumeric label="" style="font-size:13px;" id="formdisabledInput" disabled dense hide-details outlined
            autocomplete="off" v-model.number="props.item.cantidadpedida">
          </AutoNumeric>
        </template>


        <!--@input="calcularLineas(props.item)" calcularImporte(props.item) -->
        <template v-slot:item.cantidad="props">

          <AutoNumeric v-if="PropsDecimalPlaces==2" id="formdisabledInput"  :disabled="PropsInputDisabled" label="" style="font-size:13px;" dense :ref="'cantidad' + props.item.indx" hide-details outlined
            autocomplete="off" v-model.number="props.item.cantidad"
            v-on:keydown.down="OnKeyupAddRowProducCombox(props.item)" @blur="calcularTotales"
            v-on:input="VerificarCantidad(props.item)"
            @input="calcularImporte(props.item)" 
            >
          </AutoNumeric>

          <AutoNumericNuevoWithOptions v-if="PropsDecimalPlaces!=2" :options="{ decimalPlaces: PropsDecimalPlaces }" id="formdisabledInput"  :disabled="PropsInputDisabled" label="" style="font-size:13px;" dense :ref="'cantidad' + props.item.indx" hide-details outlined
            autocomplete="off" v-model.number="props.item.cantidad"
            v-on:keydown.down="OnKeyupAddRowProducCombox(props.item)" @blur="calcularTotales"
            v-on:input="VerificarCantidad(props.item)"
            @input="calcularImporte(props.item)" >
          </AutoNumericNuevoWithOptions>

        </template>

        <!-- @input="calcularLineas(props.item)" -->
        <!-- v-on:blur="calcularImporte(props.item)" -->
        <template v-slot:item.costo="props">
          <AutoNumeric v-if="PropsDecimalPlaces==2" label="" id="formdisabledInput" :disabled="DisableCosto" style="font-size:13px;" dense
            hide-details outlined autocomplete="off" v-model.number="props.item.costo"
            v-on:keydown.down="OnKeyupAddRowProducCombox(props.item)" @blur="calcularTotales"
            v-on:blur="blurCostoValidarPrecioCosto(props.item)"
            v-on:input="setCostoPrecioAnteKeyinput(props.item)"
             >
          </AutoNumeric>

          <AutoNumericNuevoWithOptions v-if="PropsDecimalPlaces!=2" :options="{ decimalPlaces: PropsDecimalPlaces }" label="" id="formdisabledInput" :disabled="DisableCosto" style="font-size:13px;" dense
            hide-details outlined autocomplete="off" v-model.number="props.item.costo"
            v-on:keydown.down="OnKeyupAddRowProducCombox(props.item)" @blur="calcularTotales"
            v-on:blur="blurCostoValidarPrecioCosto(props.item)"
            v-on:input="setCostoPrecioAnteKeyinput(props.item)" >
          </AutoNumericNuevoWithOptions>

        </template>

        <template v-slot:item.itbis="props">
          <AutoNumeric label="" id="formdisabledInput" disabled style="font-size:13px;" dense hide-details outlined
            autocomplete="off" v-model.number="props.item.impuesto">
          </AutoNumeric>
          <!-- <AutoNumeric label="" id="formdisabledInput" disabled style="font-size:13px;" dense hide-details outlined
            autocomplete="off" v-model.number="props.item.itbis">
          </AutoNumeric> -->
        </template>

        <!--@input="calcularLineas(props.item)" @input="calcularImporte(props.item)" -->
        <template v-slot:item.impuesto="props">
          <AutoNumeric v-if="PropsDecimalPlaces==2" id="formdisabledInput"  :disabled="PropsInputDisabled" label="" style="font-size:13px;" dense hide-details outlined autocomplete="off"
            v-model.number="props.item.impuesto" v-on:keydown.down="OnKeyupAddRowProducCombox(props.item)"
            @blur="calcularTotales"
            v-on:blur="blurItbsImpuestoValidarCalculo(props.item)" >
          </AutoNumeric>

          <AutoNumericNuevoWithOptions v-if="PropsDecimalPlaces!=2" :options="{ decimalPlaces: PropsDecimalPlaces }" id="formdisabledInput"  :disabled="PropsInputDisabled" label="" style="font-size:13px;" dense hide-details outlined autocomplete="off"
            v-model.number="props.item.impuesto" v-on:keydown.down="OnKeyupAddRowProducCombox(props.item)"
            @blur="calcularTotales"
            v-on:blur="blurItbsImpuestoValidarCalculo(props.item)" >
          </AutoNumericNuevoWithOptions>
        </template>

        <template v-slot:item.existencia="props">
          <!-- <span>{{props.item.existencia}}</span> -->
          <v-text-field label="" id="formdisabledInput" style="font-size:13px;" dense disabled hide-details outlined
            autocomplete="off" :value="props.item.existencia">
          </v-text-field>
        </template>


        <template v-slot:item.oferta="props">
          <AutoNumeric label="" style="font-size:13px;" dense hide-details outlined autocomplete="off"
            v-model.number="props.item.oferta" @blur="calcularTotales">
          </AutoNumeric>
        </template>

        <template v-slot:item.ofertaSelect="props">


          <v-checkbox id="formdisabledInput"  :disabled="PropsInputDisabled" class="px-2" color="teal" label="" v-model="props.item.ofertaSelect" width="50"
            @change="isOferta(props.item)" hide-details>
            <template v-slot:label>
              <strong v-if="props.item.ofertaSelect == true" style="color:#000000; font-size:13px;"> Si </strong>
              <strong v-if="props.item.ofertaSelect == false" style="color:#000000; font-size:13px;"> No </strong>
            </template>
          </v-checkbox>
        </template>



        <template v-slot:item.devolucion="props">
          <AutoNumeric label="" style="font-size:13px;" dense hide-details outlined :disabled="DisableDevolucion"
            autocomplete="off" v-model.number="props.item.devolucion" @blur="calcularTotales">
          </AutoNumeric>
        </template>



        <template v-slot:item.venta="props">
          <span style="display:none;">{{ props.item.venta = props.item.hasOwnProperty("venta") && props.item.venta != null ? props.item.venta : 0  }}</span>
          <AutoNumeric label="" disabled id="formdisabledInput"  style="font-size:13px;" dense hide-details outlined
            autocomplete="off" v-model.number="props.item.venta">
          </AutoNumeric>
        </template>
<!-- @input="calcularImporte(props.item)" --> 
<!-- v-on:blur="calcularImporte(props.item)" -->
        <template v-slot:item.precio="props">
          <AutoNumeric  id="formdisabledInput"  :disabled="PropsInputDisabled" label="" v-if="props.item.hasOwnProperty('precio') && PropsDecimalPlaces==2" 
            style="font-size:13px;" dense hide-details outlined autocomplete="off" 
            v-model.number="props.item.precio" @blur="calcularTotales"
            v-on:blur="blurPrecioValidarPrecioCosto(props.item)"
            v-on:input="props.item.precioAnte=props.item.precio" >
          </AutoNumeric>

           <AutoNumericNuevoWithOptions  :options="{ decimalPlaces: PropsDecimalPlaces }" id="formdisabledInput"  :disabled="PropsInputDisabled" label="" v-if="props.item.hasOwnProperty('precio')&&PropsDecimalPlaces!=2" 
            style="font-size:13px;" dense hide-details outlined autocomplete="off"
            v-model.number="props.item.precio" @blur="calcularTotales"
            v-on:blur="blurPrecioValidarPrecioCosto(props.item)"
            v-on:input="props.item.precioAnte=props.item.precio" >
          </AutoNumericNuevoWithOptions>
        </template>


        <template v-slot:item.descuento="props">
          <AutoNumeric v-if="PropsDecimalPlaces==2" id="formdisabledInput"  :disabled="PropsInputDisabled" label="" style="font-size:13px;" dense hide-details outlined autocomplete="off"
            v-model.number="props.item.descuento" @blur="calcularTotales"
            @input="calcularImporte(props.item)" >
          </AutoNumeric>

           <AutoNumericNuevoWithOptions v-if="PropsDecimalPlaces!=2"  :options="{ decimalPlaces: PropsDecimalPlaces }" id="formdisabledInput"  :disabled="PropsInputDisabled" label="" style="font-size:13px;" dense hide-details outlined autocomplete="off"
            v-model.number="props.item.descuento" @blur="calcularTotales"
            @input="calcularImporte(props.item)" >
          </AutoNumericNuevoWithOptions>


          
        </template>

        <template v-slot:item.descuentoPorciento="props">
          <!-- <span>{{currencyFormatter(props.item.descuentoPorciento)}}</span> class="mt-0"    -->

<!-- @change="calcularTotales" -->
          <v-switch id="formdisabledInput"  :disabled="PropsInputDisabled" v-model="props.item.descuentoPorciento" label="Activo" color="success" hide-details dense
            v-on:change="calcularImporte(props.item)">

            <template v-slot:label>
              <strong v-if="props.item.descuentoPorciento == true" style="color:#000000; font-size: 13px; "> % </strong>
              <strong v-if="props.item.descuentoPorciento == false" style="color:#000000; font-size: 13px; "> $
              </strong>
            </template>

          </v-switch>


        </template>


        <template v-slot:item.almacen="props">
          <v-combobox id="formdisabledInput"  :disabled="PropsInputDisabled" :ref="'Almacen' + props.item.indx" style="font-size:13px;" hide-details autocomplete="off" dense
            outlined label="" :items="Ralmacen" item-text="descripcion" v-model="props.item.almacen"
            @focus="CargarRalmacen">
            <template v-slot:selection="{ attrs, item, parent, selected}">
            <span class="black--text" v-if="item.hasOwnProperty('descripcion')" >{{item.descripcion.trim()}}</span>
           </template>
          </v-combobox>
        </template>

        <template v-slot:body.append v-if="windowSize.width > 600">
          <tr>
            <td class="border-top-bottom"><strong>Cantidad:{{ ListProd.length }}</strong></td>
            <td class="border-top-bottom" v-if="CelldFactura == true"><strong></strong></td>
            <td class="border-top-bottom" v-if="CelldAlmacen == true"><strong></strong></td>
            <td class="border-top-bottom"><strong class="d-flex justify-end"
                style="font-size:15px; color: #00838F;">Total:</strong></td>
            <td class="border-total" v-if="CelldFechav == true"><strong class="d-flex justify-center"
                style="font-size:15px; color: #00838F;"> </strong></td>

            <td class="border-total" v-if="CellCantpedida == true"><strong class="d-flex justify-center"
                style="font-size:15px; color: #00838F;"> </strong></td>
            <td class="border-total"><strong class="d-flex justify-center" style="font-size:15px; color: #00838F;">
              </strong></td>
            <td class="border-top-bottom" v-if="CelldOferta == true"><strong></strong></td>
            <td class="border-total" v-if="CelldExistenc == true"><strong class="d-flex justify-center"
                style="font-size:15px; color: #00838F;"> </strong></td>
            <td class="border-top-bottom" v-if="CelldOfertaSelect == true"><strong></strong></td>
            <td class="border-total" v-if="CelldVenta == true"><strong class="d-flex justify-center"
                style="font-size:15px; color: #00838F;"> </strong></td>
            <td class="border-total" v-if="Celldprecio == true"><strong class="d-flex justify-center"
                style="font-size:15px; color: #00838F;"> </strong></td>
            <td class="border-top-bottom"><strong></strong></td>
            <td class="border-top-bottom" v-if="CelldItbis == true"><strong></strong></td>
            <td class="border-total" v-if="CelldImpuesto == true"><strong class="d-flex justify-center"
                style="font-size:15px; color: #00838F;"> 
                  <!-- {{ RedondeoValorCantRed(TotalProd.impuesto,PropsDecimalPlaces ) }}  -->
                {{ currencyFormatterCant(TotalProd.impuesto,PropsDecimalPlaces ) }} 
              </strong></td>
            <td class="border-total" v-if="CelldDescuento == true"><strong class="d-flex justify-center"
                style="font-size:15px; color: #00838F;"> 
                <!-- {{ RedondeoValorCantRed(TotalProd.descuento,PropsDecimalPlaces )  }}  -->
                {{ currencyFormatterCant(TotalProd.descuento,PropsDecimalPlaces ) }} 
              </strong></td>
            <td class="border-top-bottom" v-if="CelldDesPociento == true"><strong></strong></td>
            <td class="border-top-bottom" v-if="CelldDevolucion == true"><strong></strong></td>
            <td class="border-total" v-if="CelldImporte == true"><strong class="d-flex justify-center"
                style="font-size:15px; color: #00838F;"> 
                <!-- {{ RedondeoValorCantRed(TotalProd.importe,PropsDecimalPlaces )  }}  -->
                {{ currencyFormatterCant(TotalProd.importe,PropsDecimalPlaces ) }} 

              </strong></td>

            <!-- <td class="border-total" ><strong class="d-flex justify-center" style="font-size:15px; color: #00838F;"> {{currencyFormatter(form.Total.Total)}} </strong></td> -->
            <td class="border-top-bottom"><strong></strong></td>
          </tr>
        </template>

        <template v-slot:footer v-if="windowSize.width <= 600">
          <v-list-item style="font-size: 70%;" class="white--text">
            <v-list-item-content>
              <span></span>
              <span></span>
              <!-- currencyFormatter -->
              <span> DESCUENTO: {{ currencyFormatterCant(TotalProd.descuento,PropsDecimalPlaces ) }}</span>
              <span>IMPUESTO: {{ currencyFormatterCant(TotalProd.impuesto,PropsDecimalPlaces ) }} </span>
              <span>IMPORTE: {{ currencyFormatterCant(TotalProd.importe,PropsDecimalPlaces )  }}</span>

            </v-list-item-content>
          </v-list-item>

        </template>
      </v-data-table>
    </v-card>
    <!--------------------------------------------------- fn tb add producot -->

<!---------------------  Panel Menu Opcion Botones ------------------------------->
<div style="max-height:300px;" class="overflow-x-auto" v-show="false">
              <!-- columna  ---------------------->
              <PvMenuOpcionBotones ref="RefPvMenuOpcionBotones" @DataRetornAccion="DataRetornMenuOpcBotonesPermisosPv"
                @DataRetornAccionPadre="DataRetornPadrePermisoPv"/>
            </div>
</div>


  </v-col>

</template>
<script>
import { EventBus } from "../../event-bus";
import AutoNumeric from "../../components/AutoNumericNuevo";
import AutoNumericNuevoWithOptions from "../../components/AutoNumericNuevoWithOptions.vue";
import AgregarProducto from "../../CuentaXPagar/UtilidadComponents/AgregarProducto";
import CrearProducto from "../../CuentaXPagar/UtilidadComponents/CrearProducto";
import CrearDocumento from "../../Inventario/UtilidadComponents/CrearDocumento";
import { currencyFormatter, FormatoFecha, HoraFormatter,RedondeoValorCantRed,currencyFormatterCant } from "@/js/Funciones.js";
import PvMenuOpcionBotones from "@/PuntodeVenta/UtilidadComponents/PvMenuOpcionBotones.vue";
import ImprimirEtiquetasComponet from "../../Inventario/UtilidadComponents/ImprimirEtiquetas";

export default {

  components: {
    AutoNumeric,
    CrearProducto,
    CrearDocumento,
    AgregarProducto,
    ImprimirEtiquetasComponet,
    AutoNumericNuevoWithOptions,
    PvMenuOpcionBotones


  },

  mounted() {
    this.onResize()
    // EventBus.$on("onResize", this.onResize);
    this.CrearHeader()

     console.log('--this.PropsDecimalPlaces--')
    console.log(this.PropsDecimalPlaces)

  },
  data: () => ({

    arrayPropiedases: {
      'items-per-page-options': [10, 15, 20, 50],
      'items-per-page-text': 'Cantidad de Registros Por Paginas',
      showFirstLastPage: true
    },

    TbPaginator:{
      itemsLength: 0,
      itemsPerPage: 15,
      page: 1,
      pageCount: 0,
      pageStart: 0,
      pageStop: 0
      },
      loaderTabla:false,
    windowSize: { width: "auto", height: "auto" },
    valid: true,
    dialogCrearProducto: false,
    dialogAddProducto: false,

    VarKeyCode: 0,
    RproductoBuscados: [],
    Ralmacen: [],
    Rproducto: [],



    aalert: {
      estado: false,
      color: null,
      type: ""

    },

    dialogVerOtroAlmacen: false,
    OtroAlmacen: null,
    impuestoincluido: false,
    descuentoIndividual:0,
    TotalProd: { cantidad: 0, cantExit: 0, costo: 0, impuesto: 0, descuento: 0, importe: 0 },
    HeaderDate: [],
    sugerencia: [],
    Reparaloading: false,
    FacturaTemporal: null,

    DisableCosto: false,
    DisableDevolucion: false,
    btnVerAlm: true,
    btnEliminar: true,

    CelldCrearProducto: false,
    CelldAddProducto: false,

    CelldFechav: false,
    CellCantpedida: false,
    CelldVenta: false,
    CelldAlmacen: false,
    CelldImpuesto: false,
    CelldImporte: true,
    CelldExistenc: true,
    Celldprecio: false,
    CelldDescuento: true,
    CelldDesPociento: true,

    CelldCantSuger: true,
    CelldOferta: true,
    CelldOfertaSelect: true,
    CelldDevolucion: true,
    CelldFactura: true,

    AnchoCodigo: 150,
    AnchosValores: 120,
    AnchoCantidades: 90,
    AnchoAccion: 50,
    AnchoFecha: 80,
    AnchoAlmacen: 160





  }),

  props: {

    Fecdesde: {
      required: false,
      type: String

    },
    Fechasta: {
      required: false,
      type: String
    },

    ListProd: {
      required: true,
      type: Array

    },
    EntradaAlmacen: {
      required: false,
      type: Object
    },



    PropsDevolucion: {
      required: false,
      type: Boolean,
      default: false
    },

   PropsInputDisabled: {
      required: false,
      type: Boolean,
      default: false
    },

    PropsFacturaProveedor: {
      required: false,
      type: Boolean,
      default: false
    },


    PropsComprasSugeridas: {
      required: false,
      type: Boolean,
      default: false
    },


    PropsOrdenCompra: {
      required: false,
      type: Boolean,
      default: false
    },


    PropsDefault: {
      required: false,
      type: Boolean,
      default: false
    },

    ProsActuAlmacen: {
      required: false,
      type: Boolean,
      default: false
    },

    PropsUnidadminima: {
      required: false,
      type: Boolean,
      default: false
    },

    PropsDecimalPlaces: {
      required: false,
      type: Number,
      default: 2
    },


  },


  methods: {

    currencyFormatter, FormatoFecha, HoraFormatter,RedondeoValorCantRed,currencyFormatterCant,


    onResize(e) {
      var jsn = { width: window.innerWidth, height: window.innerHeight - 112 }
      this.windowSize = jsn
    },
    OpenAddProducto() {
      this.dialogAddProducto = true

      var _this = this;
      setTimeout(function () {
        _this.$refs.RefAgregarProducto.CargarRproducto()
      }, 200);
    },

    CrearHeader() {
      //---------------------------------Devolucion-------------------------------------------
      if (this.PropsDevolucion == true) {
        this.HeaderDate = [
          { text: 'FACTURA', value: 'factura', width: this.AnchosValores, sortable: false, class: ['no-gutters  black--text'] },
          { text: 'CÓDIGO', value: 'unidadProducto', width: this.AnchoCodigo, sortable: false, class: ['no-gutters black--text'] },
          { text: 'PRODUCTO', value: 'producto.descripcion', width: 'auto', sortable: false, class: ['no-gutters  black--text'] },
          { text: 'ALMACEN', value: 'almacen', width: this.AnchoAlmacen, sortable: false, class: ['px-3 no-gutters black--text'] },
          { text: 'FECHA V.', value: 'fechavencimiento', width: this.AnchoFecha, sortable: false, class: ['no-gutters black--text'] },
          // { text: 'CANT.PEDIDA', value: 'cantidadpedida', width:this.AnchoCantidades,sortable:false,class: ['no-gutters black--text']},
          { text: 'CANTIDAD', value: 'cantidad', width: this.AnchoCantidades, sortable: false, class: ['no-gutters black--text'] },
          //{ text: 'OFERTA', value: 'oferta',width:this.AnchoCantidades,sortable:false, class: ['px-3 no-gutters black--text'] },
          //{ text: 'EXISTENCIA', value: 'existencia',width:this.AnchoCantidades,sortable:false,class: ['no-gutters black--text']},
          // { text: 'OFERTA', value: 'ofertaSelect',width:this.AnchoCantidades,sortable:false, class: ['px-3 no-gutters black--text'] },
          //{ text: 'VENTAS', value: 'venta', width:this.AnchoCantidades,sortable:false, class: ['no-gutters black--text']},
          //{ text: 'PRECIO FAC.', value: 'precio', width:this.AnchoCantidades,sortable:false, class: ['no-gutters black--text']},
          { text: 'COSTO', value: 'costo', width: this.AnchosValores, sortable: false, class: ['no-gutters black--text'] },
           { text: 'ITBIS', value: 'itbis', width: this.AnchosValores, sortable: false, class: ['no-gutters black--text'] },
         // { text: 'ITBIS', value: 'impuesto', width: this.AnchosValores, sortable: false, class: ['no-gutters black--text'] },
          // { text: 'IMPUESTO', value: 'impuesto', width:this.AnchosValores,sortable:false, class: ['no-gutters black--text']},
          //{ text: 'DESCUENTO', value: 'descuento', width:this.AnchosValores,sortable:false, class: ['no-gutters black--text']},
          //{ text: '$ / %', value: 'descuentoPorciento',sortable:false, class: ['no-gutters black--text']},
          { text: 'IMPORTE', value: 'valor', width: this.AnchosValores, sortable: false, class: ['no-gutters black--text'] },
          //{ text: 'DEVOLUCIÓN', value: 'devolucion',width:this.AnchosValores,sortable:false, class: ['no-gutters black--text']},
          { text: 'ACCIÓN', value: 'action', width: 70, sortable: false, class: ['no-gutters black--text'] }
        ]

        this.CelldCrearProducto = false
        this.CelldAddProducto = true
        this.CelldFechav = false
        this.CellCantpedida = false
        this.CelldVenta = true
        this.CelldAlmacen = true
        this.CelldImpuesto = false
        this.Celldprecio = false
        this.CelldDescuento = false
        this.CelldDesPociento = false
        this.CelldOferta = false
        this.CelldDevolucion = false
        this.CelldFactura = true
        this.CelldItbis = true
        this.CelldImporte = true
        this.CelldExistenc = false
        this.CelldOfertaSelect = false

        this.btnVerAlm = false
        this.btnEliminar = true


      }





      //--------------------------------- FacturaProveedor -------------------------------------------
      if (this.PropsFacturaProveedor == true) {
        this.HeaderDate = [
          //  { text: 'FACTURA', value: 'factura',width:this.AnchosValores,sortable:false,class: ['no-gutters  black--text']},
          { text: 'CÓDIGO', value: 'unidadProducto', width: this.AnchoCodigo, sortable: false, class: ['no-gutters black--text'] },
          { text: 'PRODUCTO', value: 'producto.descripcion', width: 'auto', sortable: false, class: ['no-gutters  black--text'] },
          { text: 'ALMACEN', value: 'almacen', width: this.AnchoAlmacen, sortable: false, class: ['px-3 no-gutters black--text'] },
          { text: 'FECHA V.', value: 'fechavencimiento', width: this.AnchoFecha, sortable: false, class: ['no-gutters black--text'] },
          // { text: 'CANT.PEDIDA', value: 'cantidadpedida', width:this.AnchoCantidades,sortable:false,class: ['no-gutters black--text']},
          { text: 'CANTIDAD', value: 'cantidad', width: this.AnchoCantidades, sortable: false, class: ['no-gutters black--text'] },
          // { text: 'OFERTA', value: 'oferta',width:this.AnchoCantidades,sortable:false, class: ['px-3 no-gutters black--text']},
          //{ text: 'EXISTENCIA', value: 'existencia',width:this.AnchoCantidades,sortable:false,class: ['no-gutters black--text']},
          { text: 'OFERTA', value: 'ofertaSelect', width: this.AnchoAccion, sortable: false, class: ['px-3 no-gutters black--text'] },
          // { text: 'VENTAS', value: 'venta', width:this.AnchoCantidades,sortable:false, class: ['no-gutters black--text']},
          { text: 'PRECIO FAC.', value: 'precio', width: this.AnchoCantidades, sortable: false, class: ['no-gutters black--text'] },
          { text: 'COSTO', value: 'costo', width: this.AnchosValores, sortable: false, class: ['no-gutters black--text'] },
          // { text: 'ITBIS', value: 'itbis', width:this.AnchosValores,sortable:false, class: ['no-gutters black--text']},
          { text: 'IMPUESTO', value: 'impuesto', width: this.AnchosValores, sortable: false, class: ['no-gutters black--text'] },
          { text: 'DESCUENTO', value: 'descuento', width: this.AnchosValores, sortable: false, class: ['no-gutters black--text'] },
          { text: '$ / %', value: 'descuentoPorciento', sortable: false, class: ['no-gutters black--text'] },
          //  { text: 'DEVOLUCIÓN', value: 'devolucion',width:this.AnchosValores,sortable:false, class: ['no-gutters black--text']},
          { text: 'IMPORTE', value: 'valor', width: this.AnchosValores, sortable: false, class: ['no-gutters black--text'] },
          { text: 'ACCIÓN', value: 'action', width: 70, sortable: false, class: ['no-gutters black--text'] }
        ]

        this.CelldCrearProducto = false
        this.CelldAddProducto = true
        this.CelldFechav = true
        this.CellCantpedida = false
        this.CelldVenta = false
        this.CelldAlmacen = true
        this.CelldImpuesto = true
        this.Celldprecio = true
        this.CelldDescuento = true
        this.CelldDesPociento = true
        this.CelldOferta = false
        this.CelldDevolucion = false
        this.CelldFactura = false
        this.CelldItbis = false
        this.CelldImporte = true
        this.CelldExistenc = false
        this.CelldOfertaSelect = true


        this.btnVerAlm = false
        this.btnEliminar = true


      }




      //--------------------------------- ComprasSugeridas-------------------------------------------


      if (this.PropsComprasSugeridas == true) {
        this.HeaderDate = [
          // { text: 'FACTURA', value: 'factura',width:this.AnchosValores,sortable:false,class: ['no-gutters  black--text']},
          { text: 'CÓDIGO', value: 'unidadProducto', width: this.AnchoCodigo, sortable: false, class: ['no-gutters black--text'] },
          { text: 'PRODUCTO', value: 'producto.descripcion', width: 'auto', sortable: false, class: ['no-gutters  black--text'] },
          // { text: 'ALMACEN', value: 'almacen', width:this.AnchoAlmacen,sortable:false, class: ['px-3 no-gutters black--text']},
          // { text: 'FECHA V.', value: 'fechavencimiento',width:this.AnchoFecha,sortable:false, class: ['no-gutters black--text']},
          // { text: 'CANT.PEDIDA', value: 'cantidadpedida', width:this.AnchoCantidades,sortable:false,class: ['no-gutters black--text']},
          { text: 'CANT.SUGERIDA', value: 'cantidad', width: this.AnchoCantidades, sortable: false, class: ['no-gutters black--text'] },
          { text: 'OFERTA', value: 'oferta', width: this.AnchoCantidades, sortable: false, class: ['px-3 no-gutters black--text'] },
          { text: 'EXISTENCIA', value: 'existencia', width: this.AnchoCantidades, sortable: false, class: ['px-3 no-gutters black--text'] },
          //{ text: 'OFERTA', value: 'ofertaSelect',width:this.AnchoAccion,sortable:false, class: ['px-3 no-gutters black--text']},
          { text: 'VENTAS', value: 'venta', width: this.AnchoCantidades, sortable: false, class: ['no-gutters black--text'] },
          // { text: 'PRECIO FAC.', value: 'precio', width:this.AnchoCantidades,sortable:false, class: ['no-gutters black--text']},
          { text: 'COSTO', value: 'costo', width: this.AnchosValores, sortable: false, class: ['no-gutters black--text'] },
          // { text: 'ITBIS', value: 'itbis', width:this.AnchosValores,sortable:false, class: ['no-gutters black--text']},
          //{ text: 'IMPUESTO', value: 'impuesto', width:this.AnchosValores,sortable:false, class: ['no-gutters black--text']},
          //{ text: 'DESCUENTO', value: 'descuento', width:this.AnchosValores,sortable:false, class: ['no-gutters black--text']},
          //{ text: '$ / %', value: 'descuentoPorciento',sortable:false, class: ['no-gutters black--text']},
          { text: 'DEVOLUCIÓN', value: 'devolucion', width: this.AnchosValores, sortable: false, class: ['no-gutters black--text'] },
          // { text: 'IMPORTE', value: 'valor', width:this.AnchosValores,sortable:false, class: ['no-gutters black--text']},
          { text: 'ACCIÓN', value: 'action', width: 70, sortable: false, class: ['no-gutters black--text'] }
        ]

        this.CelldCrearProducto = false
        this.CelldAddProducto = true
        this.CelldFechav = false
        this.CellCantpedida = false
        this.CelldVenta = true
        this.CelldAlmacen = false
        this.CelldImpuesto = false
        this.Celldprecio = false
        this.CelldDescuento = false
        this.CelldDesPociento = false
        this.CelldOferta = true
        this.CelldDevolucion = true
        this.CelldFactura = false
        this.CelldItbis = false
        this.CelldImporte = false
        this.CelldExistenc = true
        this.CelldOfertaSelect = false
        this.DisableCosto = true
        this.DisableDevolucion = true
        this.btnVerAlm = true
        this.btnEliminar = false

      }


      //---------------------------------Orden Compra-------------------------------------------

      if (this.PropsOrdenCompra == true) {
        this.HeaderDate = [
          // { text: 'FACTURA', value: 'factura',width:this.AnchosValores,sortable:false,class: ['no-gutters  black--text']},
          { text: 'CÓDIGO', value: 'unidadProducto', width: this.AnchoCodigo, sortable: false, class: ['no-gutters black--text'] },
          { text: 'PRODUCTO', value: 'producto.descripcion', width: 'auto', sortable: false, class: ['no-gutters  black--text'] },
          // { text: 'ALMACEN', value: 'almacen', width:this.AnchoAlmacen,sortable:false, class: ['px-3 no-gutters black--text']},
          //{ text: 'FECHA V.', value: 'fechavencimiento',width:this.AnchoFecha,sortable:false, class: ['no-gutters black--text']},
          //{ text: 'CANT.PEDIDA', value: 'cantidadpedida', width:this.AnchoCantidades,sortable:false,class: ['no-gutters black--text']},
          { text: 'CANTIDAD', value: 'cantidad', width: this.AnchoCantidades, sortable: false, class: ['no-gutters black--text'] },
          // { text: 'OFERTA', value: 'oferta',width:this.AnchoCantidades,sortable:false, class: ['px-3, no-gutters black--text'] },
          { text: 'EXISTENCIA', value: 'existencia', width: this.AnchoCantidades, sortable: false, class: ['no-gutters black--text'] },
          { text: 'OFERTA', value: 'ofertaSelect', width: this.AnchoAccion, sortable: false, class: ['px-3 no-gutters black--text'] },
          //{ text: 'VENTAS', value: 'venta', width:this.AnchoCantidades,sortable:false, class: ['no-gutters black--text']},
          // { text: 'PRECIO FAC.', value: 'precio', width:this.AnchoCantidades,sortable:false, class: ['no-gutters black--text']},
          { text: 'COSTO', value: 'costo', width: this.AnchosValores, sortable: false, class: ['no-gutters black--text'] },
          //{ text: 'ITBIS', value: 'itbis', width:this.AnchosValores,sortable:false, class: ['no-gutters black--text']},
          { text: 'IMPUESTO', value: 'impuesto', width: this.AnchosValores, sortable: false, class: ['no-gutters black--text'] },
          { text: 'DESCUENTO', value: 'descuento', width: this.AnchosValores, sortable: false, class: ['no-gutters black--text'] },
          { text: '$ / %', value: 'descuentoPorciento', width: this.AnchoAccion + 10, sortable: false, class: ['no-gutters black--text'] },
          //{ text: 'DEVOLUCIÓN', value: 'devolucion',width:this.AnchosValores,sortable:false, class: ['no-gutters black--text']},
          { text: 'IMPORTE', value: 'valor', width: this.AnchosValores, sortable: false, class: ['no-gutters black--text'] },
          { text: 'ACCIÓN', value: 'action', width: 70, sortable: false, class: ['no-gutters black--text'] }
        ]
        this.CelldCrearProducto = true
        this.CelldAddProducto = true
        this.CelldFechav = false
        this.CellCantpedida = true
        this.CelldVenta = false
        this.CelldAlmacen = false
        this.CelldImpuesto = true
        this.Celldprecio = false
        this.CelldDescuento = true
        this.CelldDesPociento = true
        this.CelldOferta = false
        this.CelldDevolucion = false
        this.CelldFactura = false
        this.CelldItbis = false
        this.CelldImporte = true
        this.CelldExistenc = true
        this.CelldOfertaSelect = false
        this.btnVerAlm = false
        this.btnEliminar = true
      }


      // Cantidad Despachada



      //---------------------------------Default-------------------------------------------
      if (this.PropsDefault == true) {
        this.HeaderDate = [
          { text: 'FACTURA', value: 'factura', width: this.AnchosValores, sortable: false, class: ['no-gutters  black--text'] },
          { text: 'CÓDIGO', value: 'unidadProducto', width: this.AnchoCodigo, sortable: false, class: ['no-gutters black--text'] },
          { text: 'PRODUCTO', value: 'producto.descripcion', width: 'auto', sortable: false, class: ['no-gutters  black--text'] },
          { text: 'ALMACEN', value: 'almacen', width: this.AnchoAlmacen, sortable: false, class: ['px-3 no-gutters black--text'] },
          { text: 'FECHA V.', value: 'fechavencimiento', width: this.AnchoFecha, sortable: false, class: ['no-gutters black--text'] },
          { text: 'CANT.PEDIDA', value: 'cantidadpedida', width: this.AnchoCantidades, sortable: false, class: ['no-gutters black--text'] },
          { text: 'CANTIDAD', value: 'cantidad', width: this.AnchoCantidades, sortable: false, class: ['no-gutters black--text'] },
          { text: 'OFERTA', value: 'oferta', width: this.AnchoCantidades, sortable: false, class: ['px-3 no-gutters black--text'] },
          { text: 'EXISTENCIA', value: 'existencia', width: this.AnchoCantidades, sortable: false, class: ['no-gutters black--text'] },
          { text: 'OFERTA', value: 'ofertaSelect', width: this.AnchoAccion, sortable: false, class: ['px-3 no-gutters black--text'] },
          { text: 'VENTAS', value: 'venta', width: this.AnchoCantidades, sortable: false, class: ['no-gutters black--text'] },
          { text: 'PRECIO FAC.', value: 'precio', width: this.AnchoCantidades, sortable: false, class: ['no-gutters black--text'] },
          { text: 'COSTO', value: 'costo', width: this.AnchosValores, sortable: false, class: ['no-gutters black--text'] },
          { text: 'ITBIS', value: 'itbis', width: this.AnchosValores, sortable: false, class: ['no-gutters black--text'] },
          { text: 'IMPUESTO', value: 'impuesto', width: this.AnchosValores, sortable: false, class: ['no-gutters black--text'] },
          { text: 'DESCUENTO', value: 'descuento', width: this.AnchosValores, sortable: false, class: ['no-gutters black--text'] },
          { text: '$ / %', value: 'descuentoPorciento', sortable: false, class: ['no-gutters black--text'] },
          { text: 'DEVOLUCIÓN', value: 'devolucion', width: this.AnchosValores, sortable: false, class: ['no-gutters black--text'] },
          { text: 'IMPORTE', value: 'valor', width: this.AnchosValores, sortable: false, class: ['no-gutters black--text'] },
          { text: 'ACCIÓN', value: 'action', width: 70, sortable: false, class: ['no-gutters black--text'] }
        ]

        this.CelldCrearProducto = true
        this.CelldAddProducto = true
        this.CelldFechav = true
        this.CellCantpedida = true
        this.CelldVenta = true
        this.CelldAlmacen = true
        this.CelldImpuesto = true
        this.Celldprecio = true
        this.CelldDescuento = true
        this.CelldDesPociento = true
        this.CelldOferta = true
        this.CelldDevolucion = true
        this.CelldFactura = true
        this.CelldItbis = true
        this.CelldImporte = true
        this.CelldExistenc = true
        this.CelldOfertaSelect = true
        this.btnVerAlm = true
        this.btnEliminar = true
      }
    },

    CargarRalmacen() {
      this.$axios
        .get(this.$hostname + this.$hName + "/almacen",
          { headers: this.$store.getters.GetheadersAxios })
        .then(res => {
          this.Ralmacen = res.data;
        });
    },


    GetCodigoProdUni(unidaproducto) {
     // console.log(this.$store.getters.GetConfiguracionGeneral.configInv.usarLote)
      if (this.$store.getters.GetConfiguracionGeneral.configInv.usarLote){
        return unidaproducto.lote;
      }else{
        var pk = unidaproducto.unidadesProductosPK;
        var codigoP = ('000000' + pk.producto).substring(('000000' + pk.producto).length - 6, ('000000' + pk.producto).length)
        var codigoU = ('0000' + pk.unidad).substring(('0000' + pk.unidad).length - 4, ('0000' + pk.unidad).length)
        return codigoP + codigoU;
      }
    },
    fechaVencimientoAVencer(t) {

if(t.fechavencimiento!=null && t.fechavencimiento.length==0){
t.fechavencimiento=null
}

var _this = this;
        setTimeout(function () {
        //  if(e!=null && e.length>10){e='2024-12-10' }
        if(t.fechavencimiento!=null && t.fechavencimiento.toString().length>10){
         var FCSPLIT=t.fechavencimiento.split("-")
         t.fechavencimiento=`${FCSPLIT[0].substr(0,4)}-${FCSPLIT[1]}-${FCSPLIT[2]}`
        }

       var e = new Date(), d = new Date(t.fechavencimiento)
      e.setMonth(e.getMonth() + 3)
      if (d < e) {
        _this.alerta("Este producto esta próximo a vencerse o esta vencido", 'error')
        return
      }

        }, 500);

      


    },


    newDetalle() {

      var VarAlmacen = JSON.parse(JSON.stringify(this.EntradaAlmacen))

      var detalle = {
        movimientosinventarioDetallePK: { secuencia: 0, producto: 0, unidad: 0, documento: 0 },
        fechavencimiento: null,
        cantidad: 0.00,
        costo: 0.00,
        valor: 0.00,
        impuesto: 0.00,
        precio: 0.00,
        precio2: 0.00,
        precioAnte: 0.00,
        impuestoIncluido: false,
        existencia: 0,
        id: 0,
        borrado: false,
        almacen: VarAlmacen,
        unidad: null,
        producto: null,
        codigoProducto: "",
        unidadProducto: null,
        descuentoPorciento: false,
        descuento: 0,
        oferta: 0,
        devolucion: 0,
        ofertaSelect: false
      }



      this.ListProd.push(detalle)
    },
    setImpuestoIncluido(e) {
      this.impuestoincluido = e;
       this.calcularImpuesto1()
    },

setDescuentoIndividual(e) {
      this.descuentoIndividual = e;
      var _this = this;
        setTimeout(function () {
          _this.calcularTotales();

        }, 500);

    },
    

    addDetalle(unidadProducto) {

      var VarAlmacen = JSON.parse(JSON.stringify(this.EntradaAlmacen))

      var p = 0, u = 0, a = 0
      var e = {
        movimientosinventarioDetallePK: { secuencia: 0, producto: 0, unidad: 0, documento: 0 },
        fechavencimiento: null,
        cantidad: 1,
        costo: unidadProducto.costo,
        valor: unidadProducto.costo,
        precio: unidadProducto.costo,
        precio2: unidadProducto.costo,
        precioAnte:unidadProducto.costo,
        descuento: 0.0,
        impuesto: this.calcularImpuesto(unidadProducto),
        impuestoIncluido: this.impuestoIncuidos(unidadProducto),
        id: 0,
        borrado: false,
        almacen: VarAlmacen,
        unidad: unidadProducto.unidades,
        producto: unidadProducto.productos,
        codigoProducto: "",
        existencia: 0,
        unidadProducto: unidadProducto,
        ofertaSelect: false,
        descuentoPorciento:false
      }

      var VarAlmacen = JSON.parse(JSON.stringify(this.EntradaAlmacen))

      if (unidadProducto == null) {
        return
      }
      if (unidadProducto.unidades != null && unidadProducto.unidades.codigo != undefined) {
        u = unidadProducto.unidades.codigo;
      }
      if (unidadProducto.productos != null && unidadProducto.productos.codigo != undefined) {
        p = unidadProducto.productos.codigo;
      }
      if (VarAlmacen != null && VarAlmacen.codigo != undefined) {
        a = VarAlmacen.codigo;
      }
      if (a == 0 || p == 0 || u == 0) {
        e.existencia = 0.00
      } else {

        this.findExistencia(p, u, a, e)

      }

      // var _this = this;
      // setTimeout(function(){_this.ListProd.push(e)},500)

      this.ListProd.push(e)
      this.calcularImporte(e)
    },
    addDetalleSugerido(det) {
      var VarAlmacen = JSON.parse(JSON.stringify(this.EntradaAlmacen))
      var p = 0, u = 0, a = 0
      var e = {
        movimientosinventarioDetallePK: { secuencia: 0, producto: 0, unidad: 0, documento: 0 },
        fechavencimiento: null,
        cantidad: det.cantidad,
        costo: det.hasOwnProperty("unidadesProductos") && det.unidadesProductos.hasOwnProperty("costo") && det.unidadesProductos.costo != null ? det.unidadesProductos.costo: 0,
        precioAnte: det.hasOwnProperty("unidadesProductos") && det.unidadesProductos.hasOwnProperty("costo") && det.unidadesProductos.costo != null ? det.unidadesProductos.costo: 0,
        valor: det.hasOwnProperty("unidadesProductos") && det.unidadesProductos.hasOwnProperty("costo") && det.unidadesProductos.costo != null ? det.unidadesProductos.costo: 0,
        impuesto: this.calcularImpuesto(det.unidadesProductos),
        impuestoIncluido: this.impuestoIncuidos(det.unidadesProductos),
        id: 0,
        borrado: false,
        almacen: VarAlmacen,
        unidad: det.unidadesProductos.unidades,
        producto: det.unidadesProductos.productos,
        codigoProducto: "",
        existencia: 0,
        venta: det.hasOwnProperty("ventas") && det.ventas != null ? det.ventas: 0,
        // venta: det.ventas,
        unidadProducto: det.unidadesProductos,
        oferta: 0,
        devolucion: det.devolucion,
        descuento: 0.00,
        ofertaSelect: false

      }

      var VarAlmacen = JSON.parse(JSON.stringify(this.EntradaAlmacen))
      if (det.unidadesProductos == null) {
        return
      }
      if (det.unidadesProductos.unidades != null && det.unidadesProductos.unidades.codigo != undefined) {
        u = det.unidadesProductos.unidades.codigo;
      }
      if (det.unidadesProductos.productos != null && det.unidadesProductos.productos.codigo != undefined) {
        p = det.unidadesProductos.productos.codigo;
      }
      if (VarAlmacen != null && VarAlmacen.codigo != undefined) {
        a = VarAlmacen.codigo;
      }
      if (a == 0 || p == 0 || u == 0) {
        e.existencia = 0.00
      } else {
        this.findExistencia(p, u, a, e)
      }
      this.ListProd.push(e)
      this.calcularImporte(e)
    },
    addDetallePedido(unidadProducto) {
      var VarAlmacen = JSON.parse(JSON.stringify(this.EntradaAlmacen))
      var p = 0, u = 0, a = 0
      var e = {
        movimientosinventarioDetallePK: { secuencia: 0, producto: 0, unidad: 0, documento: 0 },
        fechavencimiento: null,
        cantidad: unidadProducto.cantidad,
        costo: det.hasOwnProperty("unidadProducto") && det.unidadesProductos.hasOwnProperty("costo") && e.unidadProducto.costo != null ? e.unidadProducto.costo: 0,
        valor: det.hasOwnProperty("unidadProducto") && det.unidadesProductos.hasOwnProperty("costo") && e.unidadProducto.costo != null ? e.unidadProducto.costo: 0,
        precioAnte:det.hasOwnProperty("unidadProducto") && det.unidadesProductos.hasOwnProperty("costo") && e.unidadProducto.costo != null ? e.unidadProducto.costo: 0,
        // costo: unidadProducto.costo,
        // valor: unidadProducto.costo,
        impuesto: this.calcularImpuesto(unidadProducto),
        impuestoIncluido: this.impuestoIncuidos(unidadProducto),
        id: 0,
        borrado: false,
        almacen: VarAlmacen,
        unidad: unidadProducto.unidades,
        producto: unidadProducto.productos,
        codigoProducto: "",
        existencia: 0,
        unidadProducto: unidadProducto,
        venta: unidadProducto.ventas,
        ofertaSelect: false
      }

      var VarAlmacen = JSON.parse(JSON.stringify(this.EntradaAlmacen))

      if (unidadProducto == null) {
        return
      }
      if (unidadProducto.unidades != null && unidadProducto.unidades.codigo != undefined) {
        u = unidadProducto.unidades.codigo;
      }
      if (unidadProducto.productos != null && unidadProducto.productos.codigo != undefined) {
        p = unidadProducto.productos.codigo;
      }
      if (VarAlmacen != null && VarAlmacen.codigo != undefined) {
        a = VarAlmacen.codigo;
      }
      if (a == 0 || p == 0 || u == 0) {
        e.existencia = 0.00
      } else {

        this.findExistencia(p, u, a, e)

      }

      // var _this = this;
      // setTimeout(function(){_this.ListProd.push(e)},500)

      this.ListProd.push(e)
    },
    async getUnidadesProductos(producto, unidad) {
      var unidadProducto = null;
      await this.$axios.get(this.$hostname + this.$hName + '/unidades-productos/find/' + producto + '/' + unidad,
        { headers: this.$store.getters.GetheadersAxios })
        .then(res => {
          unidadProducto = res.data
        });
      return unidadProducto
    },

    async getUnidadesProductos2(producto, unidad,e) {
      var unidadProducto = null;
      await this.$axios.get(this.$hostname + this.$hName + '/unidades-productos/find/' + producto + '/' + unidad,
        { headers: this.$store.getters.GetheadersAxios })
        .then(res => {
          unidadProducto = res.data
          e.unidadProducto=res.data
          // e.unidadProducto.codigoProducto= this.GetCodigoProdUni(res.data)
         
        });    
    //  return unidadProducto
    },

   
    async getListOrden(listArrayProduct) {
      var LISTARRAYS=JSON.parse(JSON.stringify(listArrayProduct));
      // return
var bar = new Promise((resolve, reject) => {
  LISTARRAYS.forEach(e => {

  this.$axios.get(this.$hostname + this.$hName + '/unidades-productos/find/' + e.producto.codigo + '/' + e.unidad.codigo,
        { headers: this.$store.getters.GetheadersAxios })
        .then(res => {
          // unidadProducto = res.data
          e.unidadProducto=res.data
          e.unidadProducto.codigoProducto= this.GetCodigoProdUni(res.data)
          e.costo=e.precio 
          e.precioAnte=e.precio 
          e.ofertaSelect=e.oferta
          var VarAlmacen = JSON.parse(JSON.stringify(this.EntradaAlmacen))

      if (res.data == null) {
        return
      }
      var bar1 = new Promise((resolve1, reject) => {
      var p = 0, u = 0, a = 0
      if (res.data.unidades != null && res.data.unidades.codigo != undefined) {
        u = res.data.unidades.codigo;
      }
      if (res.data.productos != null && res.data.productos.codigo != undefined) {
        p = res.data.productos.codigo;
      }
      if (VarAlmacen != null && VarAlmacen.codigo != undefined) {
        a = VarAlmacen.codigo;
      }
      if (a == 0 || p == 0 || u == 0) {
        e.existencia = 0.00
        resolve1()
      } else {
        this.$axios.get(this.$hostname + this.$hName + '/unidades-productos/findExistencia/' + p + '/' + u + '/' + a,
        { headers: this.$store.getters.GetheadersAxios })
        .then(res => { e.existencia = res.data 
          resolve1()
        }) 
      }
    });

  bar1.then(() => {
     this.ListProd.push(e) 
     this.calcularImporte(e) 
     
  });
      
        });
        
});

resolve()
});

bar.then(() => {
  return LISTARRAYS;
  //this.ListProd =JSON.parse(JSON.stringify(LISTARRAYS));
});

        
    //  return unidadProducto
    },



    async addDetalledatostablaFacProveedor(detalle) {
      var VarAlmacen = JSON.parse(JSON.stringify(this.EntradaAlmacen))
      var p = 0, u = 0, a = 0
      var e = {
        movimientosinventarioDetallePK: { secuencia: 0, producto: 0, unidad: 0, documento: 0 },
        fechavencimiento: detalle.fechavencimiento,
        cantidad: detalle.cantidad,
        // costo: detalle.precio,
        costo: detalle.costo,
        precio: detalle.precio,
        precio2: detalle.precio,
        precioAnte:detalle.precio,
        valor: detalle.valor,
        impuesto: detalle.impuesto,
        impuestoIncluido: detalle.impuestoIncluido,
        id: 0,
        borrado: false,
        descuento:detalle.hasOwnProperty("descuento")&&detalle.descuento!=null?detalle.descuento:0 ,
        almacen: VarAlmacen,
        unidad: (detalle.unidad==null?(detalle.unidades==null?null:detalle.unidades):detalle.unidad),
        producto: (detalle.producto==null?(detalle.productos==null?null:detalle.productos):detalle.producto),
        codigoProducto: '',
        existencia: 0,
        unidadProducto: null,
        ofertaSelect: detalle.hasOwnProperty('ofertaSelect')?detalle.ofertaSelect:false,
        descuentoPorciento:detalle.hasOwnProperty('descuentoPorciento')?detalle.descuentoPorciento:false
      }
      if(detalle.producto!= null && detalle.unidad!=null){
        this.getUnidadesProductos(detalle.producto.codigo, detalle.unidad.codigo).then(aa => e.unidadProducto = aa)
      }
      if (detalle.productos!= null && detalle.unidades!=null){
        this.getUnidadesProductos(detalle.productos.codigo, detalle.unidades.codigo).then(aa => e.unidadProducto = aa)
      }
      var VarAlmacen = JSON.parse(JSON.stringify(this.EntradaAlmacen))
      if (detalle == null) {
        return
      }
      if (detalle.unidad != null && detalle.unidad.codigo != undefined) {
        u = detalle.unidad.codigo;
      }
      if (detalle.producto != null && detalle.producto.codigo != undefined) {
        p = detalle.producto.codigo;
      }
      if (detalle.unidades != null && detalle.unidades.codigo != undefined) {
        u = detalle.unidades.codigo;
      }
      if (detalle.productos != null && detalle.productos.codigo != undefined) {
        p = detalle.productos.codigo;
      }
      if (VarAlmacen != null && VarAlmacen.codigo != undefined) {
        a = VarAlmacen.codigo;
      }
      if (a == 0 || p == 0 || u == 0) {
        e.existencia = 0.00
      } else {
        this.findExistencia(p, u, a, e)
      }
      // var _this = this;
      // setTimeout(function(){_this.ListProd.push(e)},500)
      this.ListProd.push(e)
      this.blurItbsImpuestoValidarCalculo(e)
    },






    async addDetalleOrdenAFactura(detalle) {
      var VarAlmacen = JSON.parse(JSON.stringify(this.EntradaAlmacen))
      var p = 0, u = 0, a = 0
      var e = {
        movimientosinventarioDetallePK: { secuencia: 0, producto: 0, unidad: 0, documento: 0 },
        fechavencimiento: detalle.fechavencimiento,
        cantidad: detalle.cantidad,
        costo: detalle.precio,
        precio: detalle.precio,
        precio2: detalle.precio,
        precioAnte:detalle.precio,
        valor: detalle.valor,
        impuesto: detalle.impuesto,
        impuestoIncluido: detalle.impuestoIncluido,
        id: 0,
        borrado: false,
        descuento:detalle.hasOwnProperty("descuento")&&detalle.descuento!=null?detalle.descuento:0 ,
        almacen: VarAlmacen,
        unidad: (detalle.unidad==null?(detalle.unidades==null?null:detalle.unidades):detalle.unidad),
        producto: (detalle.producto==null?(detalle.productos==null?null:detalle.productos):detalle.producto),
        codigoProducto: '',
        existencia: 0,
        unidadProducto: null,
        ofertaSelect: detalle.hasOwnProperty('ofertaSelect')?detalle.ofertaSelect:false,
        descuentoPorciento:detalle.hasOwnProperty('descuentoPorciento')?detalle.descuentoPorciento:false
      }
      if(detalle.producto!= null && detalle.unidad!=null){
        this.getUnidadesProductos(detalle.producto.codigo, detalle.unidad.codigo).then(aa => e.unidadProducto = aa)
      }
      if (detalle.productos!= null && detalle.unidades!=null){
        this.getUnidadesProductos(detalle.productos.codigo, detalle.unidades.codigo).then(aa => e.unidadProducto = aa)
      }
      var VarAlmacen = JSON.parse(JSON.stringify(this.EntradaAlmacen))
      if (detalle == null) {
        return
      }
      if (detalle.unidad != null && detalle.unidad.codigo != undefined) {
        u = detalle.unidad.codigo;
      }
      if (detalle.producto != null && detalle.producto.codigo != undefined) {
        p = detalle.producto.codigo;
      }
      if (detalle.unidades != null && detalle.unidades.codigo != undefined) {
        u = detalle.unidades.codigo;
      }
      if (detalle.productos != null && detalle.productos.codigo != undefined) {
        p = detalle.productos.codigo;
      }
      if (VarAlmacen != null && VarAlmacen.codigo != undefined) {
        a = VarAlmacen.codigo;
      }
      if (a == 0 || p == 0 || u == 0) {
        e.existencia = 0.00
      } else {
        this.findExistencia(p, u, a, e)
      }
      // var _this = this;
      // setTimeout(function(){_this.ListProd.push(e)},500)
      this.ListProd.push(e)
      this.calcularImporte(e)
    },
    async addDetalleDevolucion(detalle) {
      var VarAlmacen = JSON.parse(JSON.stringify(this.EntradaAlmacen))
      var p = 0, u = 0, a = 0
      var unidadesProductos = null
      var e = {
        factproPk:detalle.hasOwnProperty('factproPk') ? detalle.factproPk : null, 
        noFactura:detalle.hasOwnProperty('factura') ? detalle.factura+"" : "", 
        factura:detalle.hasOwnProperty('factura') ? detalle.factura: null, 
        movimientosinventarioDetallePK: { secuencia: 0, producto: 0, unidad: 0, documento: 0 },
        fechavencimiento: null,
        cantidad: detalle.cantidad,
        costo: detalle.costo,
        precio: detalle.costo,
        precio2: detalle.costo,
        precioAnte:detalle.costo,
        valor: detalle.valor,
        impuesto: detalle.impuesto,
        id: 0,
        borrado: false,
        descuento: 0.00,
        almacen: detalle.almacen,
        unidad: detalle.unidades,
        producto: detalle.productos,
        codigoProducto: '',
        existencia: 0,
        unidadProducto: null,
        ofertaSelect: false
      }
      this.getUnidadesProductos(detalle.productos.codigo, detalle.unidades.codigo).then(a => e.unidadProducto = a)
      var VarAlmacen = JSON.parse(JSON.stringify(this.EntradaAlmacen))
      if (detalle == null) {
        return
      }
      if (detalle.unidades != null && detalle.unidades.codigo != undefined) {
        u = detalle.unidades.codigo;
      }
      if (detalle.productos != null && detalle.productos.codigo != undefined) {
        p = detalle.productos.codigo;
      }
      if (VarAlmacen != null && VarAlmacen.codigo != undefined) {
        a = VarAlmacen.codigo;
      }
      if (a == 0 || p == 0 || u == 0) {
        e.existencia = 0.00
      } else {

        this.findExistencia(p, u, a, e)

      }
      // var _this = this;
      // setTimeout(function(){_this.ListProd.push(e)},500)
      this.ListProd.push(e)
      this.calcularImporte(e)
    },
    RecCrearProducto(e) {
      this.dialogCrearProducto = e.estado
    },

    RecAgregarProducto(e) {
      if (e.estado == 'cerrar') {
        this.dialogAddProducto = e.false
      }
      if (e.estado == 'datos') {
        this.addDetalle(e.datos)
        var _this = this;
        setTimeout(function () {
          _this.calcularTotales();
        }, 500);
       // this.calcularTotales();
       //loaderTablaCargar
       this.loaderTablaCargar()
      }
    },

    VerOtroAlmacenItem(e) {
      var obj={
        unidadProducto:e.unidadProducto,
        desde:this.Fecdesde,
        hasta:this.Fechasta
      }
      this.$axios
        .post(this.$hostname + this.$hName + "/ordenescompras/ProductoAlmacExistVenta", obj, {
          headers: this.$store.getters.GetheadersAxios,
        })
        .then((res) => {
        obj.ListaAlmacen=res.data
        this.OtroAlmacen = JSON.parse(JSON.stringify(obj))
        this.dialogVerOtroAlmacen = true
        })
        .catch((error) => {console.log('Catch---')});
     /* this.OtroAlmacen = JSON.parse(JSON.stringify(obj))
      console.log(e)
      console.log('-----')
      console.log(this.OtroAlmacen)
      this.OtroAlmacen.ListaAlmacen = [{ venta: 5000, almacen: 'principal', existencion: 400 }, { venta: 800, almacen: 'Secuendario', existencion: 200 }]
      this.dialogVerOtroAlmacen = true*/
    },

    eliminarItem(e) {
      if (e != null) {
        const index = this.ListProd.indexOf(e)
        var c = confirm('¿ Desea eliminar este Registro ?') && this.ListProd.splice(index, 1)
        if (c != false) {
          this.calcularTotales()
        }
      }
    },
    RedireccionFocus(e) {
      var _this = this;
      setTimeout(function () { _this.$refs[e].focus() }, 500)
    },
    RedireccionBlur(e) {
      var _this = this;
      setTimeout(function () { _this.$refs[e].blur() }, 500)
    },
    PosicionFocus(e) {

     
       var NUMPOSICIO=(this.ListProd.length/this.TbPaginator.itemsPerPage)
       if(NUMPOSICIO>1){
        this.TbPaginator.pageCount=(parseInt(NUMPOSICIO)+1)
       }
      if (this.VarKeyCode == 40) {
        //var ok = e.length - 1
        var ok = this.ListProd.length-1
        if(this.TbPaginator.page!=this.TbPaginator.pageCount){
          this.TbPaginator.page=this.TbPaginator.pageCount
        }
        /*else{
          if(this.ListProd.length>this.TbPaginator.itemsPerPage){
           //var NUMPOSICIO=(this.ListProd.length/this.TbPaginator.itemsPerPage)
           // this.TbPaginator.page=parseInt(NUMPOSICIO)
           // this.TbPaginator.pageCount=parseInt(NUMPOSICIO)
          }
        }*/

        this.RedireccionFocus('codigo' + ok)
      }
      this.VarKeyCode = 0
    },
    NewObjetoProducto(e) {
      var VarAlmacen = JSON.parse(JSON.stringify(this.EntradaAlmacen))
      e.almacen = VarAlmacen
      e.costo = 0
      e.cantidad = 1
      e.producto = null
      e.impuesto = 0
      e.impuestoIncluido = false,
        e.existencia = 0
      e.valor = 0
    },
    findExistencia(p, u, a, e) {
      this.$axios.get(this.$hostname + this.$hName + '/unidades-productos/findExistencia/' + p + '/' + u + '/' + a,
        { headers: this.$store.getters.GetheadersAxios })
        .then(res => { e.existencia = res.data })
    },
    impuestoIncuidos(e) {
      return this.impuestoIncluido
    },
    calcularImpuesto(unidadProducto) {
      if (unidadProducto.productos == null) {
        return 0.00
      }
      if (unidadProducto.productos.impuesto == null) {
        return 0.00
      }
      var tasa = (unidadProducto.productos.impuesto.tasa == null || unidadProducto.productos.impuesto.tasa == 0 ? 1 : unidadProducto.productos.impuesto.tasa) / 100
      var valImpuesto = unidadProducto.costo * tasa;
      return valImpuesto
    },
    AsignarObjetoProducto(e, Obj) {
      console.log('--AsignarObjetoProducto--')
      console.log(e)
      console.log(Obj)
      var VarAlmacen = JSON.parse(JSON.stringify(this.EntradaAlmacen))
      e.unidadProducto = JSON.parse(JSON.stringify(Obj))
      e.almacen = VarAlmacen
      e.costo = Obj.costo
      e.precio=Obj.costo
      e.precio2=Obj.costo
      e.precioAnte=Obj.costo
      e.cantidad = 1
      e.producto = JSON.parse(JSON.stringify(Obj.productos))
      e.unidad = JSON.parse(JSON.stringify(Obj.unidades))
      e.impuesto = this.calcularImpuesto(e.unidadProducto)
      e.impuestoIncluido = this.impuestoIncuidos(e.unidadProducto)
      this.findExistencia(Obj.unidadesProductosPK.producto, Obj.unidadesProductosPK.unidad, e.almacen.codigo, e)
      e.valor = e.cantidad * (e.costo + e.impuesto)
      this.RedireccionBlur('codigo' + e.indx)

    var BOOLFOCUS= (this.PropsFacturaProveedor==true && Obj.productos.hasOwnProperty('controlVencimiento') && Obj.productos.controlVencimiento==true) 
    if(BOOLFOCUS==true){
      this.RedireccionFocus('fechavencimiento' + e.indx)
    }else{
      this.RedireccionFocus('cantidad' + e.indx)
    }

      
    },
    limpiar() {
      this.ListProd = [];
    },
    changeSelectProduc(e) {
      this.RproductoBuscados = []
      this.NewObjetoProducto(e)
      if (e.unidadProducto != null && e.unidadProducto.hasOwnProperty('costo')) {
        this.AsignarObjetoProducto(e, e.unidadProducto)
      } else {
        var link = this.$hostname + this.$hName + '/unidades-productos/' + e.unidadProducto + '/false/buscar';
        this.$axios.get(link,
          { headers: this.$store.getters.GetheadersAxios })
          .then(res => {
            this.RproductoBuscados = res.data;
            if (res.data.length == 1) {
              this.AsignarObjetoProducto(e, res.data[0])
              this.RproductoBuscados = []
            }

          })
      }
    },


    BlurTabSelectProduc(e) {
      if (this.RproductoBuscados.length > 0) {
        this.AsignarObjetoProducto(e, this.RproductoBuscados[0])
        this.RproductoBuscados = []
        this.RedireccionBlur('Almacen' + e.indx)
        var BOOLFOCUS= (this.PropsFacturaProveedor==true && e.producto.hasOwnProperty('controlVencimiento') && e.producto.controlVencimiento==true) 
    if(BOOLFOCUS==true){
      this.RedireccionFocus('fechavencimiento' + e.indx)
    }else{
      this.RedireccionFocus('cantidad' + e.indx)
    }
        //this.RedireccionFocus('cantidad' + e.indx)
        return
      }
      if (e.unidadProducto == null && this.RproductoBuscados.length == 0) {
        this.RedireccionBlur('Almacen' + e.indx)
        //this.alerta("Debe seleccionar un producto", "error")
      }
      if (e.unidadProducto != null && !e.unidadProducto.hasOwnProperty('costo') && this.RproductoBuscados.length == 0) {
        this.RedireccionBlur('Almacen' + e.indx)
        this.alerta("Debe seleccionar un producto", "error")
        var VarAlmacen = JSON.parse(JSON.stringify(this.EntradaAlmacen))
        e.unidadProducto = null
        e.almacen = VarAlmacen
        e.costo = 0
        e.cantidad = 1
        e.producto = null
        e.impuesto = 0
        e.impuestoIncluido = false,
          e.existencia = 0
        e.valor = 0
      }
    },
    AddNewRowListProd(e) {
      var VarAlmacen = JSON.parse(JSON.stringify(this.EntradaAlmacen))
      var detalle = {
        indx: e.indx + 1,
        movimientosinventarioDetallePK: { secuencia: 0, producto: 0, unidad: 0, documento: 0 },
        fechavencimiento: null,
        cantidad: 0.00,
        costo: 0.00,
        valor: 0.00,
        precio: 0.00,
        precio2: 0.00,
        precioAnte:0.00,
        impuesto: 0.00,
        impuestoIncluido: false,
        existencia: 0,
        id: 0,
        borrado: false,
        almacen: VarAlmacen,
        unidad: null,
        producto: null,
        codigoProducto: "",
        unidadProducto: null,
        ofertaSelect: false,
        descuento:0.00,
        descuentoPorciento:false
      }
      this.ListProd.push(detalle)
      this.RedireccionBlur('codigo' + e.indx)
    },
    isOferta(e) {
      if (e.ofertaSelect) {
        e.precio = 0
        e.costo2=0
        e.costo=0
        e.precioAnte=0
      } else {
        e.precio = e.precio2
        e.costo= e.precio2
       e.precioAnte=e.precio2
      }
      this.calcularImporte(e)

      var _this = this;
        setTimeout(function () {
          _this.calcularTotales();
        }, 500);
      
    },
    OnKeyupAddRowProducCombox(e) {
      console.log('OnKeyupAddRowProducCombox-->',e)
      if (this.RproductoBuscados.length == 0) {
        this.AddNewRowListProd(e);
        this.VarKeyCode = 40
      }
    },
    calcularTotales() {
      this.TotalProd.impuesto = 0.00
      this.TotalProd.importe = 0.00
      this.TotalProd.descuento = 0.00
      this.ListProd.forEach(dt => {
        // this.TotalProd.impuesto += (dt.impuesto * dt.cantidad)
      this.TotalProd.impuesto += (dt.impuesto )
        
        this.TotalProd.importe += dt.valor
        // this.TotalProd.descuento += dt.descuento
        if(dt.hasOwnProperty('desctotal')){
          this.TotalProd.descuento+=dt.desctotal
        }
        
      });

      this.TotalProd.importe-=this.descuentoIndividual

      this.$emit("DataTotalList", this.TotalProd)
    },
    calcularDescuento(items) {
      if (items.costo == 0) {
        return 0.00;
      }
      if (items.descuentoPorciento) {
        //return items.costo * (items.descuento / 100);
        return ((items.cantidad*items.costo) * (items.descuento / 100)) ;
      } else {
        return items.descuento;
      }
    },
    setCostoPrecioAnteKeyinput(e){

      if(this.PropsFacturaProveedor==false){
        e.precioAnte=e.costo
      }
     
    },


    blurCostoValidarPrecioCosto(e){
   
if( this.PropsFacturaProveedor==true && e.precioAnte>e.costo){
  //23/9/2023:1711
  // e.costo=JSON.parse(JSON.stringify(e.precioAnte))
}
if( this.PropsFacturaProveedor==true && e.precioAnte<e.costo){
  //27/07/2024:2281
  // e.costo=JSON.parse(JSON.stringify(e.precioAnte))
}

  this.calcularImporte(e)
},

    blurPrecioValidarPrecioCosto(e){

    if(e.precioAnte>e.costo){
      e.costo=JSON.parse(JSON.stringify(e.precioAnte))
    }
      this.calcularImporte(e)
    },

    calcularImporte(el) {
      if(el.producto!=null){
      var tasa = (el.producto.impuesto == null ? 0 : el.producto.impuesto.tasa)/100
      
      el.impuestoIncluido =JSON.parse(JSON.stringify(this.impuestoincluido))
      
      if(el.impuestoIncluido==false){
        var DESCUENTO_VALOR=0
        if(el.descuentoPorciento==true){
        // desc$=( ((Cant*Prec)*desc%)/100 )
        DESCUENTO_VALOR=( ((el.cantidad*el.precioAnte)* el.descuento)/100 )
      }else{
        DESCUENTO_VALOR=(el.descuento)
      }
      el.desctotal=DESCUENTO_VALOR
      // impuesto= (((Cant*Prec)-desc)*0.18)
        el.impuesto=( ((el.cantidad*el.precioAnte)- DESCUENTO_VALOR)*tasa )
      // importe=((Cant*Prec)-desc)+impuesto)
      el.valor=( ((el.cantidad*el.precioAnte)-DESCUENTO_VALOR)+el.impuesto )
      }else{
       var PRECIO=JSON.parse(JSON.stringify((el.precioAnte / (1 + (tasa)))))
       var DESCUENTO_VALOR=0
        if(el.descuentoPorciento==true){
        // desc$=( ((Cant*Prec)*desc%)/100 )
        DESCUENTO_VALOR=( ((el.cantidad*PRECIO)* el.descuento)/100 )
      }else{
        DESCUENTO_VALOR=(el.descuento)
      }
      el.desctotal=DESCUENTO_VALOR
      el.precio=JSON.parse(JSON.stringify(PRECIO))
      if(PRECIO>el.costo){
        el.costo=JSON.parse(JSON.stringify(PRECIO))
      }
       
       el.impuesto=( ((el.cantidad*PRECIO)- DESCUENTO_VALOR)*tasa )
       el.valor=( ((el.cantidad*PRECIO)-DESCUENTO_VALOR)+el.impuesto )
       /* el.precio=(el.precio / (1 + (tasa)))
        el.costo=el.precio
        el.impuesto=( ((el.cantidad*el.precio)- DESCUENTO_VALOR)*tasa )
        el.valor=( ((el.cantidad*el.precio)-DESCUENTO_VALOR)+el.impuesto )
         return*/
      }
     
      this.calcularTotales()

    }
    
      /*
      Proceso Anterior
      var tasa = el.producto.impuesto == null ? 0 : el.producto.impuesto.tasa
      if (tasa > 0) {
        el.impuestoIncluido = this.impuestoincluido
        if (this.impuestoincluido) {
          el.costo = (el.precio / (1 + (tasa / 100)))
          el.impuesto = el.precio - el.costo
          el.valor = (el.cantidad * el.precio)
        } else {
          el.impuesto = el.precio * (tasa / 100)
          el.valor = (el.cantidad * el.precio) + (el.cantidad * el.impuesto)
          el.costo = el.precio
        }
      } else {
        el.costo = el.precio
      }*/




    },




    blurItbsImpuestoValidarCalculo(el){
   
   if( this.PropsFacturaProveedor==true){
    if(el.producto!=null){ 
      var tasa = (el.producto.impuesto == null ? 0 : el.producto.impuesto.tasa)/100
      el.impuestoIncluido =JSON.parse(JSON.stringify(this.impuestoincluido))
      var DESCUENTO_VALOR=0
      if(el.impuestoIncluido==false){
        if(el.descuentoPorciento==true){
        DESCUENTO_VALOR=( ((el.cantidad*el.precioAnte)* el.descuento)/100 )
      }else{
        DESCUENTO_VALOR=(el.descuento)
      }
      el.desctotal=DESCUENTO_VALOR
      el.valor=( ((el.cantidad*el.precioAnte)-DESCUENTO_VALOR)+el.impuesto )
      }else{
        var PRECIO=JSON.parse(JSON.stringify((el.precioAnte / (1 + (tasa)))))
        if(el.descuentoPorciento==true){
        DESCUENTO_VALOR=(((el.cantidad*PRECIO)* el.descuento)/100 )
      }else{
        DESCUENTO_VALOR=(el.descuento)
      }
      el.desctotal=DESCUENTO_VALOR
      el.precio=JSON.parse(JSON.stringify(PRECIO))
      if(PRECIO>el.costo){
        el.costo=JSON.parse(JSON.stringify(PRECIO))
      }
       
       el.valor=(((el.cantidad*PRECIO)-DESCUENTO_VALOR)+el.impuesto )

      }

      this.calcularTotales()

    }
   }
   

   },



    calcularImpuesto1() {
      this.ListProd.forEach(el => {
if(el.producto!=null){
  el.precio=JSON.parse(JSON.stringify(el.precio2))
  el.precioAnte=JSON.parse(JSON.stringify(el.precio2))
  el.costo=JSON.parse(JSON.stringify(el.precio2))
  this.calcularImporte(el)

  //Antes proceso
     /*   var tasa = el.producto.impuesto == null ? 0 : el.producto.impuesto.tasa
        if (tasa > 0) {
          el.impuestoIncluido = this.impuestoincluido
          if (this.impuestoincluido) {
            el.costo = (el.precio / (1 + (tasa / 100)))
            el.impuesto = el.precio - el.costo
            el.valor = (el.cantidad * el.precio)
          } else {
            el.impuesto = el.precio * (tasa / 100)
            el.valor = (el.cantidad * el.precio) + (el.cantidad * el.impuesto)
            el.costo = el.precio
          }
        }*/
}

      });
      this.calcularTotales();
    },

    VerificarCantidad(e) {
      if (this.PropsDespachoPedido == true && e.cantidad != e.cantidadpedida) {
        this.alerta("La cantidad pedida es diferente a la cantidad despachada", "warning");
      }
    },


    ActualizarAlmacenEnTabla() {



      this.ListProd.forEach(e => {
        e.almacen = this.EntradaAlmacen

        if (e.unidadProducto != null && e.unidadProducto.hasOwnProperty('costo')) {
          this.findExistencia(e.unidadProducto.unidadesProductosPK.producto, e.unidadProducto.unidadesProductosPK.unidad, this.EntradaAlmacen.codigo, e)
        }

      });


      this.$emit("DataActAlmacen", false)
    },


    totalesNuevo(){

      this.TotalProd= { 
        cantidad: 0.00, 
        cantExit: 0.00, 
        costo: 0.00, 
        impuesto: 0.00, 
        descuento: 0.00, 
        importe: 0.00 }
    },




// Llamar a una función en un componente secundario para los permisos de usuario.
funcionValidaPermisoPv(e) {

var Sudo = this.$store.getters.GetdatosInfUsu.usuario.sudo
var permiso = this.$store.getters.GetdatosInfUsu.usuario.usuarioPermisoPvList

//if (Sudo == false) { }
var obj = {
  accion: JSON.parse(JSON.stringify(e)),
  Permiso: permiso,
  Sudo: Sudo
}
this.$refs.RefPvMenuOpcionBotones.ClickAccionPadreEjecut(obj)

},

DataRetornMenuOpcBotonesPermisosPv(e) {

},

DataRetornPadrePermisoPv(e) {
      //Permite Cambiar Cantidad a producto"
      if (e == 'AccBtnAgregarProductoTabla') {
       // this.EjecutarPermisoPv = true
        var _this = this;
        //var OBJDATA=JSON.parse(JSON.stringify(this.ObjEjecPermisoPv)) 
        //this.ObjEjecPermisoPv=null
        setTimeout(function () {
          _this.OpenAddProducto();
         // _this.EjecutarPermisoPv = false
        }, 500);
        return;
      }
    } ,


    cargarPaginacionAll(e){
      this.TbPaginator = JSON.parse(JSON.stringify(e))
    } ,


    loaderTablaCargar(){
      this.loaderTabla=true
var _this=this;
setTimeout(function(){ _this.loaderTabla=false }, 900);
    },

    alerta(e, l) {
      if (l == "bien") {
        (this.aalert.estado = true),
          (this.aalert.color = "success"),
          (this.aalert.nombre = e),
          (this.aalert.type = "success");

        this.newwnuevo();
      }

      if (l == "error") {
        (this.aalert.estado = true),
          (this.aalert.color = "error"),
          (this.aalert.nombre = e),
          (this.aalert.type = "error");

        this.ejecucion = true;
      }


      if (l == "warning") {
        (this.aalert.estado = true),
          (this.aalert.color = "warning"),
          (this.aalert.nombre = e)
            (this.aalert.type = "warning");

        this.ejecucion = true;
      }


    },




  },

  computed: {

    ActDescBtn() {

      //if(this.ProsActuAlmacen==true){

      //this.ActualizarAlmacenEnTabla()

      //}

      if (this.ListProd.length == 0) {
        this.newDetalle()

      }
    }


  }


}
</script>

<style>

</style>