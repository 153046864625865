<template>
  <div>{{ ActDescBtn }}
    <table style="position: absolute;" width="100%" :height="this.windowSize.height - 8"
      :max-height="this.windowSize.height - 8">
      <!-- ContenidoBase-Cierre caja Pv  -->
      <tr max-height="48px" height="48px">
        <ContenidoBase :titulo="'Cierres de Cajas'" :descripcion="'Cierres de Cajas'" :EstadoBtn="EstadoBtn"
          @abrirBarraTablaDinamica="abrirTabla" @MethodNuevo="newwnuevo" @MethodGuardar="save" @MethodEliminar="remove"
          @MethodAnular="remove" @MethodImprimir="imprimirList" @MethodImprimircuadre="imprimircuadre"
          @MethodImprimircierre="imprimircierre" />
      </tr>
      <!-- fn ContenidoBase-Cierre caja Pv -->
      <tr>
        <!-- BarraPrimaria-Cierre caja Pv  --------------------------------------------------------------------------------->
        <td width="109" :max-height="this.windowSize.height - 96" v-show="BarraTablaDinamicaAprir">

          <div class="overflow-y-auto "
            v-bind:style="{ 'max-height': this.windowSize.height - 96 + 'px', 'max-width': '325px', 'width': '325px' }">

            <v-tooltip left>
              <template v-slot:activator="{ on }">
                <center>
                  <v-btn-toggle>

                    <v-btn small dark color="deep-purple accent-2" @click.prevent="dialogimprimirListFilt = true">
                      <i style="font-size: 25px;" class="fa fa-print"> </i>
                      Listado
                    </v-btn>

                    <v-btn color="teal darken-2" dark text small
                      @click.prevent="dialogFiltros = true, ObjTemTbDinam = JSON.parse(JSON.stringify(Modallfiltro))">
                      <i style="font-size: 25px;" class="fa fa-search"> </i>
                      Filtrar
                    </v-btn>

                  </v-btn-toggle>
                </center>
                <!-- /cierrecaja/findallFiltro' /cierrecaja/findall/-->



                <BarraDinamicaLimitePlus :PropsNombre="'Cierres de Caja'" PropsSearchNumber
                  :PropsSeachNombre="'Búsqueda por secuencia'" :TbPaginator="TbPaginator" :TbTotalItem="TbTotalItem"
                  :PropsList="ListItems" @CargarPaginacion="CargarPaginacion" @EmitDAta="datostabla" :headers="[
                    { text: '#Secuencia', value: 'cierrescajasPK.secuencia' },
                    { text: 'Fecha', value: 'fecha' },
                    { text: '#Caja', value: 'cierrescajasPK.caja' },
                    { text: 'Caja', value: 'cajadescripcion' },
                    { text: 'Cajero', value: 'cajero' },
                    { text: 'Valor', value: 'efectivo' },
                    { text: 'Sucursal', value: 'sucursal.descripcion' }
                  ]" :ContListSlot="[
                    { Text: '#Secuencia', NomValue: 'cierrescajasPK', Nv2: 'secuencia', Ctsl: 2, Type: 0, State: true },
                    { Text: 'Fecha', NomValue: 'fecha', Ctsl: 1, Type: 1, State: true },
                    { Text: '#Caja', NomValue: 'cierrescajasPK', Nv2: 'caja', Ctsl: 2, Type: 0, State: true },
                    { Text: 'Caja', NomValue: 'cajadescripcion', Ctsl: 1, Type: 0, State: true },
                    { Text: 'Valor', NomValue: 'convinada', Ctsl: 1, Type: 4, State: true },
                    { Text: 'Sucursal', NomValue: 'sucursal', Nv2: 'descripcion', Ctsl: 2, Type: 0, State: true },
                    { Text: 'Cajero', NomValue: 'cajero', Ctsl: 1, Type: 0, State: true },

                  ]" />
              </template>
            </v-tooltip>




          </div>
        </td>
        <!-- fn BarraPrimaria-Cierre caja Pv  ---------------------------------------------------------------------------------->
        <!-- contenido central ------------------------------------------------------------------------------ -->
        <td :max-height="this.windowSize.height - 96">
          <!-- Formulario-Cierre caja Pv -->
          <v-app id="inspire" class="overflow-y-auto "
            v-bind:style="{ 'max-height': this.windowSize.height - 96 + 'px' }">

            <v-row>

              <v-col cols="12" md="6" sm="6">
                <v-container>
                  <v-card color="grey lighten-3">
                    <center><b> Información</b></center>
                    <v-list-item-content>
                      <span><b># Cierre:</b>{{ CierresCajas.cierrescajasPK.secuencia }}.</span>
                      <span><b># Caja:</b> {{ DesCaja }}.</span>
                      <span><b># Fecha:</b> {{ FormatoFecha(CierresCajas.fecha)}}.</span>
                      <span v-if="CierresCajas.sucursal != null"><b># Sucursal:</b>
                        {{ CierresCajas.sucursal.descripcion }}.</span>

                        <span v-if="CierresCajas.hasOwnProperty('cajero') && CierresCajas.cajero!=null "><b># Cajero:</b> {{ CierresCajas.cajero.trim() }}.</span>

                    </v-list-item-content>
                  </v-card>
                  <hr>
                  <v-card color="grey lighten-3">
                    <div color="grey lighten-3" style="border: 2px solid #757575;">
                      <v-data-table color="primary" class="elevation-1" dense :headers="[{ text: 'Denominación', value: 'denominacion.valor', width: 'auto', class: ['no-gutters black--text'] },
                      { text: 'Cantidad', sortable: false, value: 'cantidad', width: 10, class: ['no-gutters black--text'] },
                      { text: 'Valor', value: 'Vtotal', width: 'auto', sortable: false, class: ['no-gutters black--text'] },
                      ]" :items="ListDenomMoneda" :items-per-page="-1">
                        <template v-slot:body.append v-if="windowSize.width > 600">
      <tr>
        <td class="border-top-bottom"><strong></strong></td>
        <td class="border-top-bottom"><strong class="d-flex justify-end" style="font-size:13px;">Totales:</strong></td>
        <td class="border-total"><strong class="d-flex justify-center" style="font-size:13px;">
            {{ currencyFormatter(totales.totalDenomiacionvalor) }} </strong></td>
      </tr>
</template>

<template v-slot:footer v-if="windowSize.width <= 600">
  <v-list-item style="font-size: 80%; border: 2px solid #757575;" class="white--text">
    <v-list-item-content>
      <b>TOTAL: {{ currencyFormatter(totales.totalDenomiacionvalor) }}</b>
    </v-list-item-content>
  </v-list-item>
</template>





<template v-slot:item.denominacion.valor="props">


  <span style="font-size: 15px;">{{ currencyFormatter(props.item.denominacion.valor) }}</span>
</template>


<template v-slot:item.cantidad="props">
  <!-- ----------------------------- cantidad -->
  <input style="border: 2px solid #607D8B; font-size: 15px; " autocomplete="off" size="5" dense outlined
    v-model.number="props.item.cantidad" @keyup="KeyUpCantidad" v-on:keyup="CalcularDenominacion"
    :disabled="disableform" id="formTecniInput" />


  <!-- <BasicAutonumeric
                        autocomplete="off"
                        size="10"
                       
                        label=""
                        dense
                        outlined
                        v-model="props.item.cantidad"
                      @input="KeyUpCantidad"
                     v-on:input="CalcularDenominacion"

                      >
                        
                      </BasicAutonumeric> -->



  <!-- <v-text-field label=""
                   
                    autocomplete="off"
                    
                     dense outlined
                     v-model.number="props.item.cantidad"
                     @keyup="KeyUpCantidad"
                     v-on:keyup="CalcularDenominacion"
                     >
                    </v-text-field> -->

</template>

<template v-slot:item.Vtotal="props">

  <p style="display:none;" v-if="props.item.cantidad.length == 0">{{ props.item.cantidad = 0 }} </p>
  <!-- jsnn.cantidad=0
  jsnn.Vtotal=0
 -->
  <!-- <span>$RD.{{props.item.Vtotal=(props.item.valor)*(props.item.cantidad)}}</span> -->
  <span v-bind:style="{ 'font-size': '17px;', 'color': getColor(props.item.Vtotal) }">
  {{cortoMoneda}}.{{currencyFormatter(props.item.Vtotal)
  }}</span>
  <!-- <span>Valor:{{props.item.cantidad}}</span> -->

</template>


</v-data-table>
</div>

</v-card>


</v-container>
</v-col>




<v-col cols="12" md="6" sm="6">
  <v-container>


    <v-form ref="formCierre" @submit.prevent="" v-model="valid" lazy-validation>

      <v-card color="grey lighten-3">
        <center><b> Tarjeta </b></center>
        <v-row>
          <v-col cols="12" md="6" sm="6">
            <!-- ----------------------------- Cheques Emitidos -->
            <v-text-field label="(Cantidad):" autocomplete="off" style="font-size: 13px;" dense outlined
              v-model.number="CierresCajas.cantidadtarjeta" @keyup="KeyUpCantidad" v-on:keyup="CalcularDenominacion"
              :disabled="disableform" id="formTecniInput" hide-details @blur="blurCantidadtarjeta">
              <template v-slot:prepend>
                <i style="font-size: 20px;" class="fa fa-list-ol"></i>
              </template>
            </v-text-field>


          </v-col>
          <v-col cols="12" md="6" sm="6">
            <!-- ----------------------------- Cheques Emitidos -->
            <VueAutonumeric label="(Valor):" autocomplete="off" style="font-size: 13px;" required outlined dense
              v-model="CierresCajas.tarjeta" @input="CalcularDenominacion" :disabled="disableform" id="formTecniInput" hide-details>
              <template v-slot:prepend>
                <i style="font-size: 20px;" class="fa fa-money"></i>
              </template>
            </VueAutonumeric>
          </v-col>
        </v-row>
      </v-card>
      <hr>
      <v-card color="grey lighten-3">
        <center><b> Cheque </b></center>
        <v-row>
          <v-col cols="12" md="6" sm="6">
            <v-text-field label="(Cantidad):" autocomplete="off" style="font-size: 13px;" dense outlined
              v-model.number="CierresCajas.cantidadcheques" @keyup="KeyUpCantidad" v-on:keyup="CalcularDenominacion"
              :disabled="disableform" id="formTecniInput" hide-details @blur="blurCantidadcheques">
              <template v-slot:prepend>
                <i style="font-size: 20px;" class="fa fa-list-ol"></i>
              </template>
            </v-text-field>
          </v-col>

          <v-col cols="12" md="6" sm="6">
            <VueAutonumeric label="(Valor):" autocomplete="off" style="font-size: 13px;" required outlined dense
              v-model="CierresCajas.cheques" @input="CalcularDenominacion" :disabled="disableform" id="formTecniInput" hide-details>
              <template v-slot:prepend>
                <i style="font-size: 20px;" class="fa fa-money"></i>
              </template>
            </VueAutonumeric>

          </v-col>
        </v-row>
      </v-card>
      <hr>
      <v-card color="grey lighten-3">
        <center><b> Transferencia </b></center>
        <v-row>
          <v-col cols="12" md="6" sm="6">
            <v-text-field label="(Cantidad):" autocomplete="off" style="font-size: 13px;" dense outlined
              v-model.number="CierresCajas.cantidadtranferencia" @keyup="KeyUpCantidad" v-on:keyup="CalcularDenominacion"
              :disabled="disableform" id="formTecniInput" hide-details @blur="blurCantidadtranferencia">
              <template v-slot:prepend>
                <i style="font-size: 20px;" class="fa fa-list-ol"></i>
              </template>
            </v-text-field>
          </v-col>

          <v-col cols="12" md="6" sm="6">
            <VueAutonumeric label="(Valor):" autocomplete="off" style="font-size: 13px;" required outlined dense
              v-model="CierresCajas.transferencia" @input="CalcularDenominacion" :disabled="disableform" id="formTecniInput" hide-details>
              <template v-slot:prepend>
                <i style="font-size: 20px;" class="fa fa-money"></i>
              </template>
            </VueAutonumeric>

          </v-col>
        </v-row>
      </v-card>
      <hr>

      <v-card color="grey lighten-3">
        <center><b> Recibo </b></center>
        <v-row>
          <v-col cols="12" md="6" sm="6">
            <v-text-field label="(Cantidad):" autocomplete="off" style="font-size: 13px;" dense outlined
              v-model.number="CierresCajas.cantidadrecibo" @keyup="KeyUpCantidad" v-on:keyup="CalcularDenominacion"
              :disabled="disableform" id="formTecniInput" hide-details @blur="blurCantidadrecibo">
              <template v-slot:prepend>
                <i style="font-size: 20px;" class="fa fa-list-ol"></i>
              </template>
            </v-text-field>


          </v-col>

          <v-col cols="12" md="6" sm="6">
            <VueAutonumeric label="(Valor):" autocomplete="off" style="font-size: 13px;" required outlined dense
              v-model="CierresCajas.recibos" @input="CalcularDenominacion" :disabled="disableform" id="formTecniInput" hide-details>
              <template v-slot:prepend>
                <i style="font-size: 20px;" class="fa fa-money"></i>
              </template>
            </VueAutonumeric>

          </v-col>
        </v-row>
      </v-card>
      <hr>

      <!--------------------------------------------------- tb MONEDA -->
      <v-card>

        <v-toolbar flat dense color="#BDBDBD">
          <v-tooltip top>
            <template v-slot:activator="{ on }">


              <v-btn v-show="disableform == false" v-on="on" @click.prevent="AddMoneda" color="green" fab x-small dark>
                <i style="font-size: 20px;" class="fa fa-plus-square"></i>
              </v-btn>


            </template>
            <span>Agregar Moneda</span>
          </v-tooltip>


          <v-toolbar-title class="font-weight-light">
            <v-card-title>MONEDA</v-card-title>
          </v-toolbar-title>
          <v-spacer></v-spacer>
          <strong style="font-size: 15px;">{{cortoMoneda}}.{{ currencyFormatter(totales.totalTotal) }}</strong>

        </v-toolbar>
        <v-data-table
          :headers="[{ text: 'Valor', value: 'valor', width: 'auto' }, { text: 'Moneda', value: 'moneda', width: 200 }, { text: 'ACCIÓN', value: 'action', width: 90 }]"
          :items="RLisMon">

          <template v-slot:item.valor="props">
            <!-- ----------------------------- cantidad -->
            <VueAutonumeric label="" autocomplete="off" style="font-size: 13px;" required outlined dense
              v-model="props.item.valor" :disabled="disableform" id="formTecniInput">
              <template v-slot:prepend>
                <i style="font-size: 20px;" class="fa fa-money"></i>
              </template>
            </VueAutonumeric>


          </template>

          <template v-slot:item.moneda="props">
            <!-- -----------------------------------------Moneda   @blur="SelctCbMoneda"  -->
            <v-combobox autocomplete="off" dense outlined label="" :items="Rmoneda" item-text="nombrecorto"
              :rules="[$rules.required]" v-model="props.item.moneda" :disabled="disableform" id="formTecniInput"
              @focus="cargarMoneda">
              <template v-slot:prepend>
                <i style="font-size: 20px;" class="fa fa-usd"></i>
              </template>
            </v-combobox>

          </template>





          <template v-slot:item.action="{ item }">
            <!-- <v-btn fab @click.prevent="editMon(item)" x-small color="grey "><i style="font-size: 20px;" class="fa fa-pencil"></i></v-btn>  -->
            <v-btn style="margin-top:-30px" fab @click.prevent="deleMon(item)" x-small color="red darken-3"><i
                style="font-size: 20px;" class="fa fa-trash-o"></i></v-btn>

          </template>
        </v-data-table>
      </v-card>
      <!--------------------------------------------------- fn tb  MONEDA -->
    </v-form>
<!-- fn Formulario-Cierre caja Pv -->


  </v-container>
</v-col>

</v-row>






</v-app>
</td>
<!-- fn contenido central ------------------------------------------------------------------------------------->
<!-- BarraSecundaria ------------------------------------------------------------------------------------------>
<td width="109" :max-height="this.windowSize.height - 96" v-show="BarraSecundariaAprir">

  <div class="overflow-y-auto "
    v-bind:style="{ 'max-height': this.windowSize.height - 96 + 'px', 'max-width': '325px', 'width': '325px' }">


  </div>

</td>
<!-- fn BarraSecundaria --------------------------------------------------------------------------------------->



</tr>
</table>
<!-- Modales -------------------------------------------------------------------------------------------------->
<!-- ------------------------------------------------------ tb-data-->
<v-dialog label="myAlert" v-model="acti_tb_data" persistent transition="dialog-bottom-transition" fullscreen
  hide-overlay>
  <v-card>
    <v-toolbar flat color="#BDBDBD">
      <i style="font-size: 20px;" class="fa fa-file-pdf-o"></i>
      <v-toolbar-title class="font-weight-light"> REPORTE </v-toolbar-title>
      <i style="font-size: 20px;" class="fa fa-file-pdf-o"> </i>

      <v-spacer></v-spacer>
      <v-btn color="grey darken-4" fab small @click="actualiza_tb_ver">
        <i style="font-size: 20px; color:#dd4b39;" class="fa fa-close"></i>
      </v-btn>
    </v-toolbar>

    <v-card-text>
      <v-card>
        <v-card-title>
          <v-text-field v-model="Modallfiltro.cierre" type="number" @keyup="CargarListTb" label="Buscar" single-line
            hide-details>
            <template v-slot:prepend-inner>
              <i style="font-size: 35px;" class="fa fa-search"></i>
            </template>
          </v-text-field>
        </v-card-title>

        <v-data-table :headers="[{ text: '#Secuencia', value: 'codigo' }, { text: 'Descripcion', value: 'descripcion' }
        ]" dense :items="ListItems" :search="Modallfiltro.cierre" @click:row="datostabla">
          <template v-slot:item.porciento="props">
            <span>{{ currencyFormatter(props.item.porciento) }}</span>
          </template>
        </v-data-table>
      </v-card>
    </v-card-text>
  </v-card>
</v-dialog>

<!-- ------------------------------------------------------ notif-->
<v-dialog label="myAlert" v-model="notif.estado" persistent max-width="350" transition="fab-transition">
  <template justify="center" v-slot:activator="{ on }"></template>

  <v-card>
    <v-card-title>
      <h4>
        <i style="font-size: 28px;" class="fa fa-warning"></i>
        CONFIRMACION
        <i style="font-size: 28px;" class="fa fa-warning"></i>
      </h4>
    </v-card-title>
    <hr />

    <v-card-text>
      <h5>{{ notif.nombre }}</h5>
    </v-card-text>

    <hr />
    <v-card-actions>
      <v-btn v-if="notif.Exec == 'eliminarCierre'" @click.prevent="eliminar(), (notif.estado = !notif.estado)" small>
        <i style="font-size: 20px;" class="fa fa-check"></i> Si
      </v-btn>



      <v-btn @click.prevent="notif.estado = !notif.estado" small>
        <i style="font-size: 20px;" class="fa fa-ban"></i> No
      </v-btn>
    </v-card-actions>
  </v-card>
</v-dialog>
<!-- ------------------------------------------------------  fn notif -->

<!------------otras funciones---------------->
<v-snackbar v-model="aalert.estado" :color="aalert.color" style="margin-top: 20%;" :vertical="true" :timeout="120000"
  top="top">
  {{ aalert.nombre }}
  <v-btn dark text @click="aalert.estado = false">Close</v-btn>
</v-snackbar>

<!-- ------------------------------------------------------ Modal-Filtro tb-datos -->
<v-dialog transition="fab-transition" label="Modal agregar factura" v-model="dialogFiltros" persistent max-width="90%"
  max-height="80%">
  <v-form ref="formFiltro" @submit.prevent="" v-model="valid" lazy-validation>
    <v-card>
      <v-toolbar flat color="indigo" dense>

        <i style="font-size: 20px;" class=""> </i>
        <v-toolbar-title style="color:white;" class="font-weight-light"> <b>FILTROS</b></v-toolbar-title>
        <i style="font-size: 20px;" class=""> </i>

        <!-- CerrarFiltro(1) -->

        <v-spacer></v-spacer>
        <v-btn color="grey darken-4" fab small
          @click="dialogFiltros = false, Modallfiltro = JSON.parse(JSON.stringify(ObjTemTbDinam))">
          <i style="font-size: 20px; color:white;" class="fa fa-close"></i>
        </v-btn>
      </v-toolbar>

      <v-card-text>
        <br>




        <v-row>


          <v-col cols="12" md="6" sm="6">
            <!-------------------------------------------------- Sucursal -->
            <v-combobox autocomplete="off" dense outlined label="Succursal" :items="RSucursal" item-text="descripcion"
              v-model="Modallfiltro.sucursal" @blur="SelctCbSucursal" @focus="cargarSucursal">
              <template v-slot:prepend>
                <i style="font-size: 20px;" class="fa fa-sitemap"></i>
              </template>
            </v-combobox>

          </v-col>

          <v-col cols="12" md="6" sm="6">

            <!-------------------------------------------------------- caja -->
            <v-combobox v-model="Modallfiltro.caja" label="Caja :" required outlined dense @blur="SelctCbCaja"
              @focus="cargarCaja" autocomplete="off" :items="RCajaFilt" item-text="descripcion">
              <template v-slot:prepend>
                <i style="font-size: 20px;" class="fa fa-desktop"></i>
              </template>
            </v-combobox>

          </v-col>


          <!-- -----------------------------  columna-1 -->
          <v-col cols="12" md="6" sm="6">

            <!----------------------------------------------  Desde -->
            <v-text-field dense outlined type="date" label="Desde*" v-model="Modallfiltro.Desde"
              :rules="[$rules.required]">

              <template v-slot:prepend>
                <i style="font-size: 20px;" class="fa fa-calendar"></i>
              </template>
            </v-text-field>



          </v-col>
          <!-- ----------------------------- fn columna-1 -->

          <!-- -----------------------------  columna-2 -->
          <v-col cols="12" md="6" sm="6">



            <!-------------------------------------------------- Hasta -->
            <v-text-field dense outlined type="date" label="Hasta*" v-model="Modallfiltro.Hasta"
              :rules="[$rules.required]">

              <template v-slot:prepend>
                <i style="font-size: 20px;" class="fa fa-calendar"></i>
              </template>

            </v-text-field>


          </v-col>
          <!-- ----------------------------- fn columna-2 -->

          <v-col cols="12" md="12" sm="12">

            <!-- ----------------------------- Cajero -->


            <v-combobox label="Cajero" autocomplete="off" dense outlined :items="RCajero" v-model="Modallfiltro.cajero"
              @blur="SelctCbUsuario" @focus="cargarCajeros" item-text="nombre">
              <template v-slot:prepend>
                <i style="font-size: 20px;" class="fa fa-fax"></i>
              </template>
            </v-combobox>

          </v-col>




        </v-row>


      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions>
        <v-spacer></v-spacer>

        <v-btn-toggle rounded class="" dense>


          <v-btn dark color="#90A4AE" @click.prevent="Limpiarfiltro">
            <i style="font-size: 28px;" class="fa fa-file-o"> </i>
            Limpiar filtro
          </v-btn>

          <v-btn dark color="blue lighten-1" @click.prevent="BuscarFiltros">
            <i style="font-size: 28px;" class="fa fa-search"> </i>
            Buscar
          </v-btn>

        </v-btn-toggle>

      </v-card-actions>

    </v-card>
  </v-form>
</v-dialog>

<!-- ------------------------------------------------------ fn Modal-Filtro tb-datos -->


<!-- ------------------------------------------------------ Modal-Reportes cierre caja -->
<v-dialog transition="fab-transition" label="Modal agregar cuenta" v-model="dialogimprimirListFilt" persistent
  max-width="80%" max-height="50%">
  <!----------------------------------------------------------------- Cuerpo Reporte -->
  <v-card color="grey lighten-3">

    <v-toolbar flat color="#BDBDBD" dense style="border: 1px solid #000000;">

      <v-avatar tile size="45">
        <img src="@/assets/iconos_micromarket/custom-reports.png" alt="Reporte">
      </v-avatar>
      <span class="font-weight-light" style="font-size: 20px;"> <b>REPORTE CIERRE DE CAJA </b> </span>

      <v-spacer></v-spacer>
      <v-btn color="red" fab small @click.prevent="dialogimprimirListFilt = false">
        <i style="font-size: 20px; color:white;" class="fa fa-close"></i>
      </v-btn>
    </v-toolbar>

    <hr>

    <v-container style="border: 1px solid #000000;">
      <v-form ref="form" @submit.prevent="" v-model="valid" lazy-validation>
        <v-row class="pa-0 ma-0">

          <v-col cols="12" md="6" sm="6">

            <v-text-field dense outlined type="date" label="Desde*" v-model="datosImprecionAll.desde"
              :rules="[$rules.required]" @blur="BlurDesde">

              <template v-slot:prepend>
                <i style="font-size: 20px;" class="fa fa-calendar"></i>
              </template>
            </v-text-field>


            <v-combobox autocomplete="off" dense outlined label="Succursal" :items="RSucursal" item-text="descripcion"
              v-model="datosImprecionAll.sucursal" @blur="SelctCbSucursalReport" @focus="cargarSucursal">
              <template v-slot:prepend>
                <i style="font-size: 20px;" class="fa fa-sitemap"></i>
              </template>
            </v-combobox>
          </v-col>

          <v-col cols="12" md="6" sm="6">



            <!-------------------------------------------------- Hasta -->
            <v-text-field dense outlined type="date" label="Hasta*" v-model="datosImprecionAll.hasta"
              :rules="[$rules.required]" @blur="BlurHasta">

              <template v-slot:prepend>
                <i style="font-size: 20px;" class="fa fa-calendar"></i>
              </template>

            </v-text-field>


          </v-col>

        </v-row>
      </v-form>
    </v-container>

    <hr>

    <ModalReporteFiltros @DataReporteBasico="DDataReporteBasico" :Datos="datosImprecionAll" />

  </v-card>
  <!-------------------------------------------------------------------------------- Fn Cuerpo Reporte -->
</v-dialog>
<!-- ------------------------------------------------------ fn Modal-Reportes cierre caja -->





<!-- fn Modales ----------------------------------------------------------------------------------------------->


</div>
</template>

<script>
import { EventBus } from "../../event-bus";

import BarraDinamicaNormal from "../../components/BarraDinamicaNormal";
import BasicAutonumeric from "../../components/VueAutonumeric";
import VueAutonumeric from "../../components/AutoNumericNuevo";
import ContenidoBase from "../../components/ContenidoBase";
import BarraDinamicaLimitePlus from "../../components/BarraDinamicaLimitePlus";
import ModalReporteFiltros from "../../components/ModalReporteFiltros";
import { currencyFormatter,
  FormatoFecha,
  HoraFormatter,
  HoraFormatter2,
  RedondeoValor,
  RedondeoBasico,
  RedondeoValorCantRed,
  fechaNueva,
  getParamFecha
} from "@/js/Funciones.js";
export default {
  components: {

    BasicAutonumeric,
    VueAutonumeric,
    ContenidoBase,
    BarraDinamicaLimitePlus,
    ModalReporteFiltros

  },
  created() {
    var json = {
      titulo: "Cierres de Cajas",
      descripcion: "Cierre de Cajas",
      save: false,
      eliminar: false,
      anular: false,
      nuevo: false,
      imprimir: false,
      accion: [
        "CierresCajasNew",
        "CierresCajasSave",
        "CierresCajasRemove",
        "CierresCajasAnular",
        "CierresCajasPrintList"
      ],
    };
    EventBus.$emit("anularBotonesAccion", json);
  },
  //mounted-carga de inicio
  mounted() {
    EventBus.$on("abrirMenuizquierdo", this.abrirTabla);
    // EventBus.$on("abrirMenuizquierdo", this.abrirTabla2);
    // EventBus.$on("CierresCajasNew", this.new);
    // EventBus.$on("CierresCajasSave", this.save);
    // EventBus.$on("CierresCajasRemove", this.remove);
    // EventBus.$on("CierresCajasAnular", this.remove);
    // EventBus.$on("CierresCajasPrintList", this.imprimirList);
    EventBus.$on("emitCierresCajas", this.datostabla);

    this.cargarCaja2()
    this.cargarDenominocion()
    //this.cargarTodo();
    this.getMonedaBase(null)
    
  },
 //fn mounted-carga de inicio
  data: () => ({

    dialogimprimirListFilt: false,
    cajacierre:null,
    cortoMoneda:"",
    datosImprecionAll: {
      nombre: 'CIERRE DE CAJA',
      UrlImprimir: '/cierrecaja/imprimirListCierre',
      UrlExel: '/cierrecaja/ExcelListCierre',
      UrlCorreo: '/cierrecaja/enviarEmailListCierre',
      secuencia: 0,
      desde: new Date().toISOString().substr(0, 10),
      hasta: new Date().toISOString().substr(0, 10),
      sucursal: null
    },


    disableform: false,

    DesCaja: '',
    Rpropietario: [],
    RSucursal: [],
    RCaja: [],
    RCajero: [],
    RCajaFilt: [],
    RLisMon: [],

    ListaTiposClientes: [],
    ListDenomMoneda: [],

    Rdenominacion: [],
    Rmoneda: [],

    totales: {

      totalDenomiacionvalor: 0,
      totalTotal: 0

    },

    totalValor: 0,



    ejecucion: true,
    windowSize: { width: "auto", height: "auto" },




    dialogFiltros: false,
    Modallfiltro: {
      cierre: null,
      sucursal: null,
      Desde: null,
      Hasta: null,
      cajero: null,
      caja: null,

    },


    ObjTemTbDinam: null,
    ListItems: [],
    TbTotalItem: 0,
    TbPaginator: {
      page: 1,
      itemsPerPage: 5,
      pageStart: 1,
      pageStop: 15,
      pageCount: 10,
      itemsLength: 5
    },






    CierresCajas: {

      codigo: 0,
      sucursal: null,
      fecha: fechaNueva(),
      efectivo: 0,

      cantidadcheques: 0,
      cheques: 0,

      cantidadrecibo: 0,
      recibos: 0,

      cantidadtarjeta: 0,
      tarjeta: 0,

      cantidadtranferencia:0,
      transferencia:0,


      cierrescajasPK: {
        caja: 0,
        secuencia: 0
      }

      // cierrescajasPK:{
      // caja: 1,
      // secuencia: 1588},

    },

    EstadoBtn: {
      BarraTablaDinamicaAprir: true,
      nuevo: false,
      guardar: true,
      eliminar: false,
      anular: false,
      imprimir: true,
      vercuenta: false,
      guardartemporal: false,
      restaurartemporal: false,
      importarDato: false,
      cargarProducto: false,
      ImprimirproductoInv: false,
      imprimircuadre: true,
      imprimircierre: true,
    },




    text: "",
    valid: true,
    BarraTablaDinamicaAprir: false,
    BarraSecundariaAprir: false,

    headers: [{ text: "Tipo Clientes", value: "descripcion" }],

    slotlist: [
      { dt0: "codigo", dt1: null, dt2: null, dt3: 1, dt4: "Código:" },
      {
        dt0: "descripcion",
        dt1: null,
        dt2: null,
        dt3: 1,
        dt4: "Descripcion:"
      },
      { dt0: "activo", dt1: null, dt2: null, dt3: 0.1, dt4: "Desactivo" },
    ],



    list: [],
    search: "",
    acti_tb_data: false,




    notif: {
      estado: false,
      nombre: "",
      Exec: ""
    },
    aalert: {
      estado: false,
      color: null
    },

    items: [
      { name: 'Edward', value: 21 },
      { name: 'Sharpe', value: 37 },
      { name: 'And', value: 45 },
      { name: 'The', value: -12 },
      { name: 'Magnetic', value: 13 },
      { name: 'Zeros', value: 37 }
    ],



  }),


  //   destroyed() {
  //     location.reload();
  //   },
  methods: {
    FormatoFecha,
  HoraFormatter,
  HoraFormatter2,
  RedondeoValor,
  RedondeoBasico,
  RedondeoValorCantRed,
  fechaNueva,
  getParamFecha,
    ver() {

      this.items.sort(function (a, b) {
        if (a.value > b.value) {
          return 1;
        }
        if (a.value < b.value) {
          return -1;
        }
        // a must be equal to b
        return 0;
      });



    },


    // Abriendo un modal montrando los datos en ella.
    abrirTabla() {
      this.BarraTablaDinamicaAprir = !this.BarraTablaDinamicaAprir;

      if (this.BarraTablaDinamicaAprir == true) {
        this.Limpiarfiltro()
      }


    },
    async cargarTodo() {

      //////////////////OBTENER EL ULTIMO Numero de Cierre ///////////////////////////////////

      var headers = this.$store.getters.GetheadersAxios
      await this.$axios.get(this.$hostname + this.$hName + "/cierrecaja/ultimocierre/" + 1 + "/", { headers: headers })
        .then(res => { console.log('Veeer'), console.log(res.data) })
        .catch(error => console.log(error));


      // await this.$axios
      //   .post(
      //     this.$hostname + this.$hName + "/services/cierrecaja/ultimocierre",
      //     { caja: 1,
      //          usuario:JSON.parse(JSON.stringify(this.$user))  
      //     }
      //   )
      //   .then(res => {
      //     //this.CierresCajas.codigo = res.data.caja, 
      //     this.CierresCajas.cierrescajasPK.secuencia=res.data.caja,
      //     this.CierresCajas.cierrescajasPK.caja=1,
      //     this.DesccCaja(1)
      //   })
      //   .catch(error => console.log(error));


      // await this.$axios
      //   .post(
      //     this.$hostname +
      //       this.$hName +
      //       "/services/tipocliente/findalltipoclientes",
      //     {
      //       usuario:JSON.parse(JSON.stringify(this.$user)) 
      //     }
      //   )
      //   .then(res => (this.ListaTiposClientes = res.data))
      //   .catch(error => console.log(error));

    },

    DesccCaja(e) {

      this.DesCaja = ""
      // console.log('Buennnn')
      // console.log(e)
      // console.log('ThisRcaja')
      // console.log(this.RCaja)

      this.RCaja.forEach(element => {

        if (element.codigo == e) { this.DesCaja = element.descripcion }

      });




    },

getMonedaBase(moneda){
  if(moneda!=null){
    var headers = this.$store.getters.GetheadersAxios
        this.$axios.get(this.$hostname + this.$hName + "/monedas/findallActivos/", { headers: headers })
        .then(res => {
          var ObjProRow = res.data.filter((ee) => ee.codigo == moneda );
          if(ObjProRow.length>0){
            this.cortoMoneda=ObjProRow[0].nombrecorto.trim()
          }else{
            var MONEDABASE=this.$store.getters.GetConfiguracionGeneral.config.monedabase
            if(MONEDABASE!=null){
              this.cortoMoneda=MONEDABASE.nombrecorto.trim()
            }
          }
        
        })
        .catch(error => console.log(error));
}else{
  var MONEDABASE=this.$store.getters.GetConfiguracionGeneral.config.monedabase
    if(MONEDABASE!=null){
      this.cortoMoneda=MONEDABASE.nombrecorto.trim()
    }
}
  
},

   // Tomando los datos de la tabla y poniéndolos en el formulario.
    datostabla(e) {
      this.cajacierre=null
// this.findCaja(e.cierrescajasPK.caja)
      this.DesCaja = ''
      this.totalValor = 0

      this.ListDenomMoneda = []

      this.CierresCajas = JSON.parse(JSON.stringify(e));
      //this.CierresCajas.codigo=e.cierrescajasPK.secuencia
      //this.CierresCajas.caja=e.cierrescajasPK.caja

      // this.CierresCajas.fecha = new Date(e.fecha).toISOString().substr(0, 10)

      this.CierresCajas.fecha = this.getParamFecha(e.fecha+"T04:00")
      e.denominacionCierrecajaList.sort(function (a, b) {
        if (a.denominacion.valor > b.denominacion.valor) {
          return 1;
        }
        if (a.denominacion.valor < b.denominacion.valor) {
          return -1;
        }
        // a must be equal to b
        return 0;
      });



      // this.$axios
      //         .post(
      //           this.$hostname +
      //             this.$hName +"/services/cierrecaja/findIdCierreMoneda",
      //             {
      //               datos:JSON.parse(JSON.stringify(e)),
      //               usuario:JSON.parse(JSON.stringify(this.$user)) 

      //             }

      //         )
      //         .then(res =>{ this.RLisMon=res.data
      //         //console.log('Dtos Dtos'), console.log(res.data)

      //         })
      //         .catch(error => this.alerta("Dato no guardado:"+error,"error"));


      this.RLisMon = JSON.parse(JSON.stringify(e.cierrecajaMonedaList))
      this.ListDenomMoneda = JSON.parse(JSON.stringify(e.denominacionCierrecajaList))

      // var rc = this.RCaja
      var headers = this.$store.getters.GetheadersAxios
      this.$axios.get(this.$hostname + this.$hName + "/cajas/findAllActivos", { headers: headers })
        .then(res => { 
          res.data.forEach(element => {
        if (element.codigo == e.cierrescajasPK.caja) { 
          this.DesCaja = element.descripcion 
          this.cajacierre=element
        }
      });
        });

if(e.moneda!=null){
  this.getMonedaBase(e.moneda)
}else{
  this.getMonedaBase(null)
}
    
        


      this.acti_tb_data = false

      this.CalcularDenominacion()



      var _this = this;
      setTimeout(function () { _this.totalValor = JSON.parse(JSON.stringify(_this.totales.totalTotal)) }, 5);


    },
 // Envío de una solicitud POST al servidor y guarda  el sierre de caja .
    save() {


      // if(this.totales.totalTotal>0 ){

      // this.alerta("cierre no modificable","error")

      //   return 0;
      // }



      if (this.$refs.formCierre.validate()) {

        if (this.ejecucion == true) {

          this.ejecucion = false

          EventBus.$emit("loading", true);
          // var jsn={
          //    CierresCajas:JSON.parse(JSON.stringify(this.CierresCajas)),
          //    ListDenomMoneda:JSON.parse(JSON.stringify(this.ListDenomMoneda)),
          //    RLisMon:JSON.parse(JSON.stringify(this.RLisMon)),
          //    totalDenomiacionvalor:JSON.parse(JSON.stringify(this.totales.totalDenomiacionvalor)),
          //   //  usuario:JSON.parse(JSON.stringify(this.$user)) 
          //   }

          var jsn = JSON.parse(JSON.stringify(this.CierresCajas))
          jsn.denominacionCierrecajaList = JSON.parse(JSON.stringify(this.ListDenomMoneda))
          jsn.cierrecajaMonedaList = JSON.parse(JSON.stringify(this.RLisMon))

         var fecha=new Date().toISOString().substr(0,11)+this.CierresCajas.horacierre
         jsn.horacierre=new Date(fecha)
  
          this.$axios
            .post(this.$hostname + this.$hName + "/cierrecaja/saveOrUpdate/", JSON.parse(JSON.stringify(jsn)), { headers: this.$store.getters.GetheadersAxios })
            .then(res => { this.alerta("Dato guardado correctamente!", "bien") })
            .catch(error => {
              this.alerta("Dato no guardado correctamente", "error")
             } );

          //     var jsn={
          //  datos:JSON.parse(JSON.stringify(this.CierresCajas)),
          //  usuario:JSON.parse(JSON.stringify(this.$user)) 
          // }
          // this.$axios
          //   .post(
          //     this.$hostname +
          //       this.$hName +
          //       "/services/cierrecaja/saveOrUpdate",
          //     JSON.parse(JSON.stringify(jsn))
          //   )
          //   .then(res => this.alerta("Dato guardado correctamente!", "bien"))
          //   .catch(error =>
          //     this.alerta("Dato no guardado","error"
          //       // "hubo un error , por favor verifique el campo descripcion!",
          //       // "error"
          //     )
          //   );
        }

      } else {
        this.alerta("Los campos en rojo son requeridos", "error");
      }




    },



    NotifMessage() {

      if (this.caja.codigo != 0 && this.caja.codigo != null) {

        var notif = {
          estado: true,
          nombre: "¿Seguro que desea eliminar este registro?",
          Exec: "eliminarCierre"
        }

        this.Exec_notif(notif)


      } else { this.alerta("Seleccione un cierre de caja para eliminar", "error") }


    },

    Exec_notif(e) {

      //console.log(e)
      this.notif = JSON.parse(JSON.stringify(e))

      //this.Elim_notif.estado = !this.Elim_notif.estado;
    },





    remove() {

      if (this.CierresCajas.codigo != 0 && this.CierresCajas.codigo != null) {

        this.Elim_notif.estado = !this.Elim_notif.estado;
      } else { this.alerta("Seleccione un tipo de cliente para eliminar", "error") }


    },
    eliminar() {

      var jsn = {
        datos: JSON.parse(JSON.stringify(this.CierresCajas)),
        usuario: JSON.parse(JSON.stringify(this.$user))
      }

      if (this.CierresCajas.codigo != 0) {
        this.$axios
          .post(
            this.$hostname +
            this.$hName +
            "/services/tipocliente/borradoTipoCliente",
            JSON.parse(JSON.stringify(jsn))
          )
          .then(res => { this.alerta(res.data.mensage, res.data.estado) })
          .catch(error =>
            this.alerta(
              // "hubo un error , por favor verifique el campo descripcion!"
              "Dato no eliminado",
              "error"
            )
          );
      }


    },
    anular() {
      if (this.Elim_notif.estado) {

        var jsn = {
          datos: JSON.parse(JSON.stringify(this.CierresCajas)),
          usuario: JSON.parse(JSON.stringify(this.$user))
        }

        this.$axios
          .post(
            this.$hostname +
            this.$hName +
            "/services/tipocliente/removeTipoCliente",
            JSON.parse(JSON.stringify(jsn))
          )
          .then(res => this.alerta("Dato eliminado correctamente!", "bien"))
          .catch(error =>
            this.alerta(
              // "hubo un error , por favor verifique el campo descripcion!"
              "Dato no eliminado",
              "error"
            )
          );
      }
    },
 // Abriendo una nueva ventana y escribiendo el pdf en ella.
    imprimirList() {

      var sec = this.CierresCajas.cierrescajasPK.secuencia
      var caj = this.CierresCajas.cierrescajasPK.caja
      var headers = this.$store.getters.GetheadersAxios
      this.$axios.get(this.$hostname + this.$hName + "/cierrecaja/imprimir/" + sec + "/" + caj, { headers: headers }).then(res => {
        /*let pdfWindow = window.open("Reporte de imprecion")
        pdfWindow.document.write("<embed width='100%' height='100%' type='application/pdf'   src='data:application/pdf;base64," +
          encodeURI(res.data) + "'></embed>")*/

          var PDF=`data:application/pdf;base64,${encodeURI(res.data)}`;
          EventBus.$emit("openPDF",PDF ,'cierrecaja');

      });

    },

    // Envío de una solicitud al servidor para obtener los datos para imprimir.
    imprimircuadre() {
      console.log('--imprimircuadre--')
      this.$axios.get(this.$hostname + this.$hName + "/cierrecaja/findallCuadre/" + this.CierresCajas.cierrescajasPK.caja + "/" + this.CierresCajas.cierrescajasPK.secuencia, { headers: this.$store.getters.GetheadersAxios })
        .then(res => {
          if(res.data.length>0){

           // var RESDATA=
            //TotalDevuelto : cobertura sobrante: https://decasystem.atlassian.net/browse/MIC-2422
            //double TotalDevueltoNocobertura=(dat.getJsonNumber(29).doubleValue()-dat.getJsonNumber(52).doubleValue());
            //restandole lo devuelto adomicilio cobertura
            var RESDATA=JSON.parse(JSON.stringify(res.data))
            if(RESDATA[0][52]>0){
              RESDATA[0][29]=(RESDATA[0][29]-(RESDATA[0][29]-RESDATA[0][52]))
            }
            console.log('despues d',RESDATA)
          var msg = {
            accion: "print",
            formato: JSON.parse(localStorage.caja).formatoimpresioncontado.trim(),
            impresora: JSON.parse(localStorage.printerSelect).nombre,
            conf: JSON.parse(sessionStorage.ConfiguracionGeneral).config,
            data: RESDATA,
            cierre: this.CierresCajas,
            caja: this.cajacierre,
            cajero: this.CierresCajas.cajero
          }; 
          msg.cierre.todevolucion=res.data[0][44]
          msg.cierre.cnttdevolucion=res.data[0][45]
          msg.cierre.recibostransferencia=res.data[0][50]
          msg.cierre.domiciliodev=res.data[0][52]

          this.imprimir(msg, "cuadre");
        }else{
          this.alerta("este cierre no contiene movimiento", "error");
        }

        })
        .catch(error => console.log(error));

    }, 
    imprimircierre() {
      this.$axios.get(this.$hostname + this.$hName + "/cierrecaja/findById/" + this.CierresCajas.cierrescajasPK.caja + "/" + this.CierresCajas.cierrescajasPK.secuencia, { headers: this.$store.getters.GetheadersAxios })
        .then(res => {
          var msg = {
            accion: "print",
            formato: JSON.parse(localStorage.caja).formatoimpresioncontado.trim(),
            impresora: JSON.parse(localStorage.printerSelect).nombre,
            data: JSON.parse(sessionStorage.ConfiguracionGeneral),
            cierre: res.data,
            // caja: JSON.parse(localStorage.caja),
            caja: this.cajacierre,
            cajero: this.CierresCajas.cajero
          };
          this.imprimir(msg, "cierre");
        }
        )
        .catch(error => console.log(error));

    },
    // Envío de una solicitud POST al servidor con los datos a imprimir.
    imprimir(datos, operacion) {
      datos.operacion = operacion;
      console.log('---ver--')
      console.log(datos)
      this.$axios
        .post("http://localhost:15999/impresora/imprimir", datos)
        .then((res) => {
          // this.newwnuevo();
        })
        .catch((error) => console.error(error));
    },
   // Un método que se llama cuando el usuario hace clic en el botón para imprimir el informe.
    DDataReporteBasico(e) { this.dialogimprimirList = e },

  // Al verificar si la longitud de la cadena es 0, si lo es, está configurando la fecha a la fecha de
  // hoy.
    BlurDesde() {

      if (this.datosImprecionAll.desde.toString().length == 0) {
        this.datosImprecionAll.desde = new Date().toISOString().substr(0, 10)
      }

    },


    // Un método que se llama cuando el campo de entrada pierde el foco y trae la fecha de hoy si es null.
    BlurHasta() {

      if (this.datosImprecionAll.hasta.toString().length == 0) {
        this.datosImprecionAll.hasta = new Date().toISOString().substr(0, 10)
      }

    },


    // Crear un nuevo objeto y asignarle valores.
    newwnuevo() {

      this.BarraTablaDinamicaAprir = false

      this.totalValor = 0
      this.DesCaja = ''
      this.ListDenomMoneda = []
      this.RLisMon = []

      this.CierresCajas = {

        codigo: 0,
        sucursal: null,
        fecha: new Date().toISOString().substr(0, 10),
        efectivo: 0,

        cantidadcheques: 0,
        cheques: 0,

        cantidadrecibo: 0,
        recibos: 0,

        cantidadtarjeta: 0,
        tarjeta: 0,

        cantidadtranferencia:0,
        transferencia:0,

        cierrescajasPK: {
          caja: 0,
          secuencia: 0
        }


      },

        this.totales = {

          totalDenomiacionvalor: 0,
          totalTotal: 0

        },




        this.ejecucion = true
        EventBus.$emit("loading", false);
      EventBus.$emit("actualizaBarraBusqueda2");

      // var _this=this;
      // setTimeout(function(){ 

      //   _this.cargarTodo();
      //   _this.cargarDenominocion()
      // }, 2);



    },

   // Realizar una solicitud GET al servidor y devolver los datos al componente Vue.
    cargarMoneda() {

      var headers = this.$store.getters.GetheadersAxios
      this.$axios.get(this.$hostname + this.$hName + "/monedas/findallActivos/", { headers: headers })
        .then(res => (this.Rmoneda = res.data))
        .catch(error => console.log(error));


      // this.$axios.post(this.$hostname+this.$hName+'/services/Moneda/findall',{
      //   usuario:JSON.parse(JSON.stringify(this.$user))  
      // })
      // .then(res=>( this.Rmoneda=res.data))




    },

  // Haciendo una solicitud GET al servidor y luego configurando la respuesta a la variable RSucursal.
    cargarSucursal() {

      var headers = this.$store.getters.GetheadersAxios
      this.$axios.get(this.$hostname + this.$hName + "/sucursal/findallActivos/", { headers: headers })
        .then(res => { this.RSucursal = res.data, this.RCaja = [] })
        .catch(error => console.log(error));

      // this.$axios.post(this.$hostname+this.$hName+'/services/sucursal/findall',{
      // usuario:JSON.parse(JSON.stringify(this.$user)) 
      // })
      // .then(res=>{this.RSucursal=res.data, this.RCaja=[]})


    },


    // Comprobando si el valor de la selección es nulo o indefinido.
    SelctCbSucursalReport() {
      if (this.datosImprecionAll.sucursal == null) {

        this.datosImprecionAll.sucursal = ''
      }

      if (this.datosImprecionAll.sucursal.codigo == undefined) {

        this.alerta("Seleccione una sucursal", "error");
        this.datosImprecionAll.sucursal = ''
        return

      }

    },


   // Comprobando si el valor de la selección es nulo o indefinido.
    SelctCbSucursal() {

      if (this.Modallfiltro.sucursal == null) {

        this.Modallfiltro.sucursal = ''
      }

      if (this.Modallfiltro.sucursal.codigo == undefined) {

        this.alerta("Seleccione una sucursal", "error");
        this.Modallfiltro.sucursal = ''
        return

      }
    },



   // Llamar a una API y obtener los datos de la API y almacenarlos en la variable RCaja.
    cargarCaja2() {

      var headers = this.$store.getters.GetheadersAxios
      this.$axios.get(this.$hostname + this.$hName + "/cajas/findAllActivos", { headers: headers })
        .then(res => { this.RCaja = res.data; });

    },
  // Llamar a la API y obtener los datos.
    cargarCajeros() {

      var headers = this.$store.getters.GetheadersAxios
      this.$axios.get(this.$hostname + this.$hName + "/usuario", { headers: headers })
        .then(res => { this.RCajero = res.data; });

    },

   // Llamar a un método api que está para traer las caja.
    cargarCaja() {

      console.log(this.Modallfiltro.sucursal)

      if (this.Modallfiltro.sucursal != null) {


        var headers = this.$store.getters.GetheadersAxios
        //this.$axios.get(this.$hostname + this.$hName + "/cajas/findAllbySucursal/"+this.Modallfiltro.sucursal.codigo+"/",{headers:headers})
        this.$axios.get(this.$hostname + this.$hName + "/cajas/find/sucursal/" + this.Modallfiltro.sucursal.codigo + "", { headers: headers })
          .then(res => { this.RCajaFilt = res.data })
          .catch(error => console.log(error));


      }

      if (this.Modallfiltro.sucursal == null) {

        this.alerta("Seleccione una sucursal", "error");

      }





    },

  // Comprobando si el valor de la selección es nulo o indefinido.
    SelctCbCaja() {

      if (this.Modallfiltro.caja == null) {

        this.Modallfiltro.caja = ''
      }

      if (this.Modallfiltro.caja.codigo == undefined) {

        if (this.Modallfiltro.sucursal != null) {
          this.alerta("Seleccione una caja", "error");
        }


        this.Modallfiltro.caja = ''
        return

      }
    },

   // Comprobando si el valor de la selección es nulo o indefinido.
    SelctCbUsuario() {

      if (this.Modallfiltro.caja == null) {

        this.Modallfiltro.caja = ''
      }

      if (this.Modallfiltro.caja.codigo == undefined) {

        if (this.Modallfiltro.sucursal != null) {
          this.alerta("Seleccione una caja", "error");
        }


        this.Modallfiltro.caja = ''
        return

      }
    },



    AddMoneda() {

      var jsn = {
        valor: 0,
        moneda: null
      }

      this.RLisMon.push(jsn)

    },

   // Eliminar el elemento de la tabla.
    deleMon(item) {


      const index = this.RLisMon.indexOf(item)
      confirm('Desea eliminar este Moneda?') && this.RLisMon.splice(index, 1)

    },

   // Obtener una lista de denominaciones del servidor y luego insertarlas en una tabla.
    cargarDenominocion() {

      this.ListDenomMoneda = []

      var headers = this.$store.getters.GetheadersAxios
      this.$axios.get(this.$hostname + this.$hName + "/cierrecaja/getdenominacionpesoslist/", { headers: headers })
        .then(res => {
          res.data.forEach(element => {
            var jsnn = {
              denominacion: JSON.parse(JSON.stringify(element)),
              cantidad: 0,
              Vtotal: 0,
              secuencia: 0
            }

            this.ListDenomMoneda.push(jsnn);
          });
        })
        .catch(error => console.log(error));


    },





    KeyUpDiaAc(e) {

      if (e.key) {
        var mynumeral = require('numeral')
        this.Cuadres.dias = mynumeral(this.Cuadres.dias).value()
        //console.log(mynumeral(""+this.mensajero.metadeenvios)).value()
        if (this.Cuadres.dias == null) {

          this.Cuadres.dias = 0
        }

      }
      else {
        console.log("no se puede poner letras o signos")
      }



    },


    getColor(e) {

      if (e <= 0) {

        return 'red'
      } else {

        return 'green'

      }


    },


   // Cálculo del total de las denominaciones.
    CalcularDenominacion() {


      this.totales.totalDenomiacionvalor = 0


      this.ListDenomMoneda.forEach(element => {

        element.Vtotal = (element.cantidad) * (element.denominacion.valor)

        this.totales.totalDenomiacionvalor += element.Vtotal

      });

      this.CierresCajas.efectivo = this.totales.totalDenomiacionvalor
      // this.totales.totalTotal = (this.totales.totalDenomiacionvalor) + (this.CierresCajas.cheques * this.CierresCajas.cantidadcheques) + (this.CierresCajas.recibos * this.CierresCajas.cantidadrecibo) + (this.CierresCajas.tarjeta * this.CierresCajas.cantidadtarjeta)
      this.totales.totalTotal = (this.totales.totalDenomiacionvalor) + (this.CierresCajas.cheques )+ (this.CierresCajas.transferencia )+ (this.CierresCajas.recibos ) + (this.CierresCajas.tarjeta )
    },



 blurCantidadtarjeta() {

  var _this=this;
setTimeout(function(){ 
  
  if(_this.CierresCajas.cantidadtarjeta==null){
    _this.CierresCajas.cantidadtarjeta=0
  }

  if(_this.CierresCajas.cantidadtarjeta.length == 0){
    _this.CierresCajas.cantidadtarjeta=0
  }

}, 300);
  
    },

    blurCantidadcheques() {

var _this=this;
setTimeout(function(){ 

if(_this.CierresCajas.cantidadcheques==null){
  _this.CierresCajas.cantidadcheques=0
}

if(_this.CierresCajas.cantidadcheques.length == 0){
  _this.CierresCajas.cantidadcheques=0
}

}, 300);

  },


  blurCantidadtranferencia() {

var _this=this;
setTimeout(function(){ 

if(_this.CierresCajas.cantidadtranferencia==null){
  _this.CierresCajas.cantidadtranferencia=0
}

if(_this.CierresCajas.cantidadtranferencia.length == 0){
  _this.CierresCajas.cantidadtranferencia=0
}

}, 300);

  },


  blurCantidadrecibo() {

var _this=this;
setTimeout(function(){ 

if(_this.CierresCajas.cantidadrecibo==null){
  _this.CierresCajas.CierresCajas.cantidadrecibo=0
}

if(_this.CierresCajas.cantidadrecibo.length == 0){
  _this.CierresCajas.cantidadrecibo=0
}

}, 300);

  },




   // Comprobando si la entrada es un número o no y lo combiarte a entero.
    KeyUpCantidad(e) {

      //  if(e.key.charCodeAt(0)>=48 & e.key.charCodeAt(0)<=57 ){}



      if (e.target.value.length == 0) {

        e.target.value = 0

      }


      if (e.key) {

        var mynumeral = require('numeral')
        e.target.value = mynumeral(e.target.value).value()

        //console.log(mynumeral(""+this.mensajero.metadeenvios)).value()
        if (e.target.value == null) {

          e.target.value = 0
        }

      }
      else {

        e.key = ""

        console.log("no se puede poner letras o signos")
      }


      if (e.target.value.length == 0) {

        e.target.value = 0
        e.key = 0
      }



    },




   // Un método que se llama cuando se hace clic en un botón.
    actualiza_tb_ver() {

      this.acti_tb_data = !this.acti_tb_data;
      this.Limpiarfiltro()

    },

    // Limpieza del filtro y recarga de los datos.
    Limpiarfiltro() {

      var Pg = {
        itemsLength: this.TbPaginator.itemsLength,
        itemsPerPage: this.TbPaginator.itemsPerPage,
        page: 1,
        pageCount: 1,
        pageStart: 0,
        pageStop: this.TbPaginator.itemsPerPage,
      }

      this.Modallfiltro = {
        cierre: null,
        sucursal: null,
        Desde: null,
        Hasta: null,
        cajero: null,
        caja: null,
      }

      this.ListItems = []
      this.TbTotalItem = 0
      this.TbPaginator = JSON.parse(JSON.stringify(Pg))
      this.CargarListTb()
      this.dialogFiltros = false

    },

    // Una función que se llama cuando el usuario hace clic en el botón "Buscar".
    BuscarFiltros() {
      var Pg = {
        itemsLength: this.TbPaginator.itemsLength,
        itemsPerPage: this.TbPaginator.itemsPerPage,
        page: 1,
        pageCount: 1,
        pageStart: 0,
        pageStop: this.TbPaginator.itemsPerPage,
      }

      if (this.Modallfiltro.cierre != null && this.Modallfiltro.cierre.toString().length == 0) { this.Modallfiltro.cierre = null }

      this.TbPaginator = JSON.parse(JSON.stringify(Pg))
      this.CargarListTb()
      this.dialogFiltros = false

    },


    cargarPaginacionAll(e) {

      if (e.itemsPerPage == -1) { e.itemsPerPage = 15 }
      this.CargarPaginacion(e, this.Modallfiltro.cierre)
    },

   // Un método que se llama cuando el usuario cambia la página de la tabla.
    CargarPaginacion(e, s) {

      this.Modallfiltro.cierre = JSON.parse(JSON.stringify(s))

      if (e.itemsPerPage == -1) { e.itemsPerPage = 15 }
      //var pg =
      this.TbPaginator = JSON.parse(JSON.stringify(e))
      this.CargarListTb()

    },

    // Llamar a un método que está en el backend, que es un procedimiento almacenado que devuelve una
    // lista de objetos.
    CargarListTb() {

      var sucursal = null
      var caja = null
      if (this.Modallfiltro.sucursal != null) { sucursal = this.Modallfiltro.sucursal.codigo }
      if (this.Modallfiltro.caja != null) { caja = this.Modallfiltro.caja.codigo }

      var obj = this.TbPaginator.itemsPerPage + "/" + this.TbPaginator.pageStart + "/" + this.Modallfiltro.cierre + "/" + sucursal + "/" + caja + "/" + this.Modallfiltro.Desde + "/" + this.Modallfiltro.Hasta + "/" + (this.Modallfiltro.cajero == null ? null : "'" + this.Modallfiltro.cajero.nombre + "'")

      this.$axios.get(this.$hostname + this.$hName + '/cierrecaja/findListCierrecajaPlus/' + obj, { headers: this.$store.getters.GetheadersAxios })
        .then(res => { 
          // this.ListItems = res.data.ListItems, 
          this.TbTotalItem = res.data.TbTotalItem 
        
          this.cargadatosmodif(res.data.ListItems)
        })
    },


    cargadatosmodif(e){
      var LISTA_CIERRE=e
      var headers = this.$store.getters.GetheadersAxios
      this.$axios.get(this.$hostname + this.$hName + "/cajas/findAllActivos", { headers: headers })
        .then(res => { 
      var bar = new Promise((resolve, reject) => {
        LISTA_CIERRE.forEach((value, index, array) => {
          var ObjProRow = res.data.filter((ee) => ee.codigo == value.cierrescajasPK.caja);
          value.cajadescripcion=ObjProRow[0].descripcion.trim()
    });

    resolve()
     });

bar.then(() => {console.log('All done Lista Cierre Caja!');
this.ListItems = LISTA_CIERRE
});

        });

    },




/*findCaja(e){
console.log('---e---')
console.log(e)
  var headers = this.$store.getters.GetheadersAxios
      this.$axios.get(this.$hostname + this.$hName + `/cajas/findbycodigo/${e}`, { headers: headers })
        .then(res => (this.cajacierre = res.data))
        .catch(error => console.log(error));
},*/
    

    

    alerta(e, l) {
      if (l == "bien") {
        (this.aalert.estado = true),
          (this.aalert.color = "success"),
          (this.aalert.nombre = e);

        this.newwnuevo();
      }

      if (l == "error") {
        (this.aalert.estado = true),
          (this.aalert.color = "error"),
          (this.aalert.nombre = e);

        this.ejecucion = true;
        EventBus.$emit("loading", false);
      }
    },




    currencyFormatter(number) {
      //return this.formatNumber(params) + ".00";
      if (number == null) { number = 0 }
      return number
        .toFixed(2)
        .toString()
        .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");


      ///return this.formatNumber(params);

      //return params;
    },



    async filtro() {
      var headers = this.$store.getters.GetheadersAxios

      await this.$axios.get(this.$hostname + this.$hName + "/cierrecaja/findallBorradoDesc/", { headers: headers })
        .then(res => { this.list = res.data; });
    }


  },

  computed: {

    ActDescBtn() {
      if (this.CierresCajas.cierrescajasPK.secuencia > 0) {

        // var objjct = {
        //     save: true,
        //     eliminar: false,
        //     anular: false,
        //     nuevo: true,
        //     imprimir: false,
        //     vercuenta:false, 
        //     btnBarraDi:false,
        //     recalcular:false,
        //     imprimirPro:false
        //   };

        this.EstadoBtn = {
          BarraTablaDinamicaAprir: true,
          nuevo: false,
          guardar: true,
          eliminar: false,
          anular: false,
          imprimir: true,
          vercuenta: false,
          guardartemporal: false,
          restaurartemporal: false,
          importarDato: false,
          cargarProducto: false,
          ImprimirproductoInv: false,
          imprimircuadre: true,
          imprimircierre: true
        }


        if (this.totalValor > 0) {
          this.disableform = true
          this.EstadoBtn.guardar = false

        } else {
          this.disableform = false
          this.EstadoBtn.guardar = true
        }

      }

      if (this.CierresCajas.cierrescajasPK.secuencia <= 0) {

        // var objjct = {
        //       save: false,
        //       eliminar: false,
        //       anular: false,
        //       nuevo: true,
        //       imprimir: true,
        //       vercuenta:false, 
        //       btnBarraDi:false,
        //       recalcular:false,
        //       imprimirPro:false
        //     };


        this.EstadoBtn = {
          BarraTablaDinamicaAprir: true,
          nuevo: false,
          guardar: false,
          eliminar: false,
          anular: false,
          imprimir: false,
          vercuenta: false,
          guardartemporal: false,
          restaurartemporal: false,
          importarDato: false,
          cargarProducto: false,
          ImprimirproductoInv: false,
          imprimircuadre: false,
          imprimircierre: false
        }


      }


      this.windowSize = JSON.parse(JSON.stringify(this.$store.getters.GetwindowSize))
      //EventBus.$emit("ActDesaBotones", objjct);
    }
  }

};
</script>

<style>
#formTecniInput {
  color: black;
}
</style>
