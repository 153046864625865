<template>
<v-col class="pa-0 ma-0 py-0 my-0" cols="12" md="12" sm="12">{{ GetEjecucion }}
<!-- v-toolbar accion  -->
<v-toolbar dark color="#dd4b39" dense>

<v-tooltip bottom>
  <template v-slot:activator="{ on }">

    <v-toolbar-title v-on="on"> <i style="font-size: 20px;" class="fa fa-info-circle"> </i> ver Factura
    </v-toolbar-title>

  </template>
  <i style="font-size: 20px;" dark dense class="fa fa-info-circle"> <span>ver Factura: </span> Ver Factura</i>

</v-tooltip>

<v-spacer></v-spacer>

<v-card-actions>

  <v-tooltip bottom>

    <template v-slot:activator="{ on }">
      <v-btn id="boton-mini" v-on="on" @click="cancelarBuscarfactura()" color="orange"
        small dark>
        <i style="font-size: 20px;" class="fa fa-reply-all"> </i>
        CANCELAR
      </v-btn>
    </template>
    <span>CANCELAR: Cancela y retornar atrás</span>
  </v-tooltip>

</v-card-actions>
</v-toolbar>
<!-- fn v-toolbar accion  -->

<v-col cols="12" md="12" sm="12">
          <v-card color="grey lighten-3">
            <br>
            <v-form ref="formReimprecion" @submit.prevent="" v-model="valid" lazy-validation>
              <v-row>

                <!--  reimprecion busqueda secuencia  @blur="getCierreCaja" v-on:input="resertPaginacion" -------------->
                <v-col cols="12" md="2" sm="2" class="py-0 my-0">
                  <v-tooltip top>
                    <template v-slot:activator="{ on }">

                      <v-text-field v-on="on" label="# Secuencia:" dense autocomplete="off" outlined type="number"
                        v-model.number="FacturaBusqueda.secuencia" style="font-size: 12px;">


                        <template v-slot:prepend>
                          <i style="font-size: 20px;" class="fa fa-hashtag"></i>
                        </template>
                      </v-text-field>


                    </template>
                    <i style="font-size: 20px;" dark dense class="fa fa-info-circle">
                      <span> Aquí se registra el número de secuencia que deseamos búscar.</span>
                    </i>
                  </v-tooltip>
                </v-col>

                <!--  reimprecion busqueda Fecha Inicial @input="getCierreCaja"  v-on:input="resertPaginacion" -------------->
                <v-col cols="12" md="3" sm="3" class="py-0 my-0">
                  <v-tooltip top>
                    <template v-slot:activator="{ on }">

                      <v-text-field v-on="on" dense outlined type="date" label="Fecha Inicial"
                        v-model="FacturaBusqueda.fechaInicial" :rules="[$rules.required]" placeholder=" "
                        style="font-size: 12px;">
                        <template v-slot:prepend>
                          <i style="font-size: 18px;" class="fa fa-calendar"></i>
                        </template>
                      </v-text-field>

                    </template>
                    <i style="font-size: 20px;" dark dense class="fa fa-info-circle">
                      <span> Aquí se registra la fecha a partir de la cual queremos la búsqueda.</span>
                    </i>
                  </v-tooltip>
                </v-col>

                <!--  reimprecion busqueda Fecha Final @input="getCierreCaja"  v-on:input="resertPaginacion" -------------->
                <v-col cols="12" md="3" sm="3" class="py-0 my-0">
                  <v-tooltip top>
                    <template v-slot:activator="{ on }">

                      <v-text-field v-on="on" dense outlined type="date" label="Fecha Final"
                        v-model="FacturaBusqueda.fechaFinal" :rules="[$rules.required]" placeholder=" "
                        style="font-size: 12px;">
                        <template v-slot:prepend>
                          <i style="font-size: 18px;" class="fa fa-calendar"></i>
                        </template>
                      </v-text-field>

                    </template>
                    <i style="font-size: 20px;" dark dense class="fa fa-info-circle">
                      <span> Aquí se registra la fecha hasta donde queremos la búsqueda.</span>
                    </i>
                  </v-tooltip>
                </v-col>
                <!--  reimprecion cliente @change="getFacVenta"  -------------->
                <v-col cols="12" md="3" sm="3" class="py-0 my-0">
                  <v-tooltip top>
                    <template v-slot:activator="{ on }">

                      <v-combobox v-on="on" autocomplete="off" ref="RefCombClienteCosFac" dense outlined label="cliente:"
                        :items="RCliente" item-text="codnom" style="font-size: 13px;" v-model="FacturaBusqueda.cliente"
                        @focus="cargarClienteBusqueda" @keyup="CbFilcliente" @blur="SelctCbFacturaBuscar" no-filter>
                       
                        <template slot="item" slot-scope="item">
                  <span style="display:none;">{{ item.item.codnom = `${item.item.codigo} - ${item.item.nombre.trim()} ` }}</span>
                  <span class="fontSize13"><b>{{ item.item.codigo}}</b> - {{item.item.nombre.trim()}}</span>
                  </template>

                  <template v-slot:selection="{ attrs, item, parent, selected }">
                    <span class="fontSize13 colorNombre"><b>{{ item.codigo}}</b> - {{item.nombre}}</span>
                  </template>
                        <template v-slot:prepend>
                          <i style="font-size: 20px;" class="fa fa-user-circle"></i>
                        </template>
                      </v-combobox>
                    </template>
                    <i style="font-size: 20px;" dark dense class="fa fa-info-circle">
                      <span> Se selecciona el cliente que desea buscar.</span>
                    </i>
                  </v-tooltip>


                </v-col>
                <v-col cols="12" md="1" sm="1" class="py-0 my-0">
                  <v-btn @click.prevent="getFacturalistFactCliente" small color="teal"><i style="font-size: 20px;"
                      class="fa fa-search"></i></v-btn>
                </v-col>
              </v-row>
            </v-form>

            <v-col cols="12" md="12" sm="12" class=" pa-0 ma-0 py-0 my-0">
              <v-data-table class="RowSelectColor" item-key="indx" @click:row="VerFactDatostabla" :headers="[
                { text: 'Fecha', sortable: false, value: 'fecha', width: 120 },
                { text: '# Secuencia', sortable: false, value: 'Factsecuencia', width: 129 },
                { text: 'Cliente', sortable: false, value: 'cliente', width: 129 },
                { text: 'Valor', sortable: false, value: 'valor', width: 'auto', width: 105 },
              ]" dense :items="VerlistFactVent" 
              :footer-props="arrayPropiedases"
                @pagination="FacturaCargarPaginacion" :items-per-page="facturaPaginator.itemsPerPage"
                :server-items-length="TotalRegistros">
                <template v-slot:item.fecha="props">
                  <span style="font-size:13px; display:none;">{{ props.item.indx =
                  VerlistFactVent.indexOf(props.item)
                  }}</span>
                  <span style="font-size:13px;">{{ FormatoFecha(props.item.fecha) }}</span>
                </template>

                <template v-slot:item.Factsecuencia="props">
                  <span style="font-size:13px;">{{ props.item.documentoFactura.nombrecorto }} - {{
                    props.item.facturasventaPK.secuencia }} </span>
                </template>

                <template v-slot:item.cliente="props">
                  <span v-if="props.item.cliente != null" style="font-size:13px;">{{ props.item.cliente.nombre }}
                  </span>
                </template>

                <template v-slot:item.valor="props">
                  <span style="font-size:13px;">{{ currencyFormatter(props.item.valor) }}</span>
                </template>
              </v-data-table>
            </v-col>

          </v-card>
</v-col>


</v-col>
</template>
<script>
import { EventBus } from "../../event-bus";
import { currencyFormatter, FormatoFecha, HoraFormatter,RedondeoValorCantRed,currencyFormatterCant,fechaNueva,getParamFecha } from "@/js/Funciones.js";
import VueAutonumeric from "../../components/AutoNumericNuevo";
export default {
  components: {
    VueAutonumeric
  },
  created() { 
            console.log('created--PVCONFIGU')
  },
  mounted() {
 

  },
  beforeDestroy() {

  },

  data: () => ({ 
    arrayPropiedases: {
      'items-per-page-options': [10, 15, 20, 50],
      'items-per-page-text': 'Cantidad de Registros Por Paginas',
      showFirstLastPage: false
    },
    windowSize: { width: "auto", height: "auto" },
    valid: true,
    dialogBucarFacturaEdit:false,
    RCliente:[],
    VerlistFactVent:[],
    FacturaBusqueda: {
      secuencia: 0,
      fechaInicial: fechaNueva(),
      fechaFinal: fechaNueva(),
      cliente: null,
    },

    TotalRegistros: 1,
    resertPagi: true,
    facturaPaginator: {
      itemsPerPage: 15,
      pageStart: 0,
      page: 1,
      pageStop: 15,
      pageCount: 10,
      itemsLength: 5,
    },
    

  }),

  props: {
    cajaSelect: {
      required: false,
      type: Object,
      default: null
    },
  },

  methods: { 
    currencyFormatter, FormatoFecha, HoraFormatter,RedondeoValorCantRed,currencyFormatterCant,fechaNueva ,getParamFecha,
    // Creando un nuevo objeto y asignándolo a la variable CotizacionBusqueda.
NewBucarFactura() {
      this.FacturaBusqueda = {
        secuencia: 0,
        fechaInicial: fechaNueva(),
        fechaFinal: fechaNueva(),
        cliente: null,
        sucursal:null
      };
    },

    cancelarBuscarfactura() {
      this.dialogBucarFacturaEdit = false;
      this.NewBucarFactura();
      var _this=this;
    setTimeout(function(){ 
      _this.$emit("DataRetornCancelarBuscarFactura", this.dialogBucarFacturaEdit)
     }, 300);
    },


    // Establecer el valor predeterminado del paginador en 15.
    FacturaCargarPaginacion(e) {
      this.FacturaPaginator = e;
      if (this.FacturaPaginator.itemsPerPage == 1) {
        this.FacturaPaginator.itemsPerPage = 15;
      }
      this.getFacturalistFactCliente();
    },


     // Hacer una llamada API al servidor y devolver los datos de la cotizacion.
    getFacturalistFactCliente() { 
      /*console.log('Viendo Informacion klk ')
      console.log(this.$refs.RefCombClienteCosFac.internalSearch)*/
      //  this.RegistroActividades.descripcion= JSON.parse(JSON.stringify(this.$refs.RefCombAct.internalSearch)) 
      
      var _this = this;
      setTimeout(function () {
        _this.VerlistFactVent = [];
        var sec,
          fecini,
          fecfin,
          cliente = null,
          sucursal=_this.cajaSelect.sucursal.codigo;
        if (_this.FacturaBusqueda.secuencia != null) {
          sec = _this.FacturaBusqueda.secuencia;
        }
        if (_this.FacturaBusqueda.fechaInicial != null) {
          fecini = _this.FacturaBusqueda.fechaInicial;
        }
        if (_this.FacturaBusqueda.fechaFinal != null) {
          fecfin = _this.FacturaBusqueda.fechaFinal;
        }
        if (_this.FacturaBusqueda.cliente != null) {
          console.log('_this.FacturaBusqueda.cliente')
          if(_this.$refs.RefCombClienteCosFac.internalSearch.length>0){
            cliente = _this.$refs.RefCombClienteCosFac.internalSearch;
          }else{
            cliente=null
          }
        }

        _this.$axios
          .get(
            _this.$hostname +
            _this.$hName +
            "/reimpresion/facturasSucursal/" +
            sec +
            "/" +
            fecini +
            "/" +
            fecfin +
            "/" +
            cliente +
            "/" +
            _this.facturaPaginator.itemsPerPage +
            "/" +
            _this.facturaPaginator.pageStart+
            "/" +
            sucursal,
            { headers: _this.$store.getters.GetheadersAxios }
          )
          .then((res) => {
            //*this.TotalRegistros = res.data.cantidad,
            _this.VerlistFactVent = res.data.listas;
            _this.TotalRegistros = res.data.cantidad
          });

      }, 500);

    },


 // Llamar a una API y obtener los datos del cliente.
    cargarClienteBusqueda() {
  var filtroTipoCliente=3
      this.$axios
        .get(this.$hostname + this.$hName + "/clientes/findtodo/a/" + filtroTipoCliente + "/25/0", {
          headers: this.$store.getters.GetheadersAxios,
        })
        .then((res) => (this.RCliente = res.data));
    },

    // Hacer una llamada API al servidor y devolver los datos al cliente.
    CbFilcliente(e) {
      var filtroTipoCliente=3
      if (e.target.value.length >= 0) {
        this.$axios
          .get(
            this.$hostname +
            this.$hName +
            "/clientes/findtodo/" +
            (e.target.value === null || e.target.value.length==0 ? 'A' : e.target.value)  +
            "/" + filtroTipoCliente + "/25/0",
            { headers: this.$store.getters.GetheadersAxios }
          )
          .then((res) => (this.RCliente = res.data));
      }
    },

    SelctCbFacturaBuscar() {

      return
      var _this = this;
      setTimeout(function () {
        if (_this.FacturaBusqueda.cliente == null) {
          _this.FacturaBusqueda.cliente = "";
        }

        if (!_this.FacturaBusqueda.cliente.hasOwnProperty('codigo')) {
          _this.FacturaBusqueda.cliente = "";
          _this.FacturaBusqueda.cliente = null;
          return;
        }
      }, 300);

    },

    VerFactDatostabla(ee){
      EventBus.$emit("loading", true);
    /*console.log('datos-VerFactDatostabla')
    console.log(e)*/
    //this.cancelarBuscarfactura()
    //this.$emit("DataRetornBuscarFactura", e) 
    var e=JSON.parse(JSON.stringify(ee))
    e.fecha=ee.nuevaFecha
 var OBJ={
  headerFactura:{
      // fecha:getParamFecha(e.fecha).split("T")[0],
      // fecha:fechaNueva(),
      fecha: e.nuevaFecha,
      listaPrecio:null,
      tipocomprobante:e.tipocomprobante,
      documento:e.documentoFactura,
      cliente:e.cliente,
      sucursal:null,
      moneda:e.moneda,
      vendedor:e.vendedor,
      diascredito:e.diascredito,
      centroCosto:null,
      ValorPorc:false,
      descuento:0,
      activarListasPrecios:false,
      ncf: e.ncf!=null? e.ncf.trim():"",
    },
  factura:JSON.parse(JSON.stringify(e)),
  facturaDet:JSON.parse(JSON.stringify(e.facturasventaDetalleList))
 } 
 OBJ.factura.facturasventaDetalleList=[]
//  delete OBJ.factura["facturasventaDetalleList"]
    if(e.facturasventaDetalleList.length > 0){
          var listDetalle = JSON.parse(JSON.stringify(e.facturasventaDetalleList))
       listDetalle.sort(function (a, b) {
            if (a.facturasventaDetallePK.id > b.facturasventaDetallePK.id) { return 1; }
            if (a.facturasventaDetallePK.id < b.facturasventaDetallePK.id) {return -1;}
            // a must be equal to b
            return 0;
          });

          (async () => {  
            const restrList =  await this.ContFactDatostabla(listDetalle,OBJ);

          })()
        }
    },
    async ContFactDatostabla(listDetalle,data){  
  return new Promise((rresolve, reject) => { 
      var ListDetalleTemp=[]
      var OBJ=JSON.parse(JSON.stringify(data))
      var TASA=JSON.parse(JSON.stringify(data.factura.tasa))
      var bar = new Promise((resolve, reject) => {


        Promise.all(listDetalle.map(async (element) => { 
          var PRODUCTO=JSON.parse(JSON.stringify(element))
                PRODUCTO.unidadesProductosPK={
                  producto:element.productos.codigo,
                  unidad:element.unidades.codigo
                }
                    PRODUCTO.DescValor = element.valordescuento
                    PRODUCTO.descuentoKeys=element.descuento
                    PRODUCTO.ValorPorc=false
                    PRODUCTO.precio=element.precio
                    PRODUCTO.precioDig=element.precio
                    PRODUCTO.precioOrig=element.precio
                    PRODUCTO.omitirRegla=false
                    PRODUCTO.paramEspecial=false
                    PRODUCTO.paramOfert=false
                    PRODUCTO.productoValidadoEspOfer=false
                    PRODUCTO.valorimpuesto= element.valorimpuesto!=null?element.valorimpuesto:0;
                ListDetalleTemp.push(PRODUCTO)
 }));

        /*listDetalle.forEach(async (element) => {
           (async () => { 
            // if(resUniPro!=null){} 
            // var Obj =JSON.parse(JSON.stringify(element))
            //   Obj.descripcion=`${element.productos.descripcion.trim()} ${element.unidades.descripcion.trim()}`
            // 
            })()
            }); */


            resolve()
      });
      bar.then(() => {
        OBJ.listDetalle=ListDetalleTemp
        OBJ.listDetalle.sort(function (a, b) {
            if (a.facturasventaDetallePK.id > b.facturasventaDetallePK.id) { return 1; }
            if (a.facturasventaDetallePK.id < b.facturasventaDetallePK.id) {return -1;}
            // a must be equal to b
            return 0;
          });
          
         OBJ.facturaDet.sort(function (a, b) {
            if (a.facturasventaDetallePK.id > b.facturasventaDetallePK.id) { return 1; }
            if (a.facturasventaDetallePK.id < b.facturasventaDetallePK.id) {return -1;}
            // a must be equal to b
            return 0;
          });
        this.cancelarBuscarfactura()
    var _this=this;
    setTimeout(function(){ 
      _this.$emit("DataRetornBuscarFactura", OBJ)  
       rresolve(ListDetalleTemp)  
      }, 300);
      
        
    });

  });

    },
    

    async findUnidaProducto(codpro,coduni){
return  new Promise((resolve, reject) => {
var VARLINK=`${codpro}/${coduni}`
EventBus.$emit("loading", true);
  this.$axios.get(this.$hostname + this.$hName + '/unidades-productos/find/'+ VARLINK, { headers: this.$store.getters.GetheadersAxios })
  .then(res => { 
    resolve(res.data)
     EventBus.$emit("loading", false); 
  })
  .catch(error => {
      EventBus.$emit("loading", false);
      resolve(null)
    });  

  });

},


    
  },
  computed: { 
    GetEjecucion() {
      this.windowSize = JSON.parse(JSON.stringify(this.$store.getters.GetwindowSize))
     },

},
};
</script>
<style lang="scss">
</style>