<template>
  
<div v-if="dialogCrearModificarCliente==true" v-dirkeydown="KeyESC">
  
<v-col class="pa-0 ma-0 py-0 my-0" cols="12" md="12" sm="12">{{ GetEjecucion }}
  <!-- v-toolbar accion  -->
<v-toolbar dark color="#dd4b39" dense>

<v-tooltip bottom>
  <template v-slot:activator="{ on }">

    <v-toolbar-title v-on="on"> <i style="font-size: 20px;" class="fa fa-info-circle"> </i> CREAR / EDITAR CLIENTE
    </v-toolbar-title>

  </template>
  <i style="font-size: 20px;" dark dense class="fa fa-info-circle"> <span>CREAR / EDITAR CLIENTE: </span> CREAR / EDITAR CLIENTE</i>

</v-tooltip>

<v-spacer></v-spacer>

<v-card-actions>

  <v-tooltip bottom>
    <template v-slot:activator="{ on }">
      <v-btn id="boton-mini" v-on="on" @click="CancelarCerrarModal()" color="orange"
        small dark>
        <i style="font-size: 20px;" class="fa fa-reply-all"> </i>
        CANCELAR
      </v-btn>
    </template>
    <span>CANCELAR: Cancela y retornar atrás</span>
  </v-tooltip>

  <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                  <v-btn id="boton-mini" v-on="on" @click="NewNuevo()" color="grey" small dark>

                    <v-avatar tile size="28" color="transparent">
                      <img src="@/assets/icons_pv/extras/limpiarPantalla.png" alt="Limpiar">
                    </v-avatar>


                    <i style="font-size: 20px;"> </i>
                    NUEVO
                  </v-btn>
                </template>
                <span>NUEVO: Limpia los campo para un nuevo registro</span>
              </v-tooltip>

              <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                  <v-btn id="boton-mini" v-on="on" @click="SalvarCrearEditarCliente" color="light-blue darken-4" small
                    dark>
                    <i style="font-size: 20px;" class="fa fa-plus"></i><!-- fab x-small dark  -->
                    SALVAR
                  </v-btn>
                </template>
                <span>SALVAR: Crea o edita cliente y retornar atrás </span>
              </v-tooltip>

</v-card-actions>
</v-toolbar>

<v-col cols="12" md="12" sm="12">
<!-- Buscar Cliente -->
<v-col cols="12" md="12" sm="12">
                <v-form ref="formBuscarCliente" v-model="valid" lazy-validation>
                  <v-tooltip top>
                    <template v-slot:activator="{ on }">
                      <v-combobox ref="RefTextFieldBuscaCliente" v-on="on" autocomplete="off" background-color="white"
                        color="grey darken-4" dense outlined label="Buscar cliente:" :items="RCliente" item-text="codnom"
                        style="font-size: 13px;" v-model="busquedaCliente" @blur="SelctCbClienteBuscar"
                        @change="getChangeClienteBusqueda(busquedaCliente)" @keyup.enter="SeleccionarClienteFactura"
                        @focus="cargarClienteBusqueda" @keyup="CbFilcliente" hide-details :rules="[$rules.required]" no-filter
                        v-on:change="changeCbTipoComprobante(clienteSeleccionado.tipocomprobante,'RefTextFieldcedrnc')">
                        <template v-slot:prepend-inner>
                          <i style="font-size: 25px;" class="fa fa-search"></i>
                        </template>
                        <!-- margin-top="50px"style="font-size: 14px;" -->
                        <template slot="item" slot-scope="item">
                          <span style="display:none;">{{ item.item.codnom = `${item.item.codigo} - ${item.item.nombre.trim()} ` }}</span>
                          <!--<v-col cols="12" md="12" sm="12"> <v-row no-gutters></v-row> </v-col>
                            style="border: 1px dashed  #616161;"
                              -->
                          <v-col style="border: 1px solid #000000; font-size: 13px; " class="pa-0 ma-0 py-0 my-0"
                            cols="12" md="12" sm="12">
                            <p class="pa-0 ma-0 py-0 my-0"><b>NOMBRE:
                                {{ item.item.nombre }}</b></p>

                            <span class="pa-0 ma-0 py-0 my-0">
                              <span><b>TEL.: </b>
                                {{ selectTelefonoCliente(item.item) }}</span>
                              <span><b>CED/RNC: </b> {{ item.item.cedrnc }}</span>
                            </span>
                            <p v-if="item.item.hasOwnProperty('pasaporte') && item.item.pasaporte != null && item.item.pasaporte.length>0" class="pa-0 ma-0 py-0 my-0"><b>PASAPORTE: </b>{{ item.item.pasaporte }} </p>
                            <p class="pa-0 ma-0 py-0 my-0"><b>DIRECCIÓN: </b>
                              {{ item.item.direccion }} </p>
                            <span v-if="item.item.hasOwnProperty('ars') && item.item.ars != null"><b> ARS
                                :{{ item.item.ars.nombre }}</b></span>
                            <span v-if="item.item.diascredito > 0"><b> TIPO:
                                Cliente
                                Credito</b></span>
                            <span v-if="item.item.diascredito == 0"><b>TIPO:
                                Cliente
                                Contado</b></span>
                          </v-col>


                        </template>

<!-- 
                        <template v-slot:append-outer>

                          <v-tooltip top>
                            <template v-slot:activator="{ on }">

                              <v-btn @click.prevent="SeleccionarClienteFactura" v-on="on" color="indigo lighten-2"
                                outlined small dark>
                                <v-avatar tile size="28" color="transparent">
                                  <img src="@/assets/icons_pv/extras/seleccionar48.png" alt="Asignar cliente">
                                </v-avatar>
                              </v-btn>

                            </template>
                            <span>Asignar cliente buscado a la factura</span>
                          </v-tooltip>

                        </template> -->



                      </v-combobox>
                    </template>
                    <i style="font-size: 20px;" dark dense class="fa fa-info-circle">
                      <span> Se selecciona el cliente que desea editar.</span>
                    </i>
                  </v-tooltip>

                </v-form>
 </v-col>
<!-- fin Buscar Cliente -->

<!-- form Crear Editar Cliente  -->
<v-form v-model="valid" ref="formCrearEditarCliente" lazy-validation>
     <v-row>
                  <!-- -----------------------------  Codigo -->
                    <v-col cols="12" md="6" sm="6" class="py-0 my-0">
                    <v-text-field id="formdisabledInput" label="Codigo:" dense outlined
                      v-model="clienteSeleccionado.codigo" :disabled="true" hide-details>
                      <template v-slot:prepend>
                        <i style="font-size: 20px;" class="fa fa-list-ol"></i>
                      </template>
                    </v-text-field>
                  </v-col>

                   <!-- -----------------------------  comentario -->
                   <v-col cols="12" md="6" sm="6" class="py-0 my-0">
                    <v-textarea autocomplete="off" label="comentario:" rows="1" dense outlined
                          v-model="clienteSeleccionado.comentario">
                          <template v-slot:prepend>
                            <i style="font-size: 20px" class="fa fa-commenting"></i>
                          </template>
                        </v-textarea>
                  </v-col>

                  <!-- -----------------------------  Nombre -->
                  <v-col cols="12" md="6" sm="6">
                    <v-text-field label="* Nombre:" autocomplete="off" dense outlined :rules="[$rules.required]"
                      v-model="clienteSeleccionado.nombre" :disabled="validarDisabledClienteCredito(busquedaCliente)"
                      id="formdisabledInput">
                      <template v-slot:prepend>
                        <i style="font-size: 20px;" class="fa fa-font"></i>
                      </template>
                    </v-text-field>


                  </v-col>

                  <!-- ----------------------------- cedula/rnc -->
                  <v-col cols="12" md="6" sm="6">

                    <v-text-field v-if="cedularequired==false" autocomplete="off" v-mask="MakCelRnc" label="Cedula/RNC:" dense outlined maxlength="13"
                      :rules="[$rules.RulesCelRncValidar]" v-model="clienteSeleccionado.cedrnc" @blur="MakaraCel"
                      @focus="MakCelRnc = '#############'" v-on:blur="validandoCedRnc(clienteSeleccionado.cedrnc)">
                      <template v-slot:prepend>
                        <i style="font-size: 20px;" class="fa fa-id-card-o"></i>
                      </template>
                    </v-text-field>

                    <v-text-field ref="RefTextFieldcedrnc"  v-if="cedularequired==true" autocomplete="off" v-mask="MakCelRnc" label="Cedula/RNC:" dense outlined maxlength="13"
                      :rules="[$rules.required,$rules.RulesCelRncValidar]" v-model="clienteSeleccionado.cedrnc" @blur="MakaraCel" v-on:blur="validandoCedRnc(clienteSeleccionado.cedrnc)"
                      @focus="MakCelRnc = '#############'">
                      <template v-slot:prepend>
                        <i style="font-size: 20px;" class="fa fa-id-card-o"></i>
                      </template>
                    </v-text-field>

                  </v-col>
<!-- -----------------------------  Pasaporte -->
    <v-col cols="12" md="6" sm="6" class="py-0 my-0">
    <v-text-field label="Pasaporte:" autocomplete="off" dense outlined
                          v-model="clienteSeleccionado.pasaporte"
                          maxlength="19">
                          <template v-slot:prepend>
                            <i style="font-size: 20px" class="fa fa-address-card"></i>
                          </template>
                        </v-text-field>

        </v-col>


                  <!-- -----------------------------  Direccion -->
                  <v-col cols="12" md="6" sm="6" class="py-0 my-0">
                    <v-textarea autocomplete="off" label="* Dirección:" rows="1" dense outlined
                      v-model="clienteSeleccionado.direccion" :rules="[$rules.required]"
                      :disabled="validarDisabledClienteCredito(busquedaCliente)"
                      id="formdisabledInput">
                      <template v-slot:prepend>
                        <i style="font-size: 20px;" class="fa fa-map-marker"></i>
                      </template>
                    </v-textarea>
                  </v-col>



                  <!-- ----------------------------- Télefono -->
                  <v-col cols="12" md="6" sm="6" class="py-0 my-0 ">
                    <v-text-field label="Télefono:" dense outlined autocomplete="off" maxlength="13"
                      v-mask="'(###)###-####'" v-model="clienteSeleccionado.telefono"
                      :rules="[$rules.required,$rules.RulesTel]" 
                      >
                      <template v-slot:prepend>
                        <i style="font-size: 20px;" class="fa fa-phone"></i>
                      </template>
                    </v-text-field>

                  </v-col>

                   <!-- -----------------------------  Direccion 2 -->
                   <!-- :disabled="validarDisabledClienteCredito(busquedaCliente)"  -->
                  <v-col cols="12" md="6" sm="6" class="py-0 my-0">
                    <v-textarea autocomplete="off" label=" Dirección2:" rows="1" dense outlined
                      v-model="clienteSeleccionado.direccion2"
                      id="formdisabledInput"
                       >
                      <template v-slot:prepend>
                        <i style="font-size: 20px;" class="fa fa-map-marker"></i>
                      </template>
                    </v-textarea>
                  </v-col>

                  <!-- -----------------------------  email -->
                  <v-col cols="12" md="6" sm="6" class="py-0 my-0">

                    <v-text-field label="E-mail:" dense outlined v-model="clienteSeleccionado.email">
                      <template v-slot:prepend>
                        <i style="font-size: 20px;" class="fa fa-envelope-o"></i>
                      </template>
                    </v-text-field>

                  </v-col>

                  <!-- ----------------------------- fecha de nacimiento   -->
                  <v-col cols="12" md="6" sm="6" class="py-0 my-0">

                    <v-text-field dense outlined type="date" label="Fecha de Nacimiento:"
                      v-model="clienteSeleccionado.fechanacimiento">

                      <template v-slot:prepend>
                        <i style="font-size: 20px;" class="fa fa-calendar"></i>
                      </template>

                    </v-text-field>

                  </v-col>




                  <!-- ----------------------------- Ciudad-->
                  <v-col cols="12" md="6" sm="6" class="py-0 my-0">
                    <v-text-field label="* Ciudad:" dense outlined autocomplete="off" v-model="clienteSeleccionado.ciudad"
                      :rules="[$rules.required]">
                      <template v-slot:prepend>
                        <i style="font-size: 20px;" class="fa fa-map"></i>
                      </template>
                    </v-text-field>
                  </v-col>


                  <!-- -----------------------------sector -->
                  <v-col cols="12" md="6" sm="6" class="py-0 my-0">

                    <v-text-field label="sector:" dense outlined autocomplete="off" v-model="clienteSeleccionado.sector"
                      :rules="[$rules.required]">
                      <template v-slot:prepend>
                        <i style="font-size: 20px;" class="fa fa-map-marker"></i>
                      </template>
                    </v-text-field>

                  </v-col>

                   


                  <!-- ----------------------------- Dia de credito -->
                  <v-col v-if="false" cols="12" md="6" sm="6" class="py-0 my-0">

                    <v-text-field label="Días de Crédito" dense outlined v-model="clienteSeleccionado.diascredito"
                      @keyup="KeyUpCantidad">
                      <template v-slot:prepend>
                        <i style="font-size: 20px;" class="fa fa-credit-card"></i>
                      </template>
                    </v-text-field>
                  </v-col>



                  <!-- ----------------------------- tipocomprobante -->
                  <v-col cols="12" md="6" sm="6" class="py-0 my-0">

                    <v-combobox autocomplete="off" dense outlined label="* Tipos de Comprobante:"
                      :items="filterTipoComprobantePvItbis(RtipoComprobante)" :rules="[$rules.required]" item-text="descripcion"
                      v-model="clienteSeleccionado.tipocomprobante" @blur="SelctCbTipoComprobante"
                      @focus="cargarTipoComprobante"
                      @change="changeCbTipoComprobante(clienteSeleccionado.tipocomprobante,'RefTextFieldcedrnc')"
                      :disabled="validarDisabledClienteCredito(busquedaCliente)" id="formdisabledInput">
                      <template v-slot:prepend>
                        <i style="font-size: 20px;" class="fa fa-tag"></i>
                      </template>
                    </v-combobox>

                  </v-col>

<!-- -----------------------------sector -->
<v-col cols="12" md="6" sm="6" class="py-0 my-0">

<v-text-field label="Código barra preferencial:" id="formdisabledInput" dense outlined v-model="clienteSeleccionado.codigoBarraPreferencial">
 <template v-slot:prepend>
   <i style="font-size: 20px" class="fa fa-barcode"></i>
 </template>
</v-text-field>

</v-col>


                  <!-- -----------------------------sexo -->
                  <v-col cols="12" md="6" sm="6" class="py-0 my-0">

                    <!-- <v-text-field label="Sexo:" dense outlined
                      autocomplete="off"
                        v-model="clienteSeleccionado.sexo"
                        :rules="[$rules.required]"
                        >
                      <template v-slot:prepend>
                              <i style="font-size: 20px;" class="fa fa-venus-mars"></i> 
                      </template>
                        </v-text-field> -->

                  </v-col>
                  <v-col cols="12" md="12" sm="12" class="py-0 my-0"
                    v-if="$store.getters.GetdatosInfUsu.usuario.micromarket == false">
                    <hr style="border: 1px solid #000000;">
                    <br>
                    <v-row>
                      <!-- ----------------------------- Número de Afiliado  -->
                      <v-col cols="12" md="6" sm="6" class="py-0 my-0">
                        <v-text-field v-if="clienteSeleccionado.ars==null" label="Número de Afiliado:" autocomplete="off" dense outlined
                          v-model="clienteSeleccionado.carnet">
                          <template v-slot:prepend>
                            <i style="font-size: 20px;" class="fa fa-slack"></i>
                          </template>
                        </v-text-field>

                        <v-text-field v-if="clienteSeleccionado.ars!=null" label="Número de Afiliado:" autocomplete="off" dense outlined
                          v-model="clienteSeleccionado.carnet" :rules="[$rules.required]">
                          <template v-slot:prepend>
                            <i style="font-size: 20px;" class="fa fa-slack"></i>
                          </template>
                        </v-text-field>

                      </v-col>
                      <!-- ----------------------------- ARS -->
                      <v-col cols="12" md="6" sm="6" class="py-0 my-0">
                        <v-combobox autocomplete="off" dense outlined label="ARS:" :items="RArs" item-text="nombre"
                          v-model="clienteSeleccionado.ars" @blur="SelctCbArsClient" @focus="cargarArs">
                          <template v-slot:prepend>
                            <i style="font-size: 20px;" class="fa fa-shield"></i>
                          </template>
                        </v-combobox>
                      </v-col>
                      <!-- -----------------------------Donde Trabaja  -->
                      <v-col cols="12" md="6" sm="6" class="py-0 my-0">
                        <v-text-field label="Donde Trabaja:" autocomplete="off" dense outlined
                          v-model="clienteSeleccionado.dondetrabaja">
                          <template v-slot:prepend>
                            <i style="font-size: 20px;" class="fa fa-building-o"></i>
                          </template>
                        </v-text-field>


                      </v-col>
                      <!-- ----------------------------- Número de Contrato -->
                      <v-col cols="12" md="6" sm="6" class="py-0 my-0">
                        <v-text-field label="Número de Contrato:" autocomplete="off" dense outlined
                          v-model="clienteSeleccionado.contrato">
                          <template v-slot:prepend>
                            <i style="font-size: 20px;" class="fa fa-slack"></i>
                          </template>
                        </v-text-field>
                      </v-col>

                      <!-- ----------------------------- Número de NSS -->
                      <v-col cols="12" md="6" sm="6" class="py-0 my-0">
                        <v-text-field label="Número de NSS:" autocomplete="off" dense outlined
                          v-model="clienteSeleccionado.nss">
                          <template v-slot:prepend>
                            <i style="font-size: 20px;" class="fa fa-slack"></i>
                          </template>
                        </v-text-field>

                      </v-col>

                      <!-- ----------------------------- Plan  lan -->
                      <v-col cols="12" md="6" sm="6" class="py-0 my-0">

                        <v-text-field label="Plan  lan:" autocomplete="off" dense outlined
                          v-model="clienteSeleccionado.planlan">
                          <template v-slot:prepend>
                            <i style="font-size: 20px;" class="fa fa-sign-language"></i>
                          </template>
                        </v-text-field>

                      </v-col>


                    </v-row>
                  </v-col>
                </v-row>
              </v-form>
<!-- form Crear Editar Cliente  -->
    </v-col>

</v-col>

<v-snackbar v-model="aalert.estado" :color="aalert.color" style="margin-top: 20%;" :vertical="true"
      :timeout="aalert.timeout" :right="aalert.right" :top="aalert.top">
      {{ aalert.nombre }}
      <v-btn dark text @click="aalert.estado = false">Close</v-btn>
</v-snackbar>

</v-col>
<!---------------------  Panel Menu Opcion Botones ------------------------------->
<div style="max-height:300px;" class="overflow-x-auto" v-show="false">
              <!-- columna  ---------------------->
              <PvMenuOpcionBotones ref="RefPvMenuOpcionBotones" @DataRetornAccion="DataRetornMenuOpcBotonesPermisosPv"
                @DataRetornAccionPadre="DataRetornPadrePermisoPv"/>
            </div>
</div>
</div> 
</template>
<script>
import PvMenuOpcionBotones from "@/PuntodeVenta/UtilidadComponents/PvMenuOpcionBotones.vue";
import { EventBus } from "../../event-bus";
import { currencyFormatter, FormatoFecha, HoraFormatter,RedondeoValorCantRed,currencyFormatterCant,fechaNueva,validaCedRnc,getParamFecha } from "@/js/Funciones.js";
import VueAutonumeric from "../../components/AutoNumericNuevo";
export default {
  directives: {
    dirkeydown: {
      // Definición de directiva
      bind(el, binding) {
        // __HandleScrollHandler__
        el.__HandledKey__ = event => binding.value(event)
        document.body.addEventListener('keydown', el.__HandledKey__)
      },
      unbind(el) {
        document.body.removeEventListener('keydown', el.__HandledKey__)
      }

    }
  },

  components: {
    VueAutonumeric,
    PvMenuOpcionBotones
  },
  created() { 

  },
  mounted() {
 

  },
  beforeDestroy() {

  },

  data: () => ({ 

    arrayPropiedases: {
      'items-per-page-options': [10, 15, 20, 50],
      'items-per-page-text': 'Cantidad de Registros Por Paginas',
      showFirstLastPage: false
    },
    EjecutarPermisoPv:false,
    ejecucion:true,
    windowSize: { width: "auto", height: "auto" },
    valid: true,
    busquedaCliente: null,
    cedularequired:false,
    MakCelRnc: "#############",
    RtipoComprobante:[],
    RArs:[],
    RCliente:[],
    clienteSeleccionado: {
      codigo: 0,
      nombre: null,
      cedrnc: "",
      ciudad: null,
      direccion: null,
      telefono: "",
      sector: "",
      tipocomprobante: null,
      email: "",
      sexo: "",
      dondetrabaja: "",
      diascredito: 0,
      fechanacimiento: null,
      carnet: "",
      ars: null,
      contrato: "",
      nss: "",
      planlan: 0,
      comentario:'',
      pasaporte:'',
      zonaRuta:null,
      ordenVisita:0,
      diaVisita:null,
      balancePuntos:0,
      codigoBarraPreferencial:null
    },
    dialogCrearModificarCliente: false,
  
    aalert: {
      estado: false,
      color: null,
      timeout: 3000,
      top: false,
      right: false
 },

  }),

  methods: { 
    currencyFormatter, FormatoFecha, HoraFormatter,RedondeoValorCantRed,currencyFormatterCant,fechaNueva,validaCedRnc,getParamFecha,
  
// Hacer una llamada API al servidor y devolver los datos de la cotizacion.
SeachFilCodigoBarra(e) {

  var MODALIDAD_CLI_PRE=this.$store.getters.GetConfiguracionGeneral.configPv.modalidadClientePreferencial
      if(MODALIDAD_CLI_PRE==true){
        var BANDERA_CODBAR_CLI_PRE=this.$store.getters.GetConfiguracionGeneral.configPv.banderaCodigoBarraPreferencial
        //key: "Enter"keyCode: 13
        if(e.keyCode == 13){
          if(e.target.value.substr(0,2)===BANDERA_CODBAR_CLI_PRE){
            this.SeachTextFilBarraPreferencial(e.target.value)
            return
          }
        }
      }

},

// Creando un nuevo objeto y asignándolo a la variable CotizacionBusqueda.
NewNuevo() {

  this.cedularequired=false,
  this.busquedaCliente= null
  this.MakCelRnc= "#############",
  this.clienteSeleccionado= {
      codigo: 0,
      nombre: null,
      cedrnc: "",
      ciudad: null,
      direccion: null,
      telefono: "",
      sector: "",
      tipocomprobante: null,
      email: "",
      sexo: "",
      dondetrabaja: "",
      diascredito: 0,
      fechanacimiento: null,
      carnet: "",
      ars: null,
      contrato: "",
      nss: "",
      planlan: 0,
      comentario:'',
      pasaporte:'',
      zonaRuta:null,
      ordenVisita:0,
      diaVisita:null,
      balancePuntos:0,
      codigoBarraPreferencial:null

    }
    this.ejecucion=true
    this.EjecutarPermisoPv=false

    },
    

    CancelarCerrarModal() {
      this.dialogCrearModificarCliente = false;
      this.NewNuevo();
      var _this=this;
    setTimeout(function(){ 
      _this.$emit("DataRetornCancelarCrearModificarCliente", this.dialogCrearModificarCliente)
     }, 300);
    },


focusRefTextFieldBuscar(){
 this.dialogCrearModificarCliente=true
  var _this = this;
        setTimeout(function () {
          _this.$refs.RefTextFieldBuscaCliente.focus();
        }, 500);

},


KeyESC(e){
      if (e.keyCode ==27 ) {
           this.CancelarCerrarModal()
        }
  
  
      },


// Modifica un objecto cliente para enviarlo a guardar.
SalvarCrearEditarCliente() {

if (this.$refs.formCrearEditarCliente.validate()) {
  if(this.cedularequired==true){
  if(this.clienteSeleccionado.cedrnc==null || this.clienteSeleccionado.cedrnc!=null && this.clienteSeleccionado.cedrnc.trim().length==0){
  this.$refs.formCrearEditarCliente.validate();
  this.alerta(`el campo "cedula/RNC" es requeridos`, "error");
  return
  }
}

if(this.clienteSeleccionado.cedrnc!=null && this.clienteSeleccionado.cedrnc.length>0){ 
    var CEDULA = this.clienteSeleccionado.cedrnc.replace("-", "");
    CEDULA = CEDULA.replace(/-/g,'')
  if(this.validaCedRnc(CEDULA)==false){
var NOM= CEDULA.length>10?"CEDULA":"RNC"
this.alerta(`${NOM} Invalida `, "error");
  return;
  }
  }

  var MODALIDAD_CLI_PRE=this.$store.getters.GetConfiguracionGeneral.configPv.modalidadClientePreferencial
      if(MODALIDAD_CLI_PRE==true){
        var BANDERA_CODBAR_CLI_PRE=this.$store.getters.GetConfiguracionGeneral.configPv.banderaCodigoBarraPreferencial
        var CLI_COD_BAR=this.clienteSeleccionado.codigoBarraPreferencial
        if(CLI_COD_BAR==null || CLI_COD_BAR!=null && CLI_COD_BAR.trim().length==0){ this.contSalvarCrearEditarCliente()}

        if(CLI_COD_BAR!=null && CLI_COD_BAR.trim().length>0 && CLI_COD_BAR.substr(0,2)!=BANDERA_CODBAR_CLI_PRE){
          this.alerta(`El código de barra colocado, no posee la bandera de barra de la configuración (${BANDERA_CODBAR_CLI_PRE})`, "error");
            return
          }else{
    
            this.SeachTextFilBarraPreferencial(CLI_COD_BAR)
            
          }



      }else{
        this.contSalvarCrearEditarCliente()
      }

  
} else {
  this.alerta("los campos en rojo son requeridos", "error");
}
},


contSalvarCrearEditarCliente() {

  if (this.EjecutarPermisoPv == false) {
    this.funcionValidaPermisoPv('AccCrearEditarCliente')
    return;
  }
  if (this.ejecucion == true) {
    this.ejecucion = false;
    this.loading(true)
    if (this.clienteSeleccionado.hasOwnProperty("telefonosClientesList") && this.clienteSeleccionado.telefonosClientesList.length > 0) {

      if(this.clienteSeleccionado.telefono!=null && this.clienteSeleccionado.telefono.length>3 ){
        this.clienteSeleccionado.telefonosClientesList[0].telefonosClientesPK.telefono = this.clienteSeleccionado.telefono
      }
      

    } else {
      this.clienteSeleccionado.telefonosClientesList = []
      var obj = { telefonosClientesPK: { telefono: this.clienteSeleccionado.telefono, cliente: this.clienteSeleccionado.codigo }, borrado: false }
      this.clienteSeleccionado.telefonosClientesList.push(obj)
    }

    this.$axios
      .post(
        this.$hostname + this.$hName + "/clientes/save/",
        this.clienteSeleccionado,
        { headers: this.$store.getters.GetheadersAxios }
      )
      .then((res) => {
        var CLIENTE_SELECT=JSON.parse(JSON.stringify(res.data))
        CLIENTE_SELECT.codnom=`${res.data.codigo} - ${res.data.nombre.trim()}`
       this.busquedaCliente=JSON.parse(JSON.stringify(CLIENTE_SELECT))
        this.getChangeClienteBusqueda(CLIENTE_SELECT)
        if(this.clienteFactura!=null && this.clienteFactura.codigo==CLIENTE_SELECT.codigo){
          this.clienteFactura=CLIENTE_SELECT
        }

          this.alerta("cliente guardado corretamente", "bien2");
      })
      .catch(error => {
        this.ejecucion = true;
        this.loading(false)
      }
      );
    //.then((res) => (this.RCliente = res.data));
  }

},


// Busqueda de codigo_barra_preferencial del cliente .
SeachTextFilBarraPreferencial(e){
  if(e.trim().length>0){
this.$axios
        .get(this.$hostname + this.$hName + "/clientes/getclientebarrapreferencial/"+e, {
          headers: this.$store.getters.GetheadersAxios,
        })
        .then((res) => {
          if(res.data.hasOwnProperty("codigo")){
            if(res.data.codigo!=this.clienteSeleccionado.codigo){
              if(res.data.codigoBarraPreferencial.trim()!=e.trim()){
                  this.contSalvarCrearEditarCliente()
              }else{
                this.alerta("Este código de barra preferencial ya está siendo utilizado por otro cliente.", "error")
              }
            }else{
              this.contSalvarCrearEditarCliente()
            }
          }else{
            this.contSalvarCrearEditarCliente()
            //this.alerta("No existe cliente preferencial con ese código", "error");
          }
          
        })
        .catch(error => { this.alerta("dato no encontrado", "error") });


  }else{

  }
         

},




 // Llamar a una API y obtener los datos del cliente.
 cargarClienteBusqueda() {
  var filtroTipoCliente=3
      var param=""
      this.$axios
        .get(this.$hostname + this.$hName + "/clientes/findtodo/a/" + filtroTipoCliente + "/25/0"+param, {
          headers: this.$store.getters.GetheadersAxios,
        })
        .then((res) => (this.RCliente = res.data));
    },
    // Hacer una llamada API al servidor y devolver los datos al cliente.
    CbFilcliente(e) {
      var filtroTipoCliente=3
      if (e.target.value.length >= 0) {

        var param=""
   
        this.$axios
          .get(
            this.$hostname +
            this.$hName +
            "/clientes/findtodo/" +
            (e.target.value === null || e.target.value.length==0 ? 'A' : e.target.value)  +
            "/" + filtroTipoCliente + "/25/0"+param,
            { headers: this.$store.getters.GetheadersAxios }
          )
          .then((res) => (this.RCliente = res.data));
      }
    },
    // Un método que recibe un cliente y verifica en una lista si tiene telefono .
    selectTelefonoCliente(e) {
      if (typeof e === 'object' && e.hasOwnProperty('telefonosClientesList') && e.telefonosClientesList!=null && e.telefonosClientesList.length > 0) {
        return e.telefonosClientesList[0].telefonosClientesPK.telefono.trim()
      } else { return ' ' }
    },
    // Comprobando si el valor de la entrada es nulo o indefinido.
    SelctCbClienteBuscar() {
      var _this = this;
      setTimeout(function () {
        if (_this.busquedaCliente== null) {
          _this.busquedaCliente= "";
        }

        if (!_this.busquedaCliente.hasOwnProperty('codigo')) {
          _this.busquedaCliente= "";
          _this.busquedaCliente= null;
          //  _this.alerta("Seleccione un cliente", "error");
          return;
        }
      }, 300);


     /* console.log('Lalallal Pamoinjn')
      if (this.busquedaCliente == null) {
        this.busquedaCliente = "";
      }
      if (this.busquedaCliente.codigo == undefined) {
        this.busquedaCliente = "";
        this.alerta("Seleccione un cliente", "error");
        return;
      }*/
    },
    // Una función que se llama cuando un usuario selecciona un cliente de una lista desplegable.
    getChangeClienteBusqueda(e) {
      if ( typeof e === 'object' && e != null) {

        var obj = JSON.parse(JSON.stringify(e))
        obj.telefono = this.selectTelefonoCliente(e)
        if(e.fechanacimiento!=null){
          var fecnac=this.getParamFecha(e.fechanacimiento)
          obj.fechanacimiento=fecnac.split("T")[0]
        }

        if (e.cedrnc != null) { obj.cedrnc = e.cedrnc.trim() } else { obj.cedrnc = '' }
        this.clienteSeleccionado = obj;

        //statuscliente--Suspendido:2
        //statuscliente--statuscliente:2
        //statuscliente:--Cancelado:3

      }
    },

 // Una función que que validad si el cliente seleccionado es a credito y desabilita el nombre y la direccion .
validarDisabledClienteCredito(e) {
  var MODIF_NOMB=this.$store.getters.GetConfiguracionGeneral.configPv.modificarNombreClienteCredito
  var BOOLSTADO=false
        if(MODIF_NOMB!=null && MODIF_NOMB==false){
          if ( typeof e === 'object' && e != null) {
          if(e.hasOwnProperty('diascredito') && e.diascredito!=null && e.diascredito>0){
            BOOLSTADO=true
          }
          }
        }

          return BOOLSTADO;
      
          
    },


    changeCbTipoComprobante(e,nombreFocu){
// cuando el codigo sea mayor a  y que pv sea true
if(e!=null&& e.hasOwnProperty('codigo')){
   var CODCOM=e.codigo
  //  if(CODCOM==2|| CODCOM==3 || CODCOM==4){

          if(CODCOM>1 && e.pv==true){
            this.cedularequired=true
            this.RedireccionFocus(nombreFocu)
          }else{
            this.cedularequired=false
          }
}

},


 // Llamar a una API  y devolver los datos del comprobante.
 cargarTipoComprobante() {
      this.$axios
        .get(this.$hostname + this.$hName + "/tiponcf/findallactivo", {
          headers: this.$store.getters.GetheadersAxios,
        })
        .then((res) => {
          this.RtipoComprobante = res.data.filter((ee) => ee.pv == true || ee.especial==true);
        });
    },

    getChangeTipoComprobante(e){

      //secuenciaComprobantes/existesecuencia/{documento}/{tipo}/{modulo}

      if ( typeof e === 'object' && e != null) { 
        var links=`${this.caja.sucursal.documentofacturacion}/${e.codigo}/PV`
        this.$axios.get(this.$hostname + this.$hName + "/secuenciaComprobantes/existesecuencia/"+links, {
          headers: this.$store.getters.GetheadersAxios,
        })
        .then((res) => {
            if(res.data==false){
              this.ComprobanteFisc.comprobante = null
              this.alerta("no posee secuencia este tipo de comprobante", "error");
            }
          
        });

      }
    },

    filterTipoComprobantePvItbis(List){
      //RtipoComprobante
      return List.filter((ee) => ee.pv==true && ee.itbis==true || ee.descripcion.trim().toLowerCase().includes('regimen especial') || ee.especial==true );
      //return List.filter((ee) => ee.pv==true && ee.itbis==true );
    },


     filterTipoComprobantePv(List){
      //RtipoComprobante
      return List.filter((ee) => ee.pv==true);
    },

  changeCbTipoComprobante(e,nombreFocu){
// cuando el codigo sea mayor a  y que pv sea true
if(e!=null&& e.hasOwnProperty('codigo')){
   var CODCOM=e.codigo
  //  if(CODCOM==2|| CODCOM==3 || CODCOM==4){

          if(CODCOM>1 && e.pv==true){
            this.cedularequired=true
            this.RedireccionFocus(nombreFocu)
          }else{
            this.cedularequired=false
          }
}

},



  
    // Comprobando si el valor de la selección es nulo o indefinido.
    SelctCbTipoComprobante() {
     
      var _this = this;
      setTimeout(function () {
        if (_this.clienteSeleccionado.tipocomprobante == null) {
          _this.clienteSeleccionado.tipocomprobante = "";
        }

        if (!_this.clienteSeleccionado.tipocomprobante.hasOwnProperty('codigo')) {
          _this.alerta("Seleccione un Tipo Comprobante", "error");
          _this.clienteSeleccionado.tipocomprobante = "";
          _this.clienteSeleccionado.tipocomprobante = null;
          return;
        }
        var CODCOM=_this.clienteSeleccionado.tipocomprobante.codigo
        var BOOLPV=_this.clienteSeleccionado.tipocomprobante.pv
        // if(CODCOM==2|| CODCOM==3 || CODCOM==4){
          if(CODCOM>1 && BOOLPV==true){
            _this.cedularequired=true
          }else{
            _this.cedularequired=false
          }


      }, 300);

    },



    MakaraCel() {
      if (this.clienteSeleccionado.cedrnc.toString().length == 9) {
        this.MakCelRnc = "###-#####-#";
      }
      if (this.clienteSeleccionado.cedrnc.toString().length >= 11) {
        this.MakCelRnc = "###-#######-#";
      }
    },

    SelctCbArsClient() {
      var _this = this;
      setTimeout(function () {
        if (_this.clienteSeleccionado.ars == null) {
          _this.clienteSeleccionado.ars = "";
        }

        if (!_this.clienteSeleccionado.ars.hasOwnProperty('codigo')) {
          _this.clienteSeleccionado.ars = "";
          _this.clienteSeleccionado.ars = null;
          return;
        }
      }, 300);
    },


    // Llamar a una API y obtener los datos de la API y almacenarlos en la variable RArs.
    cargarArs() {
      this.$axios
        .get(this.$hostname + this.$hName + "/ars/findallactivos", {
          headers: this.$store.getters.GetheadersAxios,
        })
        .then((res) => (this.RArs = res.data));
    },
    // Comprobando si el valor de la selección es nulo o indefinido.


    RedireccionFocus(e) {
      var _this = this;
      setTimeout(function () { _this.$refs[e].focus() }, 300)
    },

    validandoCedRnc(e){

if(e!=null && e.length>0){
if(this.validaCedRnc(e)==false){
  var NOM= e.length>10?"CEDULA":"RNC"
  this.alerta(`${NOM} Invalida `, "error");
    return false;
    }
  }

  return true;

},


// Llamar a una función en un componente secundario para los permisos de usuario.
funcionValidaPermisoPv(e) {

var Sudo = this.$store.getters.GetdatosInfUsu.usuario.sudo
var permiso = this.$store.getters.GetdatosInfUsu.usuario.usuarioPermisoPvList

//if (Sudo == false) { }
var obj = {
  accion: JSON.parse(JSON.stringify(e)),
  Permiso: permiso,
  Sudo: Sudo
}
this.$refs.RefPvMenuOpcionBotones.ClickAccionPadreEjecut(obj)

},

DataRetornPadrePermisoPv(e) {

   //Permite Crear Cliente"
   if (e == 'AccCrearEditarCliente') {
        this.EjecutarPermisoPv = true
        var _this = this;
        setTimeout(function () {
          _this.contSalvarCrearEditarCliente();
          _this.EjecutarPermisoPv = false
        }, 500);
        return;
      }


},

DataRetornMenuOpcBotonesPermisosPv(e) {

},

    // Una función que se llama cuando un usuario selecciona un cliente de una lista de clientes.
    async SeleccionarClienteFactura() { 

    },


    dataClienteSeleccionado(e){

      if ( typeof e === 'object' && e != null) { 
        this.busquedaCliente=JSON.parse(JSON.stringify(e))
        this.getChangeClienteBusqueda(e)
        this.changeCbTipoComprobante(this.clienteSeleccionado.tipocomprobante,'RefTextFieldcedrnc')
        }

    },
    


alerta(e, l) {
      if (l == "bien") {
        (this.aalert.estado = true),
          (this.aalert.color = "success"),
          (this.aalert.nombre = e);
        this.aalert.right = true
        this.aalert.top = false
        this.aalert.timeout = 3000
        // this.limpiarPuntoVentaCompleto();
      }
      if (l == "bien2") {
        (this.aalert.estado = true),
          (this.aalert.color = "success"),
          (this.aalert.nombre = e);
        this.aalert.right = true
        this.aalert.top = false
        this.aalert.timeout = 3000
        this.ejecucion = true
        this.loading(false)

      }
      if (l == "error") {
        (this.aalert.estado = true),
          (this.aalert.color = "error"),
          (this.aalert.nombre = e);
        this.aalert.right = false
        this.aalert.top = true
        this.aalert.timeout = 120000
        this.ejecucion = true;
        this.loading(false)
      }
    },  

    loading(e) {
      EventBus.$emit("loading", e);
    },


    
  },
  computed: { 
    GetEjecucion() {
      this.windowSize = JSON.parse(JSON.stringify(this.$store.getters.GetwindowSize))
     },

},
};
</script>
<style lang="scss">
</style>