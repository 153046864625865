<template>
  <div>{{ ActDescBtn }}
    <table style="position: absolute;" width="100%" :height="this.windowSize.height - 8"
      :max-height="this.windowSize.height - 8">
      <!-- ContenidoBase-Conduce -->
      <tr max-height="48px" height="48px">
        <ContenidoBase :titulo="'Conduce'" :descripcion="'Realizar las transacciones del Conduce'"
          :EstadoBtn="EstadoBtn" @abrirBarraTablaDinamica="abrirTabla" @MethodNuevo="newwnuevo" @MethodGuardar="save"
          @MethodEliminar="remove" @MethodAnular="NotifMessage" @MethodImprimir="imprimirList" />
      </tr>
       <!-- fn ContenidoBase-Conduce -->

      <tr>
        <!-- BarraPrimaria-Conduce  --------------------------------------------------------------------------------->
        <td width="109" :max-height="this.windowSize.height - 96" v-show="BarraTablaDinamicaAprir">

          <div class="overflow-y-auto "
            v-bind:style="{ 'max-height': this.windowSize.height - 96 + 'px', 'max-width': '325px', 'width': '325px' }">


            <v-tooltip left>
              <template v-slot:activator="{ on }">
                <!--------------------- aqui va la otra tabla izquierda --------->
                <!-- <v-navigation-drawer >  -->
                <center>
                  <!-- <v-btn-toggle  > </v-btn-toggle> -->
                  <v-btn small width="95%" color="yellow accent-4" @click.prevent="actualiza_tb_ver"> <i
                      style="font-size: 25px;" class="fa fa-eye"> </i> ver</v-btn>

                </center>
                <!-- <v-navigation-drawer >  -->
                <BarraDinamicaNormal ref="BarraDiNor" :url="$hostname + $hName + '/ConduceDespacho/findall/'"
                  :nombre="'Conduce'" :emitnombre="'emitConduce'" :SeachNombre="'Búsqueda general'" :headers="[
                  { text: 'Código', value: 'codigo' },
                  { text: 'Fecha', value: 'fecha' },
                  { text: 'Monto', value: 'monto' }]" :ContListSlot="[
    { Text: 'Código', NomValue: 'codigo', Ctsl: 1, Type: 0, State: true },
    { Text: 'Fecha', NomValue: 'fecha', Ctsl: 1, Type: 1, State: true },
    { Text: 'Monto', NomValue: 'monto', Ctsl: 1, Type: 2, State: true },
    { Text: 'Estado', NomValue: 'activo', Ctsl: 1, Type: 0.2, State: true },
  ]" />
                <!-- </v-navigation-drawer> -->
              </template>
            </v-tooltip>



          </div>
        </td>
        <!-- fn BarraPrimaria-Conduce  ---------------------------------------------------------------------------------->
        <!-- contenido central ------------------------------------------------------------------------------ -->
        <td :max-height="this.windowSize.height - 96">
          <v-app id="inspire" class="overflow-y-auto "
            v-bind:style="{ 'max-height': this.windowSize.height - 96 + 'px' }">
            <div class="px-2">
              <!-- Formulario-Conduce  -->
              <v-form ref="form" v-model="valid" lazy-validation>
                <input type="number" v-model="Conduce.conduce.codigo" style="display:none;" />

                <v-card color="grey lighten-3">
                  <div class="px-2">


                    <v-row>
                      <v-col cols="12" md="4" sm="12">
                        <v-combobox autocomplete="off" dense outlined label="Cliente:" :items="Rcliente"
                          item-text="nombre" @keyup="CbFil" v-model="Conduce.cliente" @blur="SelctCbClient"
                          @focus="cargarCliente" @change="BuscarFactura">
                          <template v-slot:prepend>
                            <i style="font-size: 30px;" class="fa fa-user-circle-o"></i>
                          </template>
                        </v-combobox>
                      </v-col>



                      <v-col cols="12" md="4" sm="6">
                        <v-text-field dense outlined type="date" label="Fecha Desde" v-model="Conduce.fechaDesde"
                          @blur="BuscarFactura">

                          <template v-slot:prepend>
                            <i style="font-size: 20px;" class="fa fa-calendar"></i>
                          </template>

                        </v-text-field>
                      </v-col>

                      <v-col cols="12" md="4" sm="6">

                        <v-text-field dense outlined type="date" label="Fecha Hasta" v-model="Conduce.fechaHasta"
                          @blur="BuscarFactura">

                          <template v-slot:prepend>
                            <i style="font-size: 20px;" class="fa fa-calendar"></i>
                          </template>

                        </v-text-field>
                      </v-col>

                    </v-row>
                    <v-row style="margin-top:-30px;">
                      <v-col cols="12" md="4" sm="6">
                        <v-combobox autocomplete="off" dense outlined label="Documentos:" :items="RDocumento"
                          item-text="descripcion" v-model="Conduce.documento" @blur="SelctCbDocumento"
                          @focus="cargarDocumento" @change="BuscarFactura" :disabled="disabled">
                          <template v-slot:prepend>
                            <i style="font-size: 30px;" class="fa fa-book"></i>
                          </template>
                        </v-combobox>
                      </v-col>

                      <v-col cols="12" md="4" sm="6">
                        <v-text-field label="Factura" autocomplete="off" dense outlined v-model="Conduce.factura"
                          @keyup="NumeralFac" :disabled="disabled" @blur="BuscarFactura">
                          <template v-slot:prepend>
                            <i style="font-size: 20px;" class="fa fa-list-alt"></i>
                          </template>
                        </v-text-field>
                      </v-col>
                    </v-row>
                  </div>
                </v-card>
                <hr>
                <v-card color="grey lighten-3">
                  <div class="px-2">
                    <v-row>
                      <v-col cols="12" lg="12" md="12" sm="12">
                        <center><b> Factura </b></center>


                        <v-data-table v-model="selected" dense :headers="[
                          { text: '#Factura', value: 'facturasventaPK.secuencia', sortable: false, class: ['no-gutters black--text'] },
                          { text: 'Fecha', value: 'fecha', sortable: false, class: ['no-gutters black--text'] },
                          { text: 'Documento', value: 'documentoFactura.descripcion', sortable: false, class: ['no-gutters black--text'] },
                          { text: 'Cliente', value: 'cliente.nombre', width: 250, sortable: false, class: ['no-gutters black--text'] },
                          { text: 'Monto', value: 'valor', sortable: false, class: ['no-gutters black--text'] },
                          { text: 'Días Crédito', value: 'diacredito', sortable: false, class: ['no-gutters black--text'] },
                        ]" :items="RFactura" item-key="facturasventaPK.secuencia" class="elevation-1"
                          :single-select="true" :show-select="true" @item-selected="obtenerDatoDetFact"
                          @click:row="obtenerDatoDetFactClickRow" :items-per-page="5">
                          <template v-slot:item.fecha="props">
                            <span>{{ FormatoFecha(props.item.fecha) }}</span>
                          </template>


                          <template v-slot:item.horaFactura="props">
                            <span>{{ HoraFormatter(props.item.horaFactura) }}</span>
                          </template>

                          <template v-slot:item.valor="props">
                            <span>{{ currencyFormatter(props.item.valor) }}</span>
                          </template>

                          <template v-slot:item.diacredito="props">

                            <span v-if="props.item.hasOwnProperty('cliente') && props.item.cliente != null">{{
                                props.item.cliente.diascredito
                            }}</span>

                          </template>

                        </v-data-table>

                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col cols="12" lg="12" md="12" sm="12">
                        <center><b> Detalle Factura </b>
                          <v-col class="pa-0 ma-0 py-0 my-0" cols="12" md="8" sm="8">
                            <v-textarea autocomplete="off" label="comentario:" dense outlined v-model="Conduce.conduce.comentario" 
                            hide-details rows="2" v-show="selected.length>0">
                             <template v-slot:prepend>
                             <i style="font-size: 20px;" class="fa fa-commenting"></i>
                               </template>
                               
                          <template v-slot:append-outer>
                            <v-btn v-show="$store.getters.GetConfiguracionGeneral.configPv.imprimirConduceConfactura==true && Conduce.conduce.codigo>0" id="boton-mini" color="blue" dark small  @click.prevent="getImprimir">Imprimir
                             <i style="font-size: 28px;" class="fa fa-print"> </i>
                             </v-btn>
                          </template>


                             </v-textarea>

                          </v-col>

                 
                        </center>
                        <v-data-table dense :headers="[
                          { text: '#Factura', value: 'facturasventaDetallePK.secuencia', sortable: false, class: ['no-gutters black--text'] },
                          { text: 'Código Producto', value: 'facturasventaDetallePK.producto', sortable: false, class: ['no-gutters black--text'] },
                          { text: 'Producto', value: 'productos.descripcion', sortable: false, class: ['no-gutters black--text'] },
                          { text: 'Unidad', value: 'unidades.descripcion', sortable: false, class: ['no-gutters black--text'] },
                          { text: 'Precio', value: 'precio', sortable: false, class: ['no-gutters black--text'] },
                          { text: 'Cantidad facturado', value: 'cantidad', sortable: false, align: 'center', class: ['no-gutters black--text'] },
                          { text: 'Cantidad Despachada', value: 'cantidadDespachada', align: 'center', sortable: false, class: ['no-gutters black--text'] },
                          { text: 'Cantidad a Despachar', value: 'cantidadDespachar', sortable: false, class: ['no-gutters black--text'] },
                        ]" :items="RDetFactura" item-key="name" class="elevation-1" :items-per-page="5">



                          <template v-slot:item.cantidadDespachada="props">
                            <span v-if="props.item.cantidadDespachada == undefined">
                              <p style="display:none;">{{ props.item.cantidadDespachada = 0 }}</p>
                            </span>
                            <span>{{ props.item.cantidadDespachada }}</span>
                          </template>



                          <template v-slot:item.cantidadDespachar="props">
                            <AutoNumeric v-model="props.item.cantidadDespachar" @blur="CantidadDesp(props.item)"
                              required outlined dense :disabled="disabled" hide-details>
                            </AutoNumeric>

                            <!-- <p style="display:none;">
  {{SumaMonto(props.item) }} 

  </p>  -->


                          </template>

                        </v-data-table>
                      </v-col>
                    </v-row>


                  </div>
                </v-card>


              </v-form>
              <!-- Formulario-Conduce  -->
            </div>

          </v-app>
        </td>
        <!-- fn contenido central ------------------------------------------------------------------------------------->
        <!-- BarraSecundaria ------------------------------------------------------------------------------------------>
        <td width="109" :max-height="this.windowSize.height - 96" v-show="BarraSecundariaAprir">

          <div class="overflow-y-auto "
            v-bind:style="{ 'max-height': this.windowSize.height - 96 + 'px', 'max-width': '325px', 'width': '325px' }">


          </div>

        </td>
        <!-- fn BarraSecundaria --------------------------------------------------------------------------------------->



      </tr>
    </table>
    <!-- Modales -------------------------------------------------------------------------------------------------->
    <!-- ------------------------------------------------------ tb-data todo general -->
    <v-dialog label="myAlert" v-model="acti_tb_data" persistent transition="dialog-bottom-transition" fullscreen
      hide-overlay>
      <v-card>

        <v-toolbar dense flat color="#BDBDBD">

          <i style="font-size: 20px;" class="fa fa-file-pdf-o"> </i>
          <v-toolbar-title class="font-weight-light"> REPORTE </v-toolbar-title>
          <i style="font-size: 20px;" class="fa fa-file-pdf-o"> </i>

          <v-spacer></v-spacer>
          <v-tooltip top>
            <template v-slot:activator="{ on }">
              <v-btn v-on="on" dark small color="deep-purple accent-2" @click.prevent="dialogimprimirListFilt = true">
                <i style="font-size: 25px;" class="fa fa-print"> </i>
                Listado
              </v-btn>

            </template>
            <span>Imprimir Listado</span>
          </v-tooltip>
          <v-btn color="grey darken-4" fab small @click="acti_tb_data = !acti_tb_data">
            <i style="font-size: 20px; color:#dd4b39;" class="fa fa-close"></i>
          </v-btn>
        </v-toolbar>

        <v-card-text>


          <v-card>
            <v-card-title>
              <v-text-field v-model="search" @keyup="filtro" label="Buscar" single-line hide-details>
                <template v-slot:prepend-inner>
                  <i style="font-size: 35px;" class="fa fa-search"></i>
                </template>

              </v-text-field>
            </v-card-title>

            <v-data-table :headers="[
              { text: 'Código', value: 'codigo', sortable: false, class: ['no-gutters black--text'] },
              { text: 'Fecha', value: 'fecha', sortable: false, class: ['no-gutters black--text'] },
              { text: 'Monto', value: 'monto', sortable: false, class: ['no-gutters black--text'] },
              { text: 'Cliente', value: 'cliente.nombre', sortable: false, class: ['no-gutters black--text'] },
              { text: 'Estado', value: 'activo', sortable: false, class: ['no-gutters black--text'] }
            ]" :items="list" :search="search" @click:row="datostabla">

              <template v-slot:item.fecha="props">
                <span>{{ FormatoFecha(props.item.fecha) }}</span>
              </template>
              <template v-slot:item.activo="props">
                <span v-if="props.item.activo">Activo</span>
                <span v-if="!props.item.activo">Nulo</span>
              </template>

            </v-data-table>
          </v-card>


        </v-card-text>

      </v-card>


    </v-dialog>
    <!-- ------------------------------------------------------ fn tb-data todo general-->


    <!-- ------------------------------------------------------ Modal-notificacion general -->
    <v-dialog label="myAlert" v-model="notif.estado" persistent max-width="350" transition="fab-transition">
      <template justify="center" v-slot:activator="{ on }"></template>

      <v-card>
        <v-card-title>
          <h4>
            <i style="font-size: 28px;" class="fa fa-warning"></i>
            CONFIRMACION
            <i style="font-size: 28px;" class="fa fa-warning"></i>
          </h4>
        </v-card-title>
        <hr />

        <v-card-text>
          <h5>{{ notif.nombre }}</h5>
        </v-card-text>

        <hr />
        <v-card-actions>
          <v-btn v-if="notif.Exec == 'AnularConduce'" @click.prevent="anular(), (notif.estado = !notif.estado)" small>
            <i style="font-size: 20px;" class="fa fa-check"></i> Si
          </v-btn>
          <v-btn v-if="notif.Exec == 'EliminarConduce'" @click.prevent="eliminar(), (notif.estado = !notif.estado)"
            small>
            <i style="font-size: 20px;" class="fa fa-check"></i> Si
          </v-btn>





          <v-btn @click.prevent="notif.estado = !notif.estado" small>
            <i style="font-size: 20px;" class="fa fa-ban"></i> No
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- ------------------------------------------------------  fn Modal-notificacion general -->




    <!-- ------------------------------------------------------   alerta -->
    <v-snackbar v-model="aalert.estado" :color="aalert.color" style="margin-top: 20%;" :vertical="true"
      :timeout="120000" top="top">
      {{ aalert.nombre }}
      <v-btn dark text @click="aalert.estado = false">Close</v-btn>
    </v-snackbar>
    <!-- ------------------------------------------------------  fn alerta -->
    <!-------------------------------------------------------- fn Modal filtro -->

    <!-- ------------------------------------------------------ Modal-Reportes Conduce -->
    <v-dialog transition="fab-transition" label="Modal agregar cuenta" v-model="dialogimprimirListFilt" persistent
      max-width="80%" max-height="50%">
      <!----------------------------------------------------------------- Cuerpo Reporte -->
      <v-card color="grey lighten-3">

        <v-toolbar flat color="#BDBDBD" dense style="border: 1px solid #000000;">

          <v-avatar tile size="45">
            <img src="@/assets/iconos_micromarket/custom-reports.png" alt="Reporte">
          </v-avatar>
          <span class="font-weight-light" style="font-size: 20px;"> <b>REPORTE CIERRE DE CAJA </b> </span>

          <v-spacer></v-spacer>
          <v-btn color="red" fab small @click.prevent="dialogimprimirListFilt = false">
            <i style="font-size: 20px; color:white;" class="fa fa-close"></i>
          </v-btn>
        </v-toolbar>

        <hr>

        <v-container style="border: 1px solid #000000;">
          <v-form ref="form" @submit.prevent="" v-model="valid" lazy-validation>
            <v-row class="pa-0 ma-0">

              <v-col cols="12" md="6" sm="6">

                <v-text-field dense outlined type="date" label="Desde*" v-model="datosImprecionAll.desde"
                  :rules="[$rules.required]" @blur="BlurDesde">

                  <template v-slot:prepend>
                    <i style="font-size: 20px;" class="fa fa-calendar"></i>
                  </template>
                </v-text-field>
              </v-col>

              <v-col cols="12" md="6" sm="6">



                <!-------------------------------------------------- Hasta -->
                <v-text-field dense outlined type="date" label="Hasta*" v-model="datosImprecionAll.hasta"
                  :rules="[$rules.required]" @blur="BlurHasta">

                  <template v-slot:prepend>
                    <i style="font-size: 20px;" class="fa fa-calendar"></i>
                  </template>

                </v-text-field>


              </v-col>

            </v-row>
          </v-form>
        </v-container>

        <hr>

        <ModalReporteFiltros @DataReporteBasico="DDataReporteBasico" :Datos="datosImprecionAll" />

      </v-card>
      <!-------------------------------------------------------------------------------- Fn Cuerpo Reporte -->
    </v-dialog>
    <!-- ------------------------------------------------------ fn Modal-Reportes Conduce -->

    <!-- ------------------------------------------------------ Modal- Reporte Basico-->
    <v-dialog transition="fab-transition" label="Modal Reporte Basico" v-model="dialogimprimirList" persistent
      max-width="80%" max-height="50%">

      <ModalReporteBasico @DataReporteBasico="DDataReporteBasico" :Datos="datosImprecion" />

    </v-dialog>
    <!-- ------------------------------------------------------ fn Modal Reporte Basico-->



    <!-- fn Modales ----------------------------------------------------------------------------------------------->


  </div>

</template>
<script>
import AutoNumeric from "../../components/AutoNumericNuevo";
import numeral from 'numeral'
import { EventBus } from '../../event-bus'
import BarraBusqueda2 from "../../components/BarraBusquedaDinam";
import BarraDinamicaNormal from "../../components/BarraDinamicaNormal";
import ContenidoBase from "../../components/ContenidoBase";
import ModalReporteFiltros from "../../components/ModalReporteFiltros";
import ModalReporteBasico from "../../components/ModalReporteBasico";
import { currencyFormatter,
  FormatoFecha,
  HoraFormatter,
  HoraFormatter2,
  RedondeoValor,
  RedondeoBasico,
  RedondeoValorCantRed,
  fechaNueva,
  getParamFecha,
  validaCedRnc,
  getFechaZone,
  getHoraActual } from "@/js/Funciones.js";

export default {
  components: {
    BarraBusqueda2,
    AutoNumeric,
    BarraDinamicaNormal,
    ContenidoBase,
    ModalReporteFiltros,
    ModalReporteBasico

  },

  mounted() {
    EventBus.$on("abrirMenuizquierdo", this.abrirTabla);
    //EventBus.$on("abrirMenuizquierdo", this.abrirTabla2);
    EventBus.$on("emitConduce", this.datostabla);
    // EventBus.$on("ConduceNew", this.new);
    // EventBus.$on("ConduceSave", this.save);
    // EventBus.$on("ConduceRemove", this.remove);
    // EventBus.$on("ConduceAnular", this.anular);
    // EventBus.$on("ConducePrintList", this.imprimirList);
    this.filtro()




  },

  created() {
    var json = {
      titulo: "Conduce",
      descripcion: "Realizar las transacciones del Conduce",
      save: true,
      eliminar: false,
      anular: false,
      nuevo: true,
      imprimir: true,
      accion: [
        "ConduceNew",
        "ConduceSave",
        "ConduceRemove",
        "ConduceAnular",
        "ConducePrintList"
      ]
    };
    EventBus.$emit("anularBotonesAccion", json);
  },
  data() {
    return {
      windowSize: { width: "auto", height: "auto" },
      valid: true,
      disabled: false,
      showSlect: false,
      RDocumento: [],

      dialogimprimirListFilt: false,

      datosImprecionAll: {
        nombre: 'LISTA DE CONDUCE',
        UrlImprimir: '/ConduceDespacho/imprimirListConduce',
        UrlExel: '/ConduceDespacho/ExcelListConduce',
        UrlCorreo: '/ConduceDespacho/enviarEmailListConduce',
        secuencia: 0,
        desde: fechaNueva(),
        hasta: fechaNueva(),
      },

      dialogimprimirList: false,
      datosImprecion: {
        nombre: ' CONDUCE',
        UrlImprimir: '/ConduceDespacho/imprimirConduce',
        UrlExel: '/ConduceDespacho/ExcelConduce',
        UrlCorreo: '/ConduceDespacho/CorreoConduce',
        secuencia: 0
      },




      Conduce: {
        caja:null,
        fechaDesde: null,
        fechaHasta: null,
        documento: null,
        cliente: null,
        factura: 0,

        conduce: {
          codigo: 0,
          fecha: fechaNueva(),
          monto: 0,
          activo: true,
          comentario:''
        },
        RDetFactura: []

      },

      EstadoBtn: {
        BarraTablaDinamicaAprir: true,
        nuevo: true,
        guardar: true,
        eliminar: false,
        anular: false,
        imprimir: false,
        vercuenta: false,
        guardartemporal: false,
        restaurartemporal: false,
        importarDato: false,
        cargarProducto: false,
        ImprimirproductoInv: false,
      },




      Colss: {
        p1: 12,
      },




      Rcliente: [],
      RFactura: [],
      RDetFactura: [],
      selected: [],



      headers: [{ text: "Código", value: "codigo" }],

      slotlist: [
        { dt0: "codigo", dt1: null, dt2: null, dt3: 1, dt4: "Código:" },
        { dt0: "fecha", dt1: null, dt2: null, dt3: 2, dt4: "Fecha:" },
        { dt0: "monto", dt1: null, dt2: null, dt3: 5, dt4: "Monto:" },
        { dt0: "cliente", dt1: "nombre", dt2: null, dt3: 1, dt4: "Cliente:" }

      ],



      list: [],
      search: "",
      acti_tb_data: false,

      ejecucion: true,
      aalert: {
        estado: false,
        color: null
      },

      notif: {
        estado: false,
        nombre: "",
        Exec: ""
      },



      BarraTablaDinamicaAprir: false,
      BarraSecundariaAprir: false


    };
  },
  methods: {
    currencyFormatter,
  FormatoFecha,
  HoraFormatter,
  HoraFormatter2,
  RedondeoValor,
  RedondeoBasico,
  RedondeoValorCantRed,
  fechaNueva,
  getParamFecha,
  validaCedRnc,
  getFechaZone,
  getHoraActual,

   // Realizar una solicitud GET al servidor y devolver los datos al componente Vue.
    cargarCliente() {

      this.$axios.get(this.$hostname + this.$hName + '/clientes/findtodo/a/3', { headers: this.$store.getters.GetheadersAxios })
        .then(res => (this.Rcliente = res.data))

    },

    ver(e) {
      console.log(e)
    },



   // Comprobando si el cliente es nulo o indefinido.
    SelctCbClient() {

      var _this = this;
      setTimeout(function () {
        if (_this.Conduce.cliente== null) {
          _this.Conduce.cliente= "";
        }

        if (!_this.Conduce.cliente.hasOwnProperty('codigo')) {
          _this.Conduce.cliente= "";
          _this.Conduce.cliente= null;
          //  _this.alerta("Seleccione un cliente", "error");
          return;
        }
      }, 300);


    },



    // Hacer una solicitud al servidor y devolver los datos.
    CbFil(e) {
      //console.log(e.target.value)

      if (e.target.value.length >= 2) {
        // console.log("ok1")
        this.$axios
          .get(
            this.$hostname +
            this.$hName +
            '/clientes/findtodo/' + e.target.value + '/3', { headers: this.$store.getters.GetheadersAxios }
          )
          .then(res => (this.Rcliente = res.data));
      }


      // if (e.target.value.length >= 2) {
      //   //console.log("ok1")
      //   this.$axios
      //     .post(
      //       this.$hostname +
      //         this.$hName +
      //         "/services/clienteservices/findCliente",
      //       { descripcion: e.target.value, usuarios:this.$User, op:3 }
      //     )
      //     .then(res => (this.Rcliente = res.data));
      // }



    },



    // async SumaMonto(e){

    // this.Conduce.conduce.monto+= e.precio*e.cantidadDespachar

    // console.log(e)

    // },





   // Una función que se llama cuando el usuario ingresa un valor en el campo de entrada.
    CantidadDesp(e) {

      console.log(e)

      if (e.cantidadDespachar > e.cantidad - e.cantidadDespachada) {

        e.cantidadDespachar = e.cantidad - e.cantidadDespachada

        this.alerta(" 'Cantidad a despachar' no puede ser mayor a 'cantidad facturada'.", "error")


      }

      if (e.cantidadDespachar <= 0) {

        e.cantidadDespachar = 0

      }



    },

    async allobtener() {
      //console.log(this.selected)
      //console.log("ok")

      this.selected.forEach(element1 => {
        element1.facturasventaDetalleList.forEach(element2 => {
          //console.log(element2)
          var json = element2
          this.RDetFactura.push(json)
        });
      });

    },

    allobtenerDatoDetFact(e) {

      if (e.value == true) {
        this.RDetFactura = []
        setTimeout(this.allobtener, 200);


        // this.selected.forEach(element1 => {

        // element1.facturasventaDetalleList.forEach(element2 => {

        //   console.log(element2)

        // this.RDetFactura.push(element2)

        // });


        // });

      }

      if (e.value == false) {

        this.RDetFactura = []
        setTimeout(this.allobtener, 200);

      }
    },

   // Empujando la fila seleccionada en la a la tabla seleccionada.
    obtenerDatoDetFactClickRow(e) {
      this.RDetFactura = []
      this.selected = []
      this.RDetFactura = e.facturasventaDetalleList
      var ObjRDetFactura = []
      Promise.all(e.facturasventaDetalleList.map(async (element) => { 
       // ObjRDetFactura = ObjRDetFactura.concat(element.facturasventa.facturasventaDetalleList);
       element.cantidadDespachar=element.cantidad
       ObjRDetFactura.push(element)
     }));

     this.RDetFactura = JSON.parse(JSON.stringify(ObjRDetFactura))
  

      this.selected.push(e)
      this.Conduce.caja=JSON.parse(JSON.stringify(e.caja))
    },

    // Un método que se llama cuando una casilla de verificación está marcada o desmarcada.
    obtenerDatoDetFact(e) {

      //console.log(e)

      this.obtenerDatoDetFactClickRow(e.item)

      // var _this = this;
      // setTimeout(function () {

      // }, 300);


      /*   if (e.value == true) {
           //var obj=e.item.facturasventaDetalleList
           //console.log("ver")
   
           //this.RDetFactura=[]
   
           setTimeout(this.allobtener, 200);
   
   
           // e.item.facturasventaDetalleList.forEach(element => {
           // this.RDetFactura.push(element)
   
           // });
         }
   
         if (e.value == false) {
   
           this.RDetFactura = []
           setTimeout(this.allobtener, 200);
   
   
           //   this.RDetFactura.forEach(element1 => {
   
           // if(element1.facturasventaDetallePK.secuencia==e.item.facturasventaPK.secuencia){ 
   
           //  const index = this.RDetFactura.indexOf(e.item.facturasventaDetalleList)
           // this.RDetFactura.splice(index, 1)
           // }
           // });
           // const index = this.RDetFactura.indexOf(e.item.facturasventaDetalleList)
           // this.RDetFactura.splice(index, 1)
         }*/




    },

    NumeralFac(e) {
      if (e.key) {

        var mynumeral = require('numeral')
        this.Conduce.factura = mynumeral(this.Conduce.factura).value()
        //console.log(mynumeral(""+this.anular.factura)).value()
        if (this.Conduce.factura == null) {

          this.Conduce.factura = 0
        }

      }
      else {
        console.log("no se puede poner letras o signos")
      }
    },





    // Hacer una llamada API al servidor y devolver una lista de objetos.
    BuscarFactura() {

      //this.new()
      this.RDetFactura = []
      this.showSlect = false


      this.RFactura = []

      if (this.Conduce.fechaDesde != null && this.Conduce.fechaHasta != null && this.Conduce.cliente != null) {

        this.$axios.get(this.$hostname + this.$hName + '/ConduceDespacho/findNFacturaPorFechClient/' + this.Conduce.fechaDesde + '/' + this.Conduce.fechaHasta + '/' + this.Conduce.cliente.codigo,
          { headers: this.$store.getters.GetheadersAxios })
          .then(res => {

            this.Llenartabla(res.data)

          });

        return
      }

      if (this.Conduce.fechaDesde != null && this.Conduce.fechaHasta != null && this.Conduce.cliente == null) {

        this.$axios.get(this.$hostname + this.$hName + '/ConduceDespacho/findNFacturaPorFech/' + this.Conduce.fechaDesde + '/' + this.Conduce.fechaHasta + '',
          { headers: this.$store.getters.GetheadersAxios })
          .then(res => {
            this.Llenartabla(res.data)

          });

        return

      }


      if (this.Conduce.factura != 0 && this.Conduce.documento != null) {


        this.$axios.get(this.$hostname + this.$hName + '/ConduceDespacho/findNFactura/' + this.Conduce.factura + '/' + this.Conduce.documento.codigo,
          { headers: this.$store.getters.GetheadersAxios })
          .then(res => {

            this.Llenartabla(res.data)

          });


        return

      }



      if (this.Conduce.cliente != null) {

        this.$axios.get(this.$hostname + this.$hName + '/ConduceDespacho/findNFacturaPOrClient/' + this.Conduce.cliente.codigo,
          { headers: this.$store.getters.GetheadersAxios })
          .then(res => {
            this.Llenartabla(res.data)

          });

      }


    },





    Llenartabla(e) {

      e.forEach(element => {

        this.RFactura.push(element)


      });



    },



   // Realizar una solicitud GET al servidor y devolver los datos al componente Vue.
    cargarDocumento() {

      this.$axios.get(this.$hostname + this.$hName + '/documentoventas/documentoFacturaVentas', { headers: this.$store.getters.GetheadersAxios })
        .then(res => (this.RDocumento = res.data))

    },


    // Comprobando si el valor de la selección es nulo o indefinido.
    SelctCbDocumento() {

      var _this = this;
      setTimeout(function () {
        if (_this.Conduce.documento== null) {
          _this.Conduce.documento= "";
        }

        if (!_this.Conduce.documento.hasOwnProperty('codigo')) {
          _this.Conduce.documento= "";
          _this.Conduce.documento= null;
          //  _this.alerta("Seleccione un cliente", "error");
          return;
        }
      }, 300);

    },


    HoraFormatter(params) {
      if (params != null) {
        if (params.length > 0) {

          return (params.substring(0, 8));

        }
      }
    },

    currencyFormatter(params) {
      return this.formatNumber(params);
    },

    formatNumber(number) {
      //console.log(number+"->"+number.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,"))

      // var p=Math.trunc(number)
      // var pd=number-p
      //console.log(number.toFixed(2))

      if (number == null) { number = 0 }
      return number
        .toFixed(2)
        .toString()
        .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");

      // return Math.floor(number)
      //   .toString()
      //   .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
    },
    //Abriendo una panel con los datos del formulario.
    abrirTabla() {
      // console.log("entro");
      this.BarraTablaDinamicaAprir = !this.BarraTablaDinamicaAprir;

      if (this.BarraTablaDinamicaAprir == true) {
        this.$refs.BarraDiNor.list = []
        this.$refs.BarraDiNor.filtro()

      }


      // if(this.abrirTablaDinamica==true){this.Colss.p1=8}
      // if(this.abrirTablaDinamica==false){this.Colss.p1=12}


    },
    abrirTabla2() {
      // console.log("entro");
      this.BarraSecundariaAprir = !this.BarraSecundariaAprir;
    },
    // abrirTabla2() {
    //   // console.log("entro");

    //   return !this.abrirTablaDinamica
    // }



    getImprimir(){

      var FACTURASELEC=JSON.parse(JSON.stringify(this.selected[0]))

      FACTURASELEC.facturasventaDetalleList=this.RDetFactura
      FACTURASELEC.comentario=this.Conduce.conduce.comentario
      FACTURASELEC.conduceDespacho=this.Conduce.conduce
      //this.selected[0].usuario.
      this.$store.getters.GetdatosInfUsu.usuario.nombre
      var msg = {
            accion: "print",
            formato: this.Conduce.caja.formatoimpresioncontado.trim(),
            impresora: JSON.parse(localStorage.printerSelect).nombre,
            data: JSON.parse(sessionStorage.ConfiguracionGeneral),
            fac: FACTURASELEC,
            caja: this.Conduce.caja,
            cajero: this.$store.getters.GetdatosInfUsu.usuario.usuario
          };
          this.imprimir(msg, "conduceConfactura");
    },

     // Envío de una solicitud POST al servidor con los datos a imprimir.
     imprimir(datos, operacion) {
      datos.operacion = operacion;
      console.log(datos)
      this.$axios
        .post("http://localhost:15999/impresora/imprimir", datos)
        .then((res) => {
          // this.newwnuevo();
        })
        .catch((error) => console.error(error));
    },

    alerta(e, l) {
      if (l == "bien") {
        (this.aalert.estado = true),
          (this.aalert.color = "success"),
          (this.aalert.nombre = e);

        this.newwnuevo();
      }

      if (l == "error") {
        (this.aalert.estado = true),
          (this.aalert.color = "error"),
          (this.aalert.nombre = e);

        this.ejecucion = true;
        EventBus.$emit("loading", false);
      }
    },


    // resetea un objeto y asignándolo a la variable EstadoBtn el estado de las acciones.
    newwnuevo() {

      this.BarraTablaDinamicaAprir = false


      this.Conduce = {
        caja:null,
        fechaDesde: null,
        fechaHasta: null,
        documento: null,
        cliente: null,
        factura: 0,

        conduce: {
          codigo: 0,
          fecha: this.fechaNueva(),
          monto: 0,
          conduceDespachoDetalleList: [],
          comentario:''
        },
        RDetFactura: []

      },

        this.showSlect = false,

        this.selected = [];

      this.RFactura = [],
        this.RDetFactura = [],
        this.ejecucion = true
      this.disabled = false
      EventBus.$emit("actualizaBarraBusqueda2")


      // var objjct = {
      //       save: true,
      //       eliminar: false,
      //       anular: false,
      //       nuevo: true,
      //       imprimir: true,
      //       vercuenta:false, 
      //     };


      this.EstadoBtn = {
        BarraTablaDinamicaAprir: true,
        nuevo: true,
        guardar: true,
        eliminar: false,
        anular: false,
        imprimir: true,
        vercuenta: false,
        guardartemporal: false,
        restaurartemporal: false,
        importarDato: false,
        cargarProducto: false,
        ImprimirproductoInv: false,
      }

      EventBus.$emit("loading", false);
      //EventBus.$emit("ActDesaBotones", objjct);

    },
// llama a una api y guarda los datos del conduce
    save() {
      var monto = 0.00;

      if (this.Conduce.conduce.codigo != 0) {

        this.alerta("No puede modificar el conduce ya creado", "error");
        return

      }


      //this.Conduce.conduce.codigo


      if (this.RDetFactura.length == 0) {

        this.alerta("No se ha seleccionado factura para el conduce", "error");

        return

      }

    // this.RDetFactura.forEach(element1 => { });
      Promise.all(this.RDetFactura.map(async (element1) => { 
        if (element1.cantidadDespachar > 0) {this.Conduce.RDetFactura.push(element1)
          // this.Conduce.conduce.monto+= element.precio*element.cantidadDespachar
        }
      }));

    
      var det = [];
      // this.Conduce.RDetFactura.forEach(element => {});

      Promise.all(this.Conduce.RDetFactura.map(async (element) => {  
        if (element.cantidadDespachar > 0) {
          monto += (element.precio * element.cantidadDespachar);
          det.push({
            codigo: null,
            cantidadDespachar: element.cantidadDespachar,
            cantidad: element.cantidad,
            cantidadDespachada: element.cantidadDespachar,
            precio: element.precio,
            borrado: false,
            conduceDespacho: null,
            facturasventa: this.selected[0],
            producto: element.productos,
            unidades: element.unidades,
            descripcion: element.descripcion
            //descripcion:element.producto.descripcion+" "+element.unidad.descripcion
          })
        }

       }));

       var CLIENTE =this.selected[0].cliente
      
      if (this.$refs.form.validate()) {
        if (this.ejecucion == true) {
          EventBus.$emit("loading", true);
          this.ejecucion = false
          var cond = {
            codigo: null,
            monto: monto,
            fecha: this.fechaNueva(),
            cliente: CLIENTE,
            borrado: false,
            conduceDespachoDetalleList: det,
            comentario:this.Conduce.conduce.comentario
          };
       
          this.$axios.post(this.$hostname + this.$hName + '/ConduceDespacho/save', cond,
            { headers: this.$store.getters.GetheadersAxios })
            .then(res => {
              this.alerta("Dato guardado correctamente", "bien")

             /*var _this = this;
          setTimeout(function () {
            _this.datostabla(res.data)
          }, 800);*/

            })
            .catch(error => this.alerta(error, 'error'))

        }
      } else {

        this.alerta("Los campos en rojo son Requeridos", "error");

      }






    },



    remove() {
      if (this.Conduce.conduce.codigo != 0 && this.Conduce.conduce.codigo != null) {

        var notif = {
          estado: true,
          nombre: "¿Seguro que desea eliminar este registro?",
          Exec: "EliminarConduce"
        }

        this.Exec_notif(notif)


      } else { this.alerta("Seleccione un conduce para eliminar", "error") }


    },

    eliminar() {
      if (this.Conduce.conduce.codigo != 0 && this.Conduce.conduce.codigo != null) {

        if (this.$refs.form.validate()) {
          this.$axios.post(this.$hostname + this.$hName + '/ConduceDespacho/remove', this.conduce,
            { headers: this.$store.getters.GetheadersAxios })
            .then(res => this.alerta('Dato eliminado correctamente', 'bien'))
            .catch(error => this.alerta(error, 'error'))
        }
      }


    },

// notifica una alerta de accion
    NotifMessage() {

      if (this.Conduce.conduce.codigo != 0 && this.Conduce.conduce.codigo != null) {

        var notif = {
          estado: true,
          nombre: "¿Seguro que desea anular este registro?",
          Exec: "AnularConduce"
        }

        this.Exec_notif(notif)


      } else { this.alerta("Seleccione un conduce para anular", "error") }


    },

    Exec_notif(e) {

      //console.log(e)
      this.notif = JSON.parse(JSON.stringify(e))

    },


    // Realización de una llamada a la API para actualizar el estado del registro.
    anular() {


      // this.usuarioModificacion = this.$store.getters.GetdatosInfUsu.usuario.username

      if (this.Conduce.conduce.codigo !== 0) {
        this.$axios
          .get(
            this.$hostname +
            this.$hName +
            "/ConduceDespacho/Anular/" + this.Conduce.conduce.codigo,
            {
              headers: this.$store.getters.GetheadersAxios
            }
          )
          .then(res => {
            if (res.data.activo == false) {
              this.alerta("Dato anulado corretamente", "bien")
            } else {
              this.alerta("Dato no anulado", "error")
            }
          })
          .catch(error =>
            this.alerta("Dato no anulado", "error")
          );

      }


    },

   // muestra modal de la opcion de imprecion
    imprimirList() {

      this.dialogimprimirList = true
      this.datosImprecion.secuencia = this.Conduce.conduce.codigo
    },
// Cierre el modal de reportes de imprecion
    DDataReporteBasico(e) { this.dialogimprimirList = e },

    BlurDesde() {

      if (this.datosImprecionAll.desde.toString().length == 0) {
        this.datosImprecionAll.desde = new Date().toISOString().substr(0, 10)
      }

    },


    BlurHasta() {

      if (this.datosImprecionAll.hasta.toString().length == 0) {
        this.datosImprecionAll.hasta = new Date().toISOString().substr(0, 10)
      }

    },

    // Tomando los datos de la tabla y poniéndolos en el formulario.
    datostabla(e) {

      this.newwnuevo()
      //var DRfactura = e.facturasventa
      //DRfactura.valor = e.monto

      //if(e.hasOwnProperty('cliente')){ DRfactura.cliente=e.cliente}
      //this.RFactura.push(e.facturasventa)

      var _this = this;
    setTimeout(function(){ 
      _this.datostablaCon(e)
                }, 800); 
    },


    datostablaCon(e){

      this.disabled = true
      this.RDetFactura = []
      this.RFactura = []
      //this.moneda = e; productos.descripcion

      this.Conduce.conduce = e;
      if (e.hasOwnProperty('cliente')) { this.Conduce.cliente = e.cliente }

      var ObjRDetFactura = []
     // e.conduceDespachoDetalleList.forEach(element => { });

      /*Promise.all(e.conduceDespachoDetalleList.map(async (element) => { 
        ObjRDetFactura = ObjRDetFactura.concat(element.facturasventa.facturasventaDetalleList);
      }));*/



      this.RFactura.push(e.conduceDespachoDetalleList[0].facturasventa)
      this.selected.push(e.conduceDespachoDetalleList[0].facturasventa)

      this.Conduce.caja=e.conduceDespachoDetalleList[0].facturasventa.caja
  
     // this.RDetFactura = JSON.parse(JSON.stringify(ObjRDetFactura))

      //this.RDetFactura = JSON.parse(JSON.stringify(e.conduceDespachoDetalleList))
      Promise.all(e.conduceDespachoDetalleList.map(async (element) => { 
        // ObjRDetFactura = ObjRDetFactura.concat(element.facturasventa.facturasventaDetalleList);
        var ObjProRow = element.facturasventa.facturasventaDetalleList.filter((ee) => ee.productos.codigo == element.producto.codigo && ee.unidades.codigo == element.unidades.codigo);
        if(ObjProRow.length>0){
          var ObjPro = JSON.parse(JSON.stringify(element))
          ObjPro.facturasventaDetallePK=ObjProRow[0].facturasventaDetallePK
          ObjPro.productos=ObjProRow[0].productos
          ObjPro.cobertura= ObjProRow[0].cobertura
          ObjPro.codigocupon= ObjProRow[0].codigocupon
          ObjPro.impuesto= ObjProRow[0].impuesto
          ObjPro.recargo=ObjProRow[0].recargo
          ObjPro.valorComisionProducto=ObjProRow[0].valorComisionProducto
          ObjPro.valorRecargo=ObjProRow[0].valorRecargo
          ObjPro.valorcupon=ObjProRow[0].valorcupon
          ObjPro.valordescuento=ObjProRow[0].valordescuento
          ObjPro.valorimpuesto=ObjProRow[0].valorimpuesto
          ObjRDetFactura.push(ObjPro)
        }
      }));

      this.RDetFactura = JSON.parse(JSON.stringify(ObjRDetFactura))


      this.EstadoBtn = {
        BarraTablaDinamicaAprir: true,
        nuevo: true,
        guardar: false,
        eliminar: false,
        anular: true,
        imprimir: true,
        vercuenta: false,
        guardartemporal: false,
        restaurartemporal: false,
        importarDato: false,
        cargarProducto: false,
        ImprimirproductoInv: false,
      }



      this.acti_tb_data = false;
      this.showSlect = false

    },

   // Un método que se llama cuando se hace clic en un botón y muestra una tabla con datos.
    actualiza_tb_ver() {

      this.acti_tb_data = !this.acti_tb_data
      this.filtro()

    },

// Llamar a una API y obtener los datos de la API y almacenarlos en la variable de lista.
    async filtro() {
      await this.$axios.get(this.$hostname + this.$hName + '/ConduceDespacho/findall/', { headers: this.$store.getters.GetheadersAxios }).then(res => {
        this.list = res.data;
      });
    },

  },

  computed: {
    ActDescBtn() {
      this.windowSize = JSON.parse(JSON.stringify(this.$store.getters.GetwindowSize))

      if (this.Conduce.conduce.codigo > 0 && this.Conduce.conduce.activo == false) {
        this.EstadoBtn.anular = false
      }





    }
  }


};
</script>

<style>
</style>
