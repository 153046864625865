<template>
<v-col class="pa-0 ma-0 py-0 my-0" cols="12" md="12" sm="12">{{ GetEjecucion }}    
  <v-btn id="boton-mini" v-if="false"   @click="getver" color="blue" small dark>
                    <i style="font-size: 20px;" class="fa fa-cloud-upload"> </i>
                    Renderizar
                  </v-btn>    
  <!-- tooltip -->  
<v-col class="pa-0 ma-0 py-0 my-0 "  style="background-color: #dd4b39" cols="12" md="12" sm="12">
    <v-row no-gutters > 
      <v-col class="pa-0 ma-0 py-0 my-0 pt-3"   cols="12" md="3" sm="3"  >
        <span :class="`d-flex justify-start white--text`">
          <v-tooltip right>
            <template v-slot:activator="{ on }">
          <i v-on="on" style="font-size: 20px;" class="fa fa-info-circle px-2"> </i><b v-on="on">PDF VIEWER</b>
        </template>
           <i style="font-size: 20px;" dark dense class="fa fa-info-circle"> <span id="boton-mini"> PDF VIEWER </span> </i>
          </v-tooltip>
        </span>
        

      </v-col>
      <v-col class="pa-0 ma-0 py-0 my-0 pt-1"   cols="12" md="4" sm="5"  >
        <!-- OPCION PDF -->
        <v-col class="pa-0 ma-0 py-0 my-0  d-flex justify-center white--text "   cols="12" md="12" sm="12"  >
          <v-row no-gutters rounded>

            <v-col class="pa-0 ma-0 py-0 my-0 d-flex justify-center " style="border: 1px solid #FFFFFF;" cols="5" md="5" sm="5">  
              <span class="pa-0 ma-0 py-0 my-0 pt-1"  v-if="pdfViewer.showAllPages" >
                 <b> {{ pdfViewer.pageCount }} pag(s) </b>
             </span>
              <span v-else>
              <v-btn  dark  @click.prevent="" icon :disabled="pdfViewer.page <= 1" @click="pdfViewer.page--">
               <i style="font-size: 25px;" class="fa fa-chevron-circle-left"> </i>
               </v-btn>

            <!-- <b>{{ pdfViewer.page }} / {{ pdfViewer.pageCount }}</b>   -->
            <!-- <v-col class="pa-0 ma-0 py-0 my-0 d-flex justify-center " style="border: 1px solid #000000;"    cols="2" md="2" sm="2"  >  </v-col> -->
             <b ><input @input="inputPage" @blur="blurPage"    style="width: 70px;"  v-model.number="page"   type="number" > / {{ pdfViewer.pageCount }}</b>       
           
            <!-- <v-col class="pa-0 ma-0 py-0 my-0 d-flex justify-start " style="border: 1px solid #000000;"    cols="2" md="2" sm="2"  >       </v-col>      -->
                <v-btn  dark  @click.prevent="" icon :disabled="pdfViewer.page >= pdfViewer.pageCount" @click="pdfViewer.page++">
               <i style="font-size: 25px;" class="fa fa-chevron-circle-right"> </i>
               </v-btn>
              </span>

           </v-col>  
           <v-col class="pa-0 ma-0 py-0 my-0 d-flex justify-center " style="border: 1px solid #FFFFFF;"    cols="4" md="4" sm="4" >  
            <span >
              <v-btn  dark  @click.prevent="" icon :disabled="pdfViewer.zoom <= 1" @click="pdfViewer.zoom-=10">
               <i style="font-size: 25px;" class="fa fa-minus-circle"> </i>
               </v-btn>
            
            <!-- <v-col class="pa-0 ma-0 py-0 my-0 d-flex justify-center " style="border: 1px solid #000000;"    cols="2" md="2" sm="2"  >  </v-col> -->
             <b>{{ pdfViewer.zoom }}  %</b>       
           
            <!-- <v-col class="pa-0 ma-0 py-0 my-0 d-flex justify-start " style="border: 1px solid #000000;"    cols="2" md="2" sm="2"  >       </v-col>      -->
                <v-btn  dark  @click.prevent="" icon :disabled="pdfViewer.zoom >= 500" @click="pdfViewer.zoom+=10">
               <i style="font-size: 25px;" class="fa fa-plus-circle"> </i>
               </v-btn>
              </span>
          </v-col>
          
 <v-col v-if="false" class="pa-0 ma-0 py-0 my-0 d-flex justify-center " style="border: 1px solid #FFFFFF;"    cols="3" md="3" sm="3" >  
  <v-checkbox hide-details class="mt-0"   color="white" label="" v-model="pdfViewer.showAllPages" @change="changeCheck"
  
                                  width="50">
                                  <template v-slot:label>
                                    <strong v-if="pdfViewer.showAllPages == true"
                                      style="color:#FFFFFF; font-size:13px;">Mostrar todo: Si</strong>
                                    <strong v-if="pdfViewer.showAllPages == false"
                                      style="color:#FFFFFF; font-size:13px;">Mostrar todo: No</strong>
                                  </template>
                                </v-checkbox>
  </v-col>
          </v-row>

        </v-col>
        <!-- OPCION PDF -->

        <!-- <v-btn  dark  @click.prevent="" icon>
        <i style="font-size: 18px;" class="fa fa-cogs"> </i>
        </v-btn>

        <v-btn  dark @click.prevent="" icon>
        <i style="font-size: 18px;" class="fa fa-cogs"> </i>
      </v-btn>
   -->


        <span v-if="false" :class="`d-flex justify-center white--text`">
          <span v-if="pdfViewer.showAllPages">
        {{ pdfViewer.pageCount }} pagina(s)
      </span>

      <span v-else>
        <button :disabled="pdfViewer.page <= 1" @click="pdfViewer.page--">❮</button>

        {{ pdfViewer.page }} / {{ pdfViewer.pageCount }}

        <button :disabled="pdfViewer.page >= pdfViewer.pageCount" @click="pdfViewer.page++">❯</button>
      </span>

      <label class="right">
        <input v-model="pdfViewer.showAllPages" type="checkbox">

        Show all pages
      </label>


        </span>
      </v-col>

      <v-col class="pa-0 ma-0 py-0 my-0 d-flex justify-end" cols="12" md="4" sm="4"  >
        <v-card-actions>
            <v-btn-toggle>
<!--  v-show="medicamentosPendiente.completada==true" v-if="false"   -->
              <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                  <v-btn  v-if="false" id="boton-mini" v-on="on" @click="ImprimirPdfViewer()" color="blue" small dark>
                    <i style="font-size: 20px;" class="fa fa-print"> </i>
                    IMPRIMIR
                  </v-btn>
                </template>
                <span>IMPRIMIR: Cancela y retornar atrás</span>
              </v-tooltip>


              <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                  <v-btn id="boton-mini" v-on="on" @click="cancelarPdfViewer()" color="orange" small dark>
                    <i style="font-size: 20px;" class="fa fa-reply-all"> </i>
                    CERRAR
                  </v-btn>
                </template>
                <span>CERRAR: Cerrar y retornar atrás</span>
              </v-tooltip>

              <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                  <v-btn id="boton-mini" v-on="on" @click="descargarPdfViewer()" color="grey" small dark>
                     <i style="font-size: 20px;" class="fa fa-download"></i>
                    DESCARGAR
                  </v-btn>
                </template>
                <span>DESCARGAR: ARCHIVO</span>
              </v-tooltip>


              <v-tooltip bottom >
                  <template v-slot:activator="{ on }">
                    <v-btn   id="boton-mini" v-on="on" @click.prevent="ImprimirPdfViewer()" color="light-blue accent-2"
                      small dark>
                      <i style="font-size: 20px;" class="fa fa-print"></i><!-- fab x-small dark  -->
                      IMPRIMIR
                    </v-btn>
                  </template>
                  <span>IMPRIMIR: ARCHIVO </span>
                </v-tooltip>
              
            </v-btn-toggle>

          </v-card-actions>
        <!-- <v-btn  color="grey darken-4" fab x-small @click="cancelarCierreCaja()">
        <i style="font-size: 20px; color: white" class="fa fa-close"></i>
      </v-btn> -->
      </v-col>
      
    </v-row> 
</v-col>
  <!-- fn tooltip -->
<!-- Contenido  style="border: 1px solid #000000;"-->
<v-col class="pa-0 ma-0 py-0 my-0 pt-2 px-2"   cols="12" md="12" sm="12">

  <div  class="overflow-x-auto"
  v-bind:style="{ 'height': this.windowSize.height + 30 + 'px', 'max-height': this.windowSize.height + 30 + 'px', 'border': '1px solid #000000' }">
  <div v-show="pdfViewer.isLoading==false">
  <VuePdfEmbed 
       ref="pdfRef"
      :source="pdfViewer.pdfSource"
      :page="pdfViewer.page"
      @rendered="handleDocumentRender"
      :width="pdfViewer.zoom*20"
      />
  </div>
  
     <!-- :width="pdfViewer.zoom" :width="pdfViewer.zoom*20"   -->
  </div>
  <!-- <v-card >
    <v-form ref="formPdfViewer" @submit.prevent="" v-model="valid" lazy-validation>
      <v-row no-gutters>
        <v-col class="pa-0 ma-0 py-0 my-0"   cols="12" md="4" sm="4">
                    haaallaoaooao
        </v-col>

              <v-col class="pa-0 ma-0 py-0 my-0"   cols="12" md="12" sm="12">
                <div style="border: 1px solid #000000;">

                formulario


                </div>
              </v-col>

    </v-row>
    </v-form>
  </v-card> -->
</v-col>
<!-- fn Contenido -->

<!-- panel Alerta -->
<v-snackbar v-model="aalert.estado" :color="aalert.color" style="margin-top: 20%;" :vertical="true"
      :timeout="aalert.timeout" :right="aalert.right" :top="aalert.top">
      {{ aalert.nombre }}
      <v-btn dark text @click="aalert.estado = false">Close</v-btn>
</v-snackbar>
<!-- fn panel alerta -->
</v-col>
</template>
<script>
import { EventBus } from "@/event-bus";
import AutoNumericNuevoWithOptions from "@/components/AutoNumericNuevoWithOptions.vue";
import { currencyFormatter, FormatoFecha, HoraFormatter,RedondeoValorCantRed,currencyFormatterCant,HoraFormatter2,fechaNueva,getParamFecha} from "@/js/Funciones.js";
import VueAutonumeric from "@/components/AutoNumericNuevo";
import VuePdfEmbed from 'vue-pdf-embed/dist/vue2-pdf-embed'
export default {
  components: {
    VueAutonumeric,
    VuePdfEmbed,
    AutoNumericNuevoWithOptions
  },
  created() { 

  },
  mounted() {
 
  
  },
  beforeDestroy() {

  },

  data: () => ({ 
    arrayPropiedases: {
      'items-per-page-options': [10, 15, 20, 50],
      'items-per-page-text': 'Cantidad de Registros Por Paginas',
      showFirstLastPage: false
    },

    windowSize: { width: "auto", height: "auto" },
    page:1,
    pdfViewer:{
      archivoNombre:'',
      isLoading: true,
      page: 1,
      pageCount: 1,
      pdfSource: '',
      showAllPages: false,
      zoom:0

    },

    valid: true,
    aalert: {
      estado: false,
      color: null,
      timeout: 3000,
      top: false,
      right: false
      },
 

    
  }),

  props: { 

},
watch: {
    showAllPages() {
      this.pdfViewer.page = this.pdfViewer.showAllPages ? null : 1
    },
  },

  methods: { 
    currencyFormatter, FormatoFecha, HoraFormatter,RedondeoValorCantRed,currencyFormatterCant,HoraFormatter2,fechaNueva,getParamFecha,
 

    handleDocumentRender(e) {
      this.pdfViewer.isLoading = false
      this.pdfViewer.pageCount = this.$refs.pdfRef.pageCount
    
      var _this = this;
      setTimeout(function () {
        if(_this.pdfViewer.page!=null){
          _this.page=JSON.parse(JSON.stringify(_this.pdfViewer.page))
        }
        
      }, 300);
   
      //var arrays=[1,2,3,4,5,6,7,8,9,10]
      //this.$refs.pdfRef.pageNums=[1,2,3,4,5,6,7,8,9,10]
     // this.$refs.pdfRef.pageCount=10
      //this.$refs.pdfRef.load()
      //this.$refs.pdfRef.render()

     /*render
      renderPage*/

      /*var _this = this;
      setTimeout(function () {
        _this.pdfViewer.isLoading = false
      }, 900);*/
   
     // this.$refs.pdfRef.pageNums
    },

nuevoRegistro(){
    this.pdfViewer={
      archivoNombre:'',
      isLoading: true,
      page: 1,
      pageCount: 1,
      pdfSource: '',
      showAllPages: false,
      zoom:0
    }
},

cancelarPdfViewer(){
      this.nuevoRegistro()
      var _this=this;
    setTimeout(function(){ 
      _this.$emit("DataRetornCancelarPdfViewer", false)
     }, 300);
    },



    ImprimirPdfViewer(OBJMEDIV){
     // print resolution (number), filename (string), all pages flag (boolean)
      this.$refs.pdfRef.print(250,this.pdfViewer.archivoNombre,true);
    // this.$refs.pdfRef.print(100,this.pdfViewer.archivoNombre,true);
    },


  
    descargarPdfViewer(){
      this.$refs.pdfRef.download(this.pdfViewer.archivoNombre);
    },


    // Creando un nuevo objeto y asignándolo a la variable AnulacionFacturaSeleccionada.
    Datostabla(e) {
      // this.AnulacionFacturaSeleccionada=objjt
    },




    RedireccionFocus(e) {
      var _this = this;
      setTimeout(function () { _this.$refs[e].focus() }, 300)
    },

    RedireccionBlur(e) {
      var _this = this;
      setTimeout(function () { _this.$refs[e].blur() }, 300)
    },

    changeCheck(e){
     
      var _this = this;
        setTimeout(function () {

          if(e==true){ 
            _this.pdfViewer.page=null;
          }else{
        _this.pdfViewer.page=1;
      }

          
    
        }, 500);   
        

    },


    cargarPdf(URL_OR_BASE,ARCHIVO_NOMBRE){


      this.pdfViewer.showAllPages=false
      this.pdfViewer.page=1
      this.page=1
      this.pdfViewer.isLoading=true
      //this.$refs.pdfRef.pageNums=[1,2,3,4,5,6,7,8,9,10]
      var _this = this;
      setTimeout(function () {
          _this.pdfViewer.isLoading=true
          _this.pdfViewer.pdfSource=URL_OR_BASE;
          _this.pdfViewer.archivoNombre=ARCHIVO_NOMBRE;
          _this.pdfViewer.isLoading=false
          _this.$refs.pdfRef.render()
        }, 900);

     /*   setTimeout(function () {
          _this.$refs.RefComponentsPdfViewer.pdfViewer.isLoading=true
          _this.$refs.RefComponentsPdfViewer.pdfViewer.pdfSource=URL_OR_BASE;
          _this.$refs.RefComponentsPdfViewer.pdfViewer.archivoNombre=ARCHIVO_NOMBRE;
          _this.$refs.RefComponentsPdfViewer.pdfViewer.isLoading=false
        }, 800);*/
    },

    getver(){
      console.log('Viendp-->',this.pdfViewer)
      this.$refs.pdfRef.render()
         //this.$refs.pdfRef.renderPage()
    },

    inputPage(e){
      console.log('Ver-->Input->',e)
      console.log('Ver-->Input->',e.target.value)
      console.log('Ver-->Input->',e.target.value.length)
      console.log('Ver-->pdfViewer->',this.pdfViewer.pageCount)
if(e.target.value.length!=0){
if(parseInt(e.target.value)<=this.pdfViewer.pageCount){
  this.pdfViewer.page=parseInt(JSON.parse(JSON.stringify(e.target.value)))
}else{
  this.pdfViewer.page=parseInt(JSON.parse(JSON.stringify(this.pdfViewer.pageCount)))
  this.page=JSON.parse(JSON.stringify(this.pdfViewer.pageCount))
}
}else{
  this.pdfViewer.page=1
  
}


    },

    blurPage(){
      if(this.pdfViewer.page!=null){
        this.page=JSON.parse(JSON.stringify(this.pdfViewer.page))
      }else{
        this.page=1
        this.pdfViewer.page=1
      }

    },
    alerta(e, l) {
      if (l == "bien") {
        (this.aalert.estado = true),
          (this.aalert.color = "success"),
          (this.aalert.nombre = e);
        this.aalert.right = true
        this.aalert.top = false
        this.aalert.timeout = 3000
        // this.limpiarPuntoVentaCompleto();
      }
      if (l == "bien2") {
        (this.aalert.estado = true),
          (this.aalert.color = "success"),
          (this.aalert.nombre = e);
        this.aalert.right = true
        this.aalert.top = false
        this.aalert.timeout = 3000
        this.ejecucion = true
        this.loading(false)
        this.nuevoRegistro()

      }
      if (l == "error") {
        (this.aalert.estado = true),
          (this.aalert.color = "error"),
          (this.aalert.nombre = e);
        this.aalert.right = false
        this.aalert.top = true
        this.aalert.timeout = 120000
        this.ejecucion = true;
        this.loading(false)
      }
    },  

    loading(e) {
      EventBus.$emit("loading", e);
    },

    
  },
  computed: { 
    GetEjecucion() {
      this.windowSize = JSON.parse(JSON.stringify(this.$store.getters.GetwindowSize))
     },

},
};
</script>
<style lang="scss">
</style>