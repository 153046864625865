<template>
  <div>
    {{ ActivarBotones }}
    <table style="position: absolute" width="100%" :height="this.windowSize.height - 8"
      :max-height="this.windowSize.height - 8">
      <!-- ContenidoBase-Cheques -->
      <tr max-height="48px" height="48px">
        <ContenidoBase :titulo="'Cheques'" :descripcion="'Crear y Modificar Cheques'" :EstadoBtn="EstadoBtn"
          @abrirBarraTablaDinamica="abrirTabla" @MethodNuevo="newwnuevo" @MethodGuardar="save"
          @MethodEliminar="NotifMessageEliminar" @MethodAnular="NotifMessageAnular" @MethodImprimir="imprimirList" />
      </tr>
      <!-- ContenidoBase-Cheques -->
      <tr>
        <!-- BarraPrimaria-Cheques --------------------------------------------------------------------------------->
        <td width="109" :max-height="this.windowSize.height - 96" v-show="BarraTablaDinamicaAprir">
          <div class="overflow-y-auto" v-bind:style="{
            'max-height': this.windowSize.height - 96 + 'px',
            'max-width': '325px',
            width: '325px',
          }">
            <v-tooltip left>
              <template v-slot:activator="{ on }">
                <center>
                  <v-btn-toggle>
                    <v-btn small color="yellow accent-4" @click.prevent="actualiza_tb_ver">
                      <i style="font-size: 25px" class="fa fa-eye"> </i>
                      ver
                    </v-btn>

                    <v-btn color="teal darken-2" dark text small @click.prevent="
                      (dialogFiltros = true),
                        (ObjTemTbDinam = JSON.parse(
                          JSON.stringify(Modallfiltro)
                        ))
                    ">
                      <i style="font-size: 25px" class="fa fa-search"> </i>
                      Filtrar
                    </v-btn>
                  </v-btn-toggle>
                </center>

                <BarraDinamicaLimitePlus :PropsNombre="'Cheques'" PropsSearchNumber
                  :PropsSeachNombre="'Búsqueda por secuencia'" :TbPaginator="TbPaginator" :TbTotalItem="TbTotalItem"
                  :PropsList="ListItems" @CargarPaginacion="CargarPaginacion" @EmitDAta="datostabla" :headers="[
                    { text: 'Código', value: 'chequesPK.secuencia' },
                    /* { text: 'Nombre', value: 'nombre' },
              { text: 'Balance', value: 'balancecliente.balance' }*/
                  ]" :ContListSlot="[
                  {
                      Text: '# Cheque',
                      NomValue: 'referencia',
                      Ctsl: 1,
                      Type: 0,
                      State: true,
                    },
                  {
                      Text: 'Código',
                      NomValue: 'chequesPK',
                      Nv2: 'secuencia',
                      Ctsl: 2,
                      Type: 0,
                      State: true,
                    },
                    // {
                    //   Text: 'Secuencia',
                    //   NomValue: 'referencia',
                    //   Ctsl: 1,
                    //   Type: 0,
                    //   State: true,
                    // },
                   
                   
                    {
                      Text: 'Beneficiario',
                      NomValue: 'beneficiario',
                      Ctsl: 1,
                      Type: 0,
                      State: true,
                    },
                    {
                      Text: 'Cuenta',
                      NomValue: 'cuentaBanco',
                      Nv2: 'cuenta',
                      Ctsl: 2,
                      Type: 0,
                      State: true,
                    },
                    {
                      Text: 'Banco',
                      NomValue: 'cuentaBanco',
                      Nv2: 'banco',
                      Nv3: 'nombre',
                      Ctsl: 3,
                      Type: 0,
                      State: true,
                    },
                    {
                      Text: 'Documento',
                      NomValue: 'documentobancos',
                      Nv2: 'descripcion',
                      Ctsl: 2,
                      Type: 0,
                      State: true,
                    },
                    {
                      Text: 'concepto',
                      NomValue: 'concepto',
                      Ctsl: 1,
                      Type: 0,
                      State: true,
                    },
                    {
                      Text: 'Fecha',
                      NomValue: 'nuevaFecha',
                      Ctsl: 1,
                      Type: 1,
                      State: true,
                    },
                    {
                      Text: 'Valor',
                      NomValue: 'valor',
                      Ctsl: 1,
                      Type: 2,
                      State: true,
                    },
                    { Text: 'Activo', NomValue: 'activo', Ctsl: 1, Type: 0.2, State: true },
                    // {
                    //   Text: 'Activo',
                    //   NomValue: 'activo',
                    //   Ctsl: 1,
                    //   Type: 0.4,
                    //   State: true,

        
                    // },
                    // {
                    //   Text: 'Anulado',
                    //   NomValue: 'anulado',
                    //   Ctsl: 1,
                    //   Type: 0.4,
                    //   State: true,
                    // },
                  ]" />

                <!-- <BarraBusqueda2
              :url="
                $hostname + $hName + '/cheques/findalltipoclientes'
              "
              :nombre="'Tipo de Clientes'"
              :headers="headers"
              :slotname="'item.descripcion'"
              :emitnombre="'emitBANCheques'"
              :SeachNombre="'Búsqueda por descripcion '"
              :slotlist="slotlist"
            /> -->
              </template>
            </v-tooltip>
          </div>
        </td>
        <!-- fn BarraPrimaria-Cheques ---------------------------------------------------------------------------------->
        <!-- contenido central ------------------------------------------------------------------------------ -->
        <td :max-height="this.windowSize.height - 96">
          <v-app id="inspire" class="overflow-y-auto"
            v-bind:style="{ 'max-height': this.windowSize.height - 96 + 'px' }">
            <!-- Formulario-Cheques -->
            <div class="px-2">
              <v-card color="green lighten-5">
                <div class="px-2" style="border: 1px solid #000000">
                  <!-- Barra extra-------------------------------------->
                  <v-toolbar dense color="indigo">
                    <!-- <v-toolbar-title>Ordenes De Compras sugeridas</v-toolbar-title> -->
                    <v-spacer></v-spacer>
                    <v-card-actions>
                      <v-sheet id="scrolling-techniques-7" class="overflow-x-auto" :max-width="windowSize.width - 70"
                        color="transparent">
                        <v-btn-toggle>
                          <!-- btn 1  -->
                          <v-tooltip top v-if="
                            Cheques.proveedor != null &&
                            Cheques.proveedor.balanceproveedor != null &&
                            Cheques.chequesPK.secuencia != 0 &&
                            Cheques.anulado == false
                          ">
                            <template v-slot:activator="{ on }">
                              <v-btn id="boton-mini" v-on="on" color="success" small
                                @click.prevent="dialogEditarCheque = true">
                                <i style="font-size: 20px" class="fa fa-pencil-square-o">
                                </i>
                              </v-btn>
                            </template>
                            <span>Editar Cheque </span>
                          </v-tooltip>
                          <!-- fn btn 1 -->

                          <!-- btn 2 -->
                          <v-tooltip top v-if="
                            Cheques.proveedor != null &&
                            Cheques.proveedor.balanceproveedor != null &&
                            Cheques.proveedor.balanceproveedor.balance > 0 &&
                            listFactVent.length > 0
                          ">
                            <template v-slot:activator="{ on }">
                              <v-btn id="boton-mini" v-on="on" color="yellow accent-4" small
                                @click.prevent="dialogVerFactura = true, MethoEjecutarCompVerFact()">
                                <i style="font-size: 20px" class="fa fa-eye"></i><!-- fab x-small dark  -->
                              </v-btn>
                            </template>
                            <span>Ver Facturas</span>
                          </v-tooltip>
                          <!-- fn btn 2 -->

                          <!--  btn 3 -->
                          <v-tooltip top v-if="
                            Cheques.chequesPK.secuencia != 0 &&
                            Cheques.anulado == false
                          ">
                            <template v-slot:activator="{ on }">
                              <v-btn id="boton-mini" v-on="on" color="cyan" small @click.prevent="ReimprimirCheq">
                                <i style="font-size: 20px" class="fa fa-repeat">
                                </i>
                              </v-btn>
                            </template>
                            <span>Re-imprimir </span>
                          </v-tooltip>
                          <!-- fn btn 3 -->

                          <!--  btn 4 -->
                          <v-tooltip top>
                            <template v-slot:activator="{ on }">
                              <v-btn id="boton-mini" v-on="on" color="light-blue lighten-3" small @click.prevent="
                                dialogReimprimirChequesRango = true
                              ">
                                <i style="font-size: 20px" class="fa fa-random">
                                </i>
                              </v-btn>
                            </template>
                            <span>Re-imprimir rongo de cheques</span>
                          </v-tooltip>
                          <!-- fn btn 4 -->

                          <!--  btn 5 -->
                          <v-tooltip top v-if="
                            Cheques.chequesPK.secuencia != 0 &&
                            Cheques.anulado == true
                          ">
                            <template v-slot:activator="{ on }">
                              <v-btn id="boton-mini" v-on="on" color="pink lighten-3" small
                                @click.prevent="RehacerCheque">
                                <i style="font-size: 20px" class="fa fa-refresh">
                                </i>
                              </v-btn>
                            </template>
                            <span>Re-hacer cheque anulado</span>
                          </v-tooltip>
                          <!-- fn btn 5 -->
                        </v-btn-toggle>
                      </v-sheet>
                    </v-card-actions>
                  </v-toolbar>
                  <!-- fn Barra extra-------------------------------------->

                  <v-form ref="formCheques" v-model="valid" lazy-validation>
                    <v-row>
                      <!-- columna 1 ----------------------------------------------->
                      <v-col cols="12" md="8" sm="8">
                        <!-- <v-img style="height: 60px; width:110px; border-radius: 2px;" :src="getImgUrl('imagenMM.gif')"></v-img> -->

                        <v-row>
                          <v-col cols="12" md="6" sm="6">
                            <v-avatar size="65" tile>
                              <v-img :src="getImgUrl('imagenMM.gif')"></v-img>
                            </v-avatar>
                          </v-col>
                          <v-col cols="12" md="6" sm="6" v-if="
                            Cheques.chequesPK.secuencia != 0 &&
                            Cheques.anulado == true
                          ">
                            <span :class="`d-flex justify-start pink--text`">
                              <b style="font-size: 40px"> Anulado </b></span>
                          </v-col>
                        </v-row>
                        <!-------------------------------------------------------- Cuenta  -->
                        <!--@blur="SelctCbOperacion" @focus="CargarROperacion"  -->

                        <v-combobox v-model="Cheques.cuentaBanco" label="Cuenta de banco:" required outlined dense
                          :rules="[$rules.required]" autocomplete="off" :items="RCuentaBanco" item-text="cuenta"
                          @focus="cargarCuentaBanco" @change="findDocumentoBanco" @blur="SelctCbCuentaBanco"
                          id="formdisabledInput" :disabled="editando">
                          <template slot="item" slot-scope="item">
                            {{ item.item.cuenta }} -
                            {{ item.item.banco.nombre }}
                          </template>

                          <template v-slot:selection="{ attrs, item, parent, selected }">
                          <span class="fontSize13 colorNombre" v-if="item!=null"> <b>{{ item.cuenta}}</b> - {{item.banco.nombre}}</span>
                          </template>

                          <template v-slot:prepend>
                            <i style="font-size: 20px" class="fa fa-sticky-note-o"></i>
                          </template>
                        </v-combobox>
                        <!-------------------------------------------------------- beneficio -->
                        <v-combobox autocomplete="off" dense outlined label="* Beneficiario:" :items="Rproveedor"
                          item-text="nombre" :rules="[$rules.required]" v-model="Cheques.proveedor"
                          @focus="cargarProveedor" @keyup="CbFilProveedor" @blur="SelctCbProveedor"
                          v-on:blur="getCrear"
                          @change="ChangeBeneficiario"
                          id="formdisabledInput" :disabled="editando"
                          >
                          <template v-slot:prepend>
                            <i style="font-size: 20px" class="fa fa-user-circle"></i>
                          </template>
                        </v-combobox>
                      </v-col>
                      <!-- fn columna 1 ------------------------------------------->

                      <!-- columna 2  --------------------------------------------->
                      <v-col cols="12" md="4" sm="4">
                        <!-------------------------------------------------------- no Cheques.secuenciaSecundaria-->

                        <v-text-field v-if="editando==false" ref="RefTextFieldNocheque" label="No. :" dense outlined v-model.number="Cheques.referencia"
                          @keyup="KeyUpCantidad"
                          type="number"
                          autocomplete="off" @blur="convertirMayusculaReferencia" :disabled="
                            Cheques.cuentaBanco != null &&
                            Cheques.cuentaBanco.chequeimpreso"
                          id="formdisabledInput" 
                          >
                          
                          <template v-slot:prepend>
                            <i style="font-size: 20px" class="fa fa-slack"></i>
                          </template>
                        </v-text-field>
                          <!-- Cheques.secuenciaSecundaria -->
                        <v-text-field v-if="editando==true" ref="RefTextFieldNocheque" label="No. :" dense outlined v-model.number="Cheques.referencia"
                          @keyup="KeyUpCantidad"
                          type="number"
                          autocomplete="off" @blur="convertirMayusculaReferencia" :disabled="true"
                          id="formdisabledInput" 
                          >
                          <template v-slot:prepend>
                            <i style="font-size: 20px" class="fa fa-slack"></i>
                          </template>
                        </v-text-field>


                        <!-- -----------------------------  fecha :disabled="editando" -->
                        <v-text-field dense outlined type="date" label="Fecha:" v-model="Cheques.fecha"
                        id="formdisabledInput" fecha :disabled="disabledEditarFecha" >
                          <template v-slot:prepend>
                            <i style="font-size: 20px" class="fa fa-calendar"></i>
                          </template>
                        </v-text-field>

                        <!-------------------------------------------------- Valor-->
                        <VueAutonumeric label="Valor:" dense outlined autocomplete="off" v-model.number="Cheques.valor"
                          :rules="[$rules.required]" @input="numerosLetras" @blur="getCrear"
                          id="formdisabledInput" :disabled="editando">
                          <template v-slot:prepend>
                            <i style="font-size: 20px" class="fa fa-money"></i>
                          </template>
                        </VueAutonumeric>
                      </v-col>
                      <!-- fn columna 2  ------------------------------------------>

                      <!-- columna 3 ----------------------------------------------->
                      <v-col cols="12" md="12" sm="12" style="margin-top: -26px">
                        <!-------------------------------------------------------- Letras -->
                        <v-text-field label="" dense outlined v-model="letras" disabled>
                          <template v-slot:prepend>
                            <i style="font-size: 20px" class="fa fa-info-circle"></i>
                          </template>
                        </v-text-field>

                        <!-- ----------------------------- comentario -->

                        <v-textarea autocomplete="off" label="Concepto:" rows="2" dense outlined
                          v-model="Cheques.concepto" @blur="getCrear"
                          id="formdisabledInput" :disabled="editando">
                          <template v-slot:prepend>
                            <i style="font-size: 20px" class="fa fa-commenting"></i>
                          </template>
                        </v-textarea>
                      </v-col>
                      <!-- fn columna 3  --------------------------------->
                   
                      <v-col cols="12" md="12" sm="12" v-if="this.$store.getters.GetConfiguracionGeneral.config.multimoneda==true"  style="margin-top: -26px">
                      <v-row >
                        <v-col  cols="12" md="6" sm="6" >
                          <v-combobox autocomplete="off" dense outlined label="Monedas" :items="Rmoneda" item-text="descripcion"
                    :rules="[$rules.required]" v-model="Cheques.moneda" style="font-size:13px;" @focus="cargarMoneda" @blur="SelctCbMoneda"
                    @change="ChangeMonedaTasa">

                    <template v-slot:prepend>
                    <i style="font-size: 20px;" class="fa fa-money"></i>
                    </template>

                    <template v-slot:selection="{ attrs, item, parent, selected }">
                    <span id="ImputColorDisable">
                    {{ item.descripcion }} ({{ item.nombrecorto }})
                    </span>

                    </template>


                    <template slot="item" slot-scope="item">
                    <!--<v-col cols="12" md="12" sm="12"> <v-row no-gutters></v-row> </v-col>-->
                    <v-col style="font-size: 13px;" class="pa-0 ma-0 py-0 my-0" cols="12" md="12" sm="12">
                    <p class="pa-0 ma-0 py-0 my-0"><b>{{ item.item.descripcion }} ({{ item.item.nombrecorto }})</b>
                    </p>
                    </v-col>
                    </template>
                    </v-combobox>
                        </v-col>
                       <v-col  cols="12" md="6" sm="6">
                        <VueAutonumeric v-if="Cheques.moneda != null" id="formdisabledInput" label="Tasa:" dense outlined
                        v-model.number="Cheques.tasa">
                        <template v-slot:prepend>
                        <i style="font-size: 20px;" class="fa fa-usd"></i>
                        </template>
                        </VueAutonumeric>
                      </v-col> 
                      </v-row>
                      </v-col>
                      
                       

                    </v-row>
                  </v-form>
                </div>
              </v-card>

              <v-row>
                <v-col cols="12" md="12" sm="12">
                  <CuentasContables @CuentasContables="CCuentasContables" :RLisCuenta="RLisCuenta"
                    :Total="TotalCuenta" />
                </v-col>
              </v-row>
            </div>
            <!-- fn Formulario-Cheques -->
          </v-app>
        </td>
        <!-- fn contenido central ------------------------------------------------------------------------------------->
        <!-- BarraSecundaria ------------------------------------------------------------------------------------------>
        <td width="109" :max-height="this.windowSize.height - 96" v-show="BarraSecundariaAprir">
          <div class="overflow-y-auto" v-bind:style="{'max-height': this.windowSize.height - 96 + 'px',
            'max-width': '325px', 'width': '325px'}">
            <!--         
        <BarraBusqueda2
              :url="$hostname + $hName + '/services/caja/findAll'"
              :nombre="'Cajas'"
              :headers="headers"
              :slotname="'item.descripcion'"
              :emitnombre="'emitCaja'"
              :slotlist="slotlist"
              :SeachNombre="'Búsqueda por descripción caja'"
            /> -->
          </div>
        </td>
        <!-- fn BarraSecundaria --------------------------------------------------------------------------------------->
      </tr>
    </table>
    <!-- Modales -------------------------------------------------------------------------------------------------->

    <!-------------------------------------------------------- tb-data todo general -->
    <v-dialog label="myAlert" v-model="acti_tb_data" persistent transition="dialog-bottom-transition" fullscreen
      hide-overlay>
      <v-card>
        <v-toolbar dense flat color="#BDBDBD">
          <i style="font-size: 20px" class="fa fa-file-pdf-o"> </i>
          <v-toolbar-title class="font-weight-light"> REPORTE </v-toolbar-title>
          <i style="font-size: 20px" class="fa fa-file-pdf-o"> </i>

          <v-spacer></v-spacer>
          <v-tooltip top>
            <template v-slot:activator="{ on }">
              <v-btn v-on="on" dark small color="deep-purple accent-2" @click.prevent="OpenImprimirList()">
                <i style="font-size: 25px" class="fa fa-print"> </i>
                Listado
              </v-btn>
            </template>
            <span>Imprimir Listado</span>
          </v-tooltip>
          <v-btn color="grey darken-4" fab small @click="actualiza_tb_ver">
            <i style="font-size: 20px; color: #dd4b39" class="fa fa-close"></i>
          </v-btn>
        </v-toolbar>

        <v-card-text>
          <v-card>
            <v-card-title>
              <v-text-field v-model="Modallfiltro.codigo" @keyup.enter="CargarListTb" label="Búsqueda por secuencia"
                single-line hide-details type="number">
                <template v-slot:prepend-inner>
                  <i style="font-size: 35px" class="fa fa-search"></i>
                </template>
                <template v-slot:append-outer>
                  <v-btn color="teal darken-2" dark small @click.prevent="
                    (dialogFiltros = true),
                      (ObjTemTbDinam = JSON.parse(
                        JSON.stringify(Modallfiltro)
                      ))
                  ">
                    <i style="font-size: 25px" class="fa fa-search"> </i>
                    Filtros
                  </v-btn>
                </template>
              </v-text-field>
            </v-card-title>
            <v-data-table class="TbSize13" :headers="[
              {
                text: 'Secuencia',
                value: 'chequesPK.secuencia',
                sortable: false,
                class: ['no-gutters black--text'],
              },
              {
                text: 'Beneficiario',
                value: 'beneficiario',
                sortable: false,
                class: ['no-gutters black--text'],
              },
              {
                text: 'Cuenta',
                value: 'cuentaBanco.cuenta',
                sortable: false,
                class: ['no-gutters black--text'],
              },
              {
                text: 'Banco',
                value: 'cuentaBanco.banco.nombre',
                sortable: false,
                class: ['no-gutters black--text'],
              },
              {
                text: 'Documento',
                value: 'documentobancos.descripcion',
                sortable: false,
                class: ['no-gutters black--text'],
              },
              {
                text: '# Refernecia',
                value: 'referencia',
                sortable: true,
                class: ['no-gutters black--text'],
              },
              {
                text: 'moneda',
                value: 'moneda.descripcion',
                sortable: false,
                class: ['no-gutters black--text'],
              },
              {
                text: 'Fecha',
                value: 'nuevaFecha',
                sortable: false,
                class: ['no-gutters black--text'],
              },
              {
                text: 'Valor',
                value: 'valor',
                sortable: false,
                class: ['no-gutters black--text'],
              },
              {
                text: 'Concepto',
                value: 'concepto',
                sortable: false,
                class: ['no-gutters black--text'],
              },
              {
                text: 'Estado',
                value: 'activo',
                sortable: false,
                class: ['no-gutters black--text'],
              },
            ]" dense @pagination="cargarPaginacionAll" :items-per-page="TbPaginator.itemsPerPage"
              :page="TbPaginator.page" :pageCount="TbPaginator.pageCount" :items="ListItems"
              :search="Modallfiltro.codigo" @click:row="datostabla" :server-items-length="TbTotalItem"
              :footer-props="arrayPropiedases">
              <template v-slot:item.fecha="props">
                <span>{{ FormatoFecha(props.item.fecha) }}</span>
              </template>

              <template v-slot:item.valor="props">
                <span>{{ currencyFormatter(props.item.valor) }}</span>
              </template>

              <template v-slot:item.activo="props">
                <span v-if="props.item.activo == true">Activo</span>
                <span v-if="props.item.anulado == true">Inactivo</span>
              </template>
            </v-data-table>
          </v-card>
        </v-card-text>
      </v-card>
    </v-dialog>
    <!-- ------------------------------------------------------ fn tb-data todo general -->

    <!-- ------------------------------------------------------ Modal-notificacion general -->
    <v-dialog label="myAlert" v-model="notif.estado" persistent max-width="350" transition="fab-transition">
      <template justify="center" v-slot:activator="{ on }"></template>

      <v-card>
        <v-card-title :class="`d-flex justify-center`">
          <h4>
            <i style="font-size: 28px" class="fa fa-warning"></i>
            CONFIRMACION
            <i style="font-size: 28px" class="fa fa-warning"></i>
          </h4>
        </v-card-title>
        <hr />

        <v-card-text>
          <h5>{{ notif.nombre }}</h5>
        </v-card-text>

        <hr />
        <v-card-actions :class="`d-flex justify-center`">
          <v-btn v-if="notif.Exec == 'eliminarcheques'" @click.prevent="eliminar(), (notif.estado = !notif.estado)"
            small>
            <i style="font-size: 20px" class="fa fa-check"></i> Si
          </v-btn>

          <v-btn v-if="notif.Exec == 'anularcheques'" @click.prevent="anular(), (notif.estado = !notif.estado)" small>
            <i style="font-size: 20px" class="fa fa-check"></i> Si
          </v-btn>

          <v-btn v-if="notif.Exec == 'Imprimcheques'" @click.prevent="
            ImprimRehacerCheque(), (notif.estado = !notif.estado)
          " small>
            <i style="font-size: 20px" class="fa fa-check"></i> Si
          </v-btn>

          <v-btn v-if="notif.Exec == 'imprimirAutoCheque'" @click.prevent="imprimirAutomCheque(), (notif.estado = !notif.estado)" small>
          <i style="font-size: 20px" class="fa fa-print"></i> Si
         </v-btn>

          <v-btn @click.prevent="notif.estado = !notif.estado" small>
            <i style="font-size: 20px" class="fa fa-ban"></i> No
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- ------------------------------------------------------  fn Modal-notificacion general  -->

    <!------------otras funciones---------------->
    <v-snackbar v-model="aalert.estado" :color="aalert.color" style="margin-top: 20%" :vertical="true" :timeout="120000"
      top="top">
      {{ aalert.nombre }}
      <v-btn dark text @click="aalert.estado = false">Close</v-btn>
    </v-snackbar>

    <!-------------------------------------------------------- Modal- agregar cuenta-->
    <!-- <v-dialog label="Modal agregar cuenta" v-model="acti_Modal_cuenta" persistent max-width="90%" max-height="80%">
<CuentasContables
@CuentasContables="CCuentasContables"
:RLisCuenta="RLisCuenta"
:objeTem="objeTem"
:Total="TotalCuenta"
/>
</v-dialog> -->
    <!-- ------------------------------------------------------ fn Modal agregar cuenta-->

    <!-- ------------------------------------------------------ Modal-Reporte Basico -->
    <v-dialog transition="fab-transition" label="Modal Reporte Basico" v-model="dialogimprimirList" persistent
      max-width="80%" max-height="50%">
      <ModalReporteBasico @DataReporteBasico="DDataReporteBasico" :Datos="datosImprecion" />
    </v-dialog>
    <!-- ------------------------------------------------------ fn Modal-Reporte Basico -->

    <!-- ------------------------------------------------------ Modal-Reportes-ModalReporteFiltros -->
    <v-dialog transition="fab-transition" label="Modal agregar cuenta" v-model="dialogimprimirListFilt" persistent
      max-width="80%" max-height="50%">
      <!----------------------------------------------------------------- Cuerpo Reporte -->
      <v-card color="grey lighten-3">
        <v-toolbar flat color="#BDBDBD" dense style="border: 1px solid #000000">
          <v-avatar tile size="45">
            <img src="@/assets/iconos_micromarket/custom-reports.png" alt="Reporte" />
          </v-avatar>
          <span class="font-weight-light" style="font-size: 20px">
            <b>REPORTE CHEQUES </b>
          </span>

          <v-spacer></v-spacer>
          <v-btn color="red" fab small @click.prevent="dialogimprimirListFilt = false">
            <i style="font-size: 20px; color: white" class="fa fa-close"></i>
          </v-btn>
        </v-toolbar>

        <hr />

        <v-container style="border: 1px solid #000000">
          <v-form ref="formImpList" @submit.prevent="" v-model="valid" lazy-validation>
            <v-row class="pa-0 ma-0">

              <v-col cols="12" md="12" sm="12">
                <!---------------------------------------------- documento :rules="[$rules.required]"-->
                <v-combobox autocomplete="off" dense outlined label="Documentos:" :items="RDocumento"
                  item-text="descripcion"  v-model="datosImprecionAll.documentos"
                  @blur="SelctCbReportDocumento" @focus="cargarDocumento">
                  <template v-slot:prepend>
                    <i style="font-size: 20px" class="fa fa-book"></i>
                  </template>
                </v-combobox>

              </v-col>


              <v-col cols="12" md="6" sm="6">
                <v-text-field dense outlined type="date" label="Desde*" v-model="datosImprecionAll.desde"
                  :rules="[$rules.required]" @blur="BlurDesde">
                  <template v-slot:prepend>
                    <i style="font-size: 20px" class="fa fa-calendar"></i>
                  </template>
                </v-text-field>
              </v-col>

              <v-col cols="12" md="6" sm="6">
                <!-------------------------------------------------- Hasta -->
                <v-text-field dense outlined type="date" label="Hasta*" v-model="datosImprecionAll.hasta"
                  :rules="[$rules.required]" @blur="BlurHasta">
                  <template v-slot:prepend>
                    <i style="font-size: 20px" class="fa fa-calendar"></i>
                  </template>
                </v-text-field>
              </v-col>

              <v-col cols="12" md="12" sm="12" class="pa-0 ma-0 py-0 my-0 px-2 d-flex justify-center">
                <v-radio-group  hide-details class="py-0" v-model="datosImprecionAll.opcion" row>     
                  <!-- <v-radio label="RUTINARIOS" color="green" :value="1"></v-radio>
                  <v-radio label="NO RUTINARIOS" color="green" :value="2"></v-radio>-->
                  <v-radio label="TODOS" color="green" :value="1"></v-radio>
                  <v-radio label="ACTIVO" color="green" :value="2"></v-radio>                                       
                  <v-radio label="ANULO" color="green" :value="3"></v-radio>  
                  <!-- <v-radio label="CANCELADO" color="green" :value="5"></v-radio> -->
                  
</v-radio-group>
</v-col>



            </v-row>
          </v-form>
        </v-container>

        <hr />

        <ModalReporteFiltros @DataReporteBasico="DDataReporteBasico" ref="ModRepFilt" :Datos="datosImprecionAll" />
      </v-card>
      <!-------------------------------------------------------------------------------- Fn Cuerpo Reporte -->
    </v-dialog>
    <!-- ------------------------------------------------------ fn Modal-Reportes-ModalReporteFiltros -->

    <!-- ------------------------------------------------------ Modal-Filtro tb-datos -->
    <v-dialog transition="fab-transition" label="Modal agregar factura" v-model="dialogFiltros" persistent
      max-width="90%" max-height="80%">
      <v-card>
        <v-toolbar flat color="indigo" dense>
          <i style="font-size: 20px" class=""> </i>
          <v-toolbar-title style="color: white" class="font-weight-light">
            <b>FILTROS</b>
          </v-toolbar-title>
          <i style="font-size: 20px" class=""> </i>

          <v-spacer></v-spacer>
          <v-btn color="grey darken-4" fab small @click="
            (dialogFiltros = false),
              (Modallfiltro = JSON.parse(JSON.stringify(ObjTemTbDinam)))
          ">
            <i style="font-size: 20px; color: white" class="fa fa-close"></i>
          </v-btn>
        </v-toolbar>

        <v-card-text>
          <br />

          <v-row>
            <!-- -----------------------------  columna-1 -->
            <v-col cols="6" md="6">
              <v-combobox v-model="Modallfiltro.cuentaBanco" label="Cuenta de banco:" required outlined dense
                :rules="[$rules.required]" autocomplete="off" :items="RCuentaBanco" item-text="cuenta"
                @focus="cargarCuentaBanco" @blur="SelctCbCuentaBancoFiltro">
                <template slot="item" slot-scope="item">
                  {{ item.item.cuenta }} - {{ item.item.banco.nombre }}
                </template>
                <template v-slot:selection="{ attrs, item, parent, selected }">
                          <span class="fontSize13 colorNombre" v-if="item!=null"> <b>{{ item.cuenta}}</b> - {{item.banco.nombre}}</span>
                          </template>
                <template v-slot:prepend>
                  <i style="font-size: 20px" class="fa fa-sticky-note-o"></i>
                </template>
              </v-combobox>

              <!----------------------------------------------  Desde -->
              <v-text-field dense outlined type="date" label="Desde*" v-model="Modallfiltro.Desde">
                <template v-slot:prepend>
                  <i style="font-size: 20px" class="fa fa-calendar"></i>
                </template>
              </v-text-field>

              <v-text-field label="NO.Cheque :" dense outlined v-model.number="Modallfiltro.codigo" autocomplete="off"
                type="number" :rules="[$rules.required]">
                <template v-slot:prepend>
                  <i style="font-size: 20px" class="fa fa-slack"></i>
                </template>
              </v-text-field>
            </v-col>
            <!-- ----------------------------- fn columna-1 -->

            <!-- -----------------------------  columna-2 -->
            <v-col cols="6" md="6">
              <v-combobox autocomplete="off" dense outlined label="* Beneficiario:" :items="Rproveedor"
                item-text="nombre" :rules="[$rules.required]" v-model="Modallfiltro.proveedor" @focus="cargarProveedor"
                @keyup="CbFilProveedor">
                <template v-slot:prepend>
                  <i style="font-size: 20px" class="fa fa-user-circle"></i>
                </template>
              </v-combobox>

              <!-------------------------------------------------- Hasta -->
              <v-text-field dense outlined type="date" label="Hasta*" v-model="Modallfiltro.Hasta">
                <template v-slot:prepend>
                  <i style="font-size: 20px" class="fa fa-calendar"></i>
                </template>
              </v-text-field>
            </v-col>
            <!-- ----------------------------- fn columna-2 -->
          </v-row>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn-toggle rounded class="" dense>
            <v-btn dark color="#90A4AE" @click.prevent="Limpiarfiltro">
              <i style="font-size: 28px" class="fa fa-file-o"> </i>
              Limpiar filtro
            </v-btn>

            <v-btn dark color="blue lighten-1" @click.prevent="BuscarFiltros">
              <i style="font-size: 28px" class="fa fa-search"> </i>
              Buscar
            </v-btn>
          </v-btn-toggle>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- ------------------------------------------------------ fn Modal-Filtro tb-datos -->

    <!-- ------------------------------------------------------ Modal-Reimprimir rango cheque -->
    <v-dialog transition="fab-transition" label="Modal agregar factura" v-model="dialogReimprimirChequesRango"
      persistent max-width="90%" max-height="80%">
      <v-card>
        <v-toolbar flat color="indigo" dense>
          <i style="font-size: 20px" class=""> </i>
          <v-toolbar-title style="color: white" class="font-weight-light">
            <b>Reimprimir Cheques Rango</b>
          </v-toolbar-title>
          <i style="font-size: 20px" class=""> </i>

          <v-spacer></v-spacer>
          <v-btn color="grey darken-4" fab small @click="dialogReimprimirChequesRango = false">
            <i style="font-size: 20px; color: white" class="fa fa-close"></i>
          </v-btn>
        </v-toolbar>

        <v-card-text>
          <br />

          <v-row>
            <v-col cols="12" md="12" sm="12">
              <v-combobox v-model="ModallReimprCheqRan.cuentaBanco" label="Cuenta de banco:" required outlined dense
                :rules="[$rules.required]" autocomplete="off" :items="RCuentaBanco" item-text="cuenta"
                @focus="cargarCuentaBanco" @blur="SelctCbCuentaBancoReimprimir">
                <template v-slot:prepend>
                  <i style="font-size: 20px" class="fa fa-sticky-note-o"></i>
                </template>
              </v-combobox>
            </v-col>

            <!-- -----------------------------  columna-1 -->
            <v-col cols="6" md="6">
              <v-text-field label="Secuencia Inicial :" dense outlined v-model.number="ModallReimprCheqRan.Secini"
                autocomplete="off" type="number" :rules="[$rules.required]">
                <template v-slot:prepend>
                  <i style="font-size: 20px" class="fa fa-slack"></i>
                </template>
              </v-text-field>
            </v-col>
            <!-- ----------------------------- fn columna-1 -->

            <!-- -----------------------------  columna-2 -->
            <v-col cols="6" md="6">
              <v-text-field label="Secuencia Final:" dense outlined v-model.number="ModallReimprCheqRan.Secfin"
                autocomplete="off" type="number" :rules="[$rules.required]">
                <template v-slot:prepend>
                  <i style="font-size: 20px" class="fa fa-slack"></i>
                </template>
              </v-text-field>
            </v-col>
            <!-- ----------------------------- fn columna-2 -->
          </v-row>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn-toggle rounded class="" dense>
            <v-btn color="blue lighten-1" @click.prevent="ReimprimirChequesRango">
              <i style="font-size: 28px" class="fa fa-print"> </i>
              Imprimir
            </v-btn>
          </v-btn-toggle>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- ------------------------------------------------------ fn Modal-Reimprimir rango cheque -->

    <!-- ------------------------------------------------------  Modal-Ver Facturas cheque -->
    <v-dialog v-model="dialogVerFactura" persistent fullscreen hide-overlay transition="dialog-bottom-transition">
      <v-card>
        <ComponentsVerFactura @CerrarModal="cerrarModalVerFacturas" :Titulo="'FACTURAS A PAGAR'"
          :detalle="'muestra  y  asigna las facturas a pagar'" :ObjSuplidor="datosProveedor"
          :listArray="JSON.parse(JSON.stringify(listFactVent))" :abonoTotal="Cheques.valor" :ejecutar="modificar"
          :editando="Cheques.chequesPK.secuencia > 0" 
          @ActualizarCompVerFact="actualizarCompVerFact"
          ref="CompVerFactt" 
          />
      </v-card>
    </v-dialog>
    <!-- ------------------------------------------------------ fn Modal-Ver Facturas cheque  -->

    <!-- ------------------------------------------------------  Modal-Editar cheque -->
    <v-dialog v-model="dialogEditarCheque" persistent fullscreen hide-overlay transition="dialog-bottom-transition">
      <v-card>
        <ComponentsVerFactura @CerrarModal="cerrarModalEditarFactura" :Titulo="'EDITAR CHEQUE'"
          :detalle="'Carga facturas Para editar los cheques'" :ObjSuplidor="datosProveedor"
          :listArray="JSON.parse(JSON.stringify(listFactModificar))" :abonoTotal="Cheques.valor" :ejecutar="ejecutarCompVerFactEditTran"
          :editando="Cheques.chequesPK.secuencia == 0"
          @ActualizarCompVerFact="actualizarCompVerFactEditTran"
          
          />
      </v-card>
    </v-dialog>
    <!-- ------------------------------------------------------ fn Modal-Editar cheque  -->

    <!-- fn Modales ----------------------------------------------------------------------------------------------->
  </div>
</template>

<script>
import numeral from 'numeral'
import { EventBus } from "../../event-bus";
import VueAutonumeric from "../../components/AutoNumericNuevo";
import CuentasContables from "@/Bancos/UtilidadComponents/CuentasContables";
import ContenidoBase from "../../components/ContenidoBase";
import { NumeroALetras } from "@/js/NumeroALetras.js";
import {
  currencyFormatter,
  FormatoFecha,
  HoraFormatter,
  fechaNueva
} from "@/js/Funciones.js";
import ModalReporteBasico from "../../components/ModalReporteBasico";
import ModalReporteFiltros from "../../components/ModalReporteFiltros2";
import BarraDinamicaLimite from "../../components/BarraDinamicaLimite";
import ComponentsVerFactura from "@/Bancos/UtilidadComponents/FacturasPendiente";
import ReporteAuxiliarCuentasPorCobrarVue from "../../Reportes/cxc/ReporteAuxiliarCuentasPorCobrar.vue";
import BarraDinamicaLimitePlus from "../../components/BarraDinamicaLimitePlus";

export default {
  components: {
    VueAutonumeric,
    CuentasContables,
    ContenidoBase,
    ModalReporteBasico,
    ModalReporteFiltros,
    BarraDinamicaLimite,
    ComponentsVerFactura,
    BarraDinamicaLimitePlus,
  },
  created() {
    var json = {
      titulo: "Cheques",
      descripcion: "Crear y Modificar Cheques",
      save: true,
      eliminar: false,
      anular: false,
      nuevo: true,
      imprimir: true,
      vercuenta: false,
      accion: [
        "BANChequesNew",
        "BANChequesSave",
        "BANChequesRemove",
        "BANChequesAnular",
        "BANChequesPrintList",
        "BANChequesVercuenta",
      ],
    };
    EventBus.$emit("anularBotonesAccion", json);
  },
  // mounted-carga de inicio
  mounted() {
    EventBus.$on("abrirMenuizquierdo", this.abrirTabla);
    // EventBus.$on("abrirMenuizquierdo", this.abrirTabla2);
    // EventBus.$on("BANChequesNew", this.new);
    // EventBus.$on("BANChequesSave", this.save);
    // EventBus.$on("BANChequesRemove", this.remove);
    // EventBus.$on("BANChequesAnular", this.remove);
    // EventBus.$on("BANChequesPrintList", this.imprimirList);
    EventBus.$on("emitBANCheques", this.datostabla);
    //  EventBus.$on("BANChequesVercuenta", this.vercuenta);
    this.cargarTodo();
    this.getMonedaBase()
    EventBus.$on("onResize", this.onResize);

  },
// fn mounted-carga de inicio
  data() {
    return {
      editando:false,
      disabledEditarFecha:false,
      dialogimprimirListFilt: false,
      RDocumento:[],
      datosImprecionAll: {
        nombre: "CHEQUES",
        UrlImprimir: "/cheques/imprimirList",
        UrlExel: "/cheques/excelList",
        UrlCorreo: "/cheques/CorreoList",
        desde: fechaNueva(),
        hasta: fechaNueva(),
        cuentaBanco: null,
        documentos: null,
        opcion:1
      },

      dialogimprimirList: false,
      datosImprecion: {
        nombre: "CHEQUES",
        UrlImprimir: "/cheques/Imprimir",
        UrlExel: "/cheques/Excel",
        UrlCorreo: "/cheques/Correo",
        codigo: 0,
        documento: 0,
        letras: "",
      },
      dialogFiltros: false,

      Modallfiltro: {
        codigo: null,
        Desde: null,
        Hasta: null,
        proveedor: null,
        cuentaBanco: null,
      },

      ObjTemTbDinam: null,
      ListItems: [],
      TbTotalItem: 0,
      TbPaginator: {
        page: 1,
        itemsPerPage: 5,
        pageStart: 1,
        pageStop: 15,
        pageCount: 10,
        itemsLength: 5,
      },
      arrayPropiedases: {
        'items-per-page-options': [10, 15, 20, 50],
        'items-per-page-text': 'Cantidad de Registros Por Paginas',
        showFirstLastPage: false
      },


      dialogReimprimirChequesRango: false,
      ModallReimprCheqRan: {
        cuentaBanco: null,
        Secini: null,
        Secfin: null,
      },
      datosProveedor: { nombre: "", codigo: 0 },
      dialogVerFactura: false,
      dialogEditarCheque: false,
      listFactModificar: [],
      listFactVent: [
        {
          fecha: 1603857600000,
          ncf: "B0400002362",
          origen: 0,
          facturasventaPK: { secuencia: 21 },
          abono: 0,
          Retitbis: 0,
          pendiente: 0,
          descuento: 0,
          Retencion: 0,
          isr: 0,
          notad: 0,
          valor: 0,
          valortotal: 0,
        },
      ],
      modificar: true,
      ejecutarCompVerFactEditTran:false,
      TotalesCompVerFacturas:{
        totalAbono:0,
  totalAb:0,
  abono:0,
  descuento:0,
  retencion:0,
  isr:0,
  totalPendiente:0,
  TotalAPagar:0,
  original:0,
  pendiente:0,       
  notadebito:0,
  valorpago:0,
  balance:0
      },
      windowSize: { width: "auto", height: "auto" },
      objeTem: null,
      letras: "",

      Rcuenta: [],
      Rproveedor: [],
      RCuentaBanco: [],
      Rmoneda: [],

      ListaTiposClientes: [],
      RLisCuenta: [],

      TotalCuenta: {
        credito: 0,
        debito: 0,
      },
      contabilidad: null,
      Cheques: {
        chequesPK: { secuencia: 0, documento: 0 },
        moneda: null,
        documentobancos: null,
        proveedor: null,
        referencia: "",
        beneficiario: "",
        concepto: "",
        cuentaBanco: null,
        valor: 0,
        tasa: 0,
        fecha: fechaNueva(),
        activo: true,
        solicitud: null,
        descripcion: "",
        periodocontable: null,
        impreso: false,
        anulado: false,
        marca: false,
        conciliacion: 0,
        chequesDetalleList: [],
        borrado: false,
        contabilidad: null,
      },

      EstadoBtn: {
        BarraTablaDinamicaAprir: true,
        nuevo: true,
        guardar: true,
        eliminar: false,
        anular: false,
        imprimir: true,
        vercuenta: false,
        guardartemporal: false,
        restaurartemporal: false,
        importarDato: false,
        cargarProducto: false,
        ImprimirproductoInv: false,
        etiquetaXfactura: false,
      },

      acti_Modal_cuenta: false,

      text: "",
      valid: false,
      BarraTablaDinamicaAprir: false,
      BarraSecundariaAprir: false,

      headers: [{ text: "Tipo Clientes", value: "descripcion" }],

      slotlist: [
        { dt0: "codigo", dt1: null, dt2: null, dt3: 1, dt4: "Código:" },
        {
          dt0: "descripcion",
          dt1: null,
          dt2: null,
          dt3: 1,
          dt4: "Descripcion:",
        },
        { dt0: "activo", dt1: null, dt2: null, dt3: 0.1, dt4: "Desactivo" },
      ],

      list: [],
      search: "",
      acti_tb_data: false,
      Elim_notif: {
        estado: false,
        nombre: "¿Seguro que desea anular este registro?",
      },

      notif: {
        estado: false,
        nombre: "",
        Exec: "",
      },

      aalert: {
        estado: false,
        color: null,
      },
    };
  },

  methods: {
    NumeroALetras,
    currencyFormatter,
    FormatoFecha,
    HoraFormatter,
    fechaNueva,
    onResize(e) {
      // this.windowSize = e;
    },

    // Un método que se llama cuando un componente secundario emite un evento.
    actualizarCompVerFactEditTran(e){
       this.ejecutarCompVerFactEditTran=JSON.parse(JSON.stringify(e.ejecutarCompVerFact))
       this.listFactModificar=JSON.parse(JSON.stringify(e.listArray))
    },

    // Tomando los datos del modal y asignándolos a las variables del componente padre.
    cerrarModalEditarFactura(e) {
      var ObjProRow = e.listArray.filter((ee) => ee.abono > 0);
          if (ObjProRow.length == 0) {
            this.modificar = false;
            this.dialogEditarCheque = false;
            return
          }
      this.TotalesCompVerFacturas=JSON.parse(JSON.stringify(e.Total))
      this.listFactModificar = e.listArray;
      if (e.listArray.filter((d) => d.abono > 0).length > 0) {
        var abo = 0,
          concepto = "Abono a Facturas : ";
        this.Cheques.chequesDetalleList = e.listArray.filter((d) => d.abono > 0);
        this.Cheques.chequesDetalleList.forEach((d) => {
          abo += d.abono;
          if (d.gastos != null) {
            concepto =
              concepto +
              "" +
              d.gastos.documentocontable.nombrecorto.trim() +
              "-" +
              d.gastos.gastosPK.secuencia +
              ", ";
          }
          if (d.facturasProveedores != null) {
            concepto =
              concepto +
              "" +
              d.facturasProveedores.documentoproveedor.nombrecorto.trim() +
              "-" +
              d.facturasProveedores.facturasProveedoresPK.codigo +
              ", ";
          }
          if (d.notacreditoproveedores != null) {
            concepto =
              concepto +
              "" +
              d.notacreditoproveedores.documentoproveedor.nombrecorto.trim() +
              "-" +
              d.notacreditoproveedores.notacreditoproveedoresPK.codigo +
              ", ";
          }
        });
        this.Cheques.concepto = concepto.substring(0, concepto.length - 2);
       /* if (this.Cheques.valor != abo) {
          this.Cheques.valor = abo;
          this.numerosLetras(abo);
        } else {
          //this.modificar=true
        }*/
      }
      this.Cheques.valor=JSON.parse(JSON.stringify(e.Total.abono))
      this.modificar = false;
      this.dialogEditarCheque = false;
      var _this=this;
      // if(e.editando==false){}
        setTimeout(function(){ _this.newCont() }, 300);
        setTimeout(function(){ 
      var RLIS_CUENTA=JSON.parse(JSON.stringify(_this.RLisCuenta))
      _this.numerosLetras(e.Total.valorpago)
      _this.Cheques.valor= JSON.parse(JSON.stringify(e.Total.valorpago)) 
    }, 900);
      
    },

    actualizarCompVerFact(e){
       this.modificar=JSON.parse(JSON.stringify(e.ejecutarCompVerFact))
       this.listFactVent=JSON.parse(JSON.stringify(e.listArray))
       //this.Tranferencia.valor=JSON.parse(JSON.stringify())

    },


    // Cerrando un modal y luego llamando a una función newCont()
    async cerrarModalVerFacturas(e) {
      this.TotalesCompVerFacturas=JSON.parse(JSON.stringify(e.Total))
      this.listFactVent = e.listArray;

      if (e.listArray.filter((d) => d.abono > 0).length > 0) {
        var abo = 0, concepto = "";
        this.Cheques.chequesDetalleList = e.listArray.filter((d) => d.abono > 0);
     /*   this.Cheques.chequesDetalleList.forEach((d) => {
          abo += d.abono;
          if (d.gastos != null) {
            // concepto =concepto +"" +d.gastos.documentocontable.nombrecorto.trim() +"-" +d.gastos.gastosPK.secuencia +", ";
             concepto =concepto +"" +d.gastos.factura.trim() +", ";

          }
          if (d.facturasProveedores != null) {
            // concepto =concepto +"" +d.facturasProveedores.documentoproveedor.nombrecorto.trim() +"-" +d.facturasProveedores.facturasProveedoresPK.codigo +", ";
             concepto =concepto +"" +d.facturasProveedores.referencia.trim() +", ";
          }
          if (d.notacreditoproveedores != null) {
             concepto =concepto +"" +d.notacreditoproveedores.documentoproveedor.nombrecorto.trim() +"-" +d.notacreditoproveedores.notacreditoproveedoresPK.codigo +", ";
          }
        });*/

        var SOLO_ABONO = e.listArray.filter((d) => d.abono > 0 && d.pendiente>d.abono)
        
        if(SOLO_ABONO.length>0){
          concepto = "Abono a Facturas : \n"
      await Promise.all(SOLO_ABONO.map(async (d) => { 
        if (d.gastos != null) {
             concepto =concepto +"" +d.gastos.factura.trim() +", ";
          }
          if (d.facturasProveedores != null) {
             concepto =concepto +"" +d.facturasProveedores.referencia.trim() +", ";
          }
          if (d.notacreditoproveedores != null) {
             concepto =concepto +"" +d.notacreditoproveedores.documentoproveedor.nombrecorto.trim() +"-" +d.notacreditoproveedores.notacreditoproveedoresPK.codigo +", ";
          }
     }));
    }

    var SOLO_SALDO = e.listArray.filter((d) => d.abono > 0 && d.pendiente <=d.abono)
    if(SOLO_SALDO.length>0){
      concepto+= (concepto.length>0?"\n Saldo a Facturas : \n": "Saldo a Facturas :\n");
      await Promise.all(SOLO_SALDO.map(async (d) => { 
        if (d.gastos != null) {
             concepto =concepto +"" +d.gastos.factura.trim() +", ";
          }
          if (d.facturasProveedores != null) {
             concepto =concepto +"" +d.facturasProveedores.referencia.trim() +", ";
          }
          if (d.notacreditoproveedores != null) {
             concepto =concepto +"" +d.notacreditoproveedores.documentoproveedor.nombrecorto.trim() +"-" +d.notacreditoproveedores.notacreditoproveedoresPK.codigo +", ";
          }
     }));

    }

        this.Cheques.concepto = concepto.substring(0, concepto.length - 2);

       /* if (this.Cheques.valor != abo) {
          this.Cheques.valor = abo;
          this.numerosLetras(abo);
        } else {
          //this.modificar=true
        }*/
      }
      this.Cheques.valor= e.Total.abono
      this.modificar = false;
      this.dialogVerFactura = false;
      var _this=this;
      if(e.editando==false){
        setTimeout(function(){ _this.newCont() }, 300);
        setTimeout(function(){ 
      var RLIS_CUENTA=JSON.parse(JSON.stringify(_this.RLisCuenta))
      _this.numerosLetras(e.Total.valorpago)
      _this.Cheques.valor= JSON.parse(JSON.stringify(e.Total.valorpago)) 
    }, 900);
      }
    

    },

    async cargarTodo() { },
    // Tomando los datos de la tabla y poniéndolos en el formulario.
    async datostabla(e) {
      this.editando=true
      this.RLisCuenta=[]
      var obj=JSON.parse(JSON.stringify(e))

      obj.fecha =e.nuevaFecha
      // obj.fecha = new Date(e.fecha).toJSON().split("T")[0];
      obj.secuenciaSecundaria = e.chequesPK.secuencia;
      if(e.hasOwnProperty('referencia')&& e.referencia!=null&&e.referencia.trim().length>0){
        obj.referencia = e.referencia;
      }else{
        obj.referencia = e.chequesPK.secuencia;
      
      }

      this.Cheques = JSON.parse(JSON.stringify(obj));
      

      if(obj.hasOwnProperty('ndAutomatico') && obj.ndAutomatico!=null){
        this.Cheques.ndAutomatico =obj.ndAutomatico
      }else{
        this.Cheques.ndAutomatico=false
      }
      
      this.numerosLetras(e.valor);

      
      this.listFactVent = this.Cheques.chequesDetalleList;
      if(this.Cheques.ndAutomatico==true){

        await Promise.all(this.listFactVent.map(async (element) => { 
        element.descuento=element.descuentoInformativoNd
        element.descuentoInformativoNd=0
        }));

      }
      

      if (e.proveedor != null) {
        this.findDetalle();
      } else {
        this.Cheques.proveedor = e.beneficiario;
      }
      this.modificar = false;
      this.acti_tb_data = false;

      if(e.conciliacion!=null && e.conciliacion>0 || e.impreso==true){
       this.disabledEditarFecha=true
      }else{
        this.disabledEditarFecha=false
      }

 
      var _this = this;
      setTimeout(function () {
          _this.getCrear();
        }, 500);

     /* if (this.contabilidad == null) {
        this.newCont();
      }*/
    },
  // Guardando los datos en la base de datos.
  async save() {
      if(this.Cheques.conciliacion!==null && this.Cheques.conciliacion>0){
        this.alerta(
          "Este Cheque esta conciliado no puede ser modificado",
          "error"
        );
        return;
      }
      if (this.TotalCuenta.credito != this.TotalCuenta.debito) {
        this.alerta(
          "El Total de Debito y Credito no Coincide en el Asiento Contable",
          "error"
        );
        return;
      }
      console.log('this.Cheques-->',this.Cheques)
      if (this.$refs.formCheques.validate()) {
    var reglaChe=(this.Cheques.referencia.hasOwnProperty('referencia') &&  this.Cheques.referencia === null || this.Cheques.referencia!=null && this.Cheques.referencia.trim().length==0)
    if (this.Cheques.cuentaBanco.chequeimpreso==false &&  reglaChe==true)  {
          this.alerta("Seleccione una secuencia para este cheque!", "error");
          return;
        }
        
   /* if (this.Cheques.cuentaBanco.chequeimpreso==false && (this.Cheques.secuenciaSecundaria === undefined || this.Cheques.secuenciaSecundaria === null) ) {
          this.alerta("Seleccione una secuencia para este cheque!", "error");
          return;
        }*/
       // this.Cheques.chequesPK.secuencia = this.Cheques.secuenciaSecundaria;

        EventBus.$emit("loading", true);

    if(this.editando==false && this.Cheques.cuentaBanco.chequeimpreso==false){
      if(this.Cheques.referencia!=null && this.Cheques.referencia.trim().length>0 && !isNaN(this.Cheques.referencia.trim())){
        var OBJ={
           // sec:this.Cheques.chequesPK.secuencia,
            sec:parseInt(this.Cheques.referencia.trim()),
            doc:this.Cheques.documentobancos.codigo
          }
         const result =  await this.verificarCheque(OBJ);
          if(result==true){
          this.alerta("Este numero de cheque ya existe.", "error")
          this.RedireccionFocus('RefTextFieldNocheque')
          this.Cheques.chequesPK.secuencia=0
            return ;
          }
      }

        }

        if(this.Cheques.chequesPK.secuencia>0 && this.listFactModificar.length>0){
          var ObjProRow = this.listFactModificar.filter((ee) => ee.abono > 0);
          if (ObjProRow.length > 0) {
            var ARRAY= JSON.parse(JSON.stringify(this.listFactVent)) 
            ARRAY.forEach(element => {
             var OBJELEM=JSON.parse(JSON.stringify(element))
             OBJELEM.abono=0
             OBJELEM.descuento=0
             OBJELEM.isr=0
             OBJELEM.retencion=0
            ObjProRow.push(OBJELEM)
            });
            // var LIST= ObjProRow.concat(this.RProductoCompraOfercta)
            //this.listFactModificar
            //this.listFactVent
            this.Cheques.chequesDetalleList=JSON.parse(JSON.stringify(ObjProRow))
          }  
        }

       
        if (this.Cheques.proveedor.codigo === undefined) {
          this.Cheques.beneficiario = this.Cheques.proveedor;
          this.Cheques.proveedor = null;
        } else {
          this.Cheques.beneficiario = this.Cheques.proveedor.nombre;
        }
        this.Cheques.contabilidad = this.contabilidad;
        if(this.RLisCuenta.length>0){
        this.Cheques.contabilidad.contabilidadDetalleList=this.RLisCuenta
        }

        //this.FinalizarSalvarChequeFacPen(this.Cheques)
      
        if (this.Cheques.chequesPK.documento > 0 && this.Cheques.ndAutomatico==true){
          if(this.Cheques.chequesDetalleList.length>0){
          this.FinalizarSalvarChequeFacPen(this.Cheques)
        }else{
          this.Cheques.ncAutomatico=false
          this.contSave(this.Cheques)
        }
        }else{
          this.FinalizarSalvarChequeFacPen(this.Cheques)
        }

        

    
      
      } else {
        this.alerta("Los campos en rojo son requeridos", "error");
      }
    },

    FinalizarSalvarChequeFacPen(CHEQUE){
      EventBus.$emit("loading", true);
      var CHE =JSON.parse(JSON.stringify(CHEQUE))
      var NOTA_DB_AUTO =this.$store.getters.GetConfiguracionGeneral.configCxp.activarNotasDebitoAutomatica
            var ObjProRow =  CHE.chequesDetalleList.filter((ee) => ee.abono>0 && ee.descuento >0);
            if(NOTA_DB_AUTO==true&& ObjProRow.length>0){
              CHE.ndAutomatico=NOTA_DB_AUTO
              this.getNotaDebitoAutomatica(CHE)
            }else{
              CHE.ncAutomatico=false
              this.contSave(CHE)
            }

    },

  
    async getNotaDebitoAutomatica(datos){
      var CHE = JSON.parse(JSON.stringify(datos))
       var NDAUTO= this.$store.getters.GetConfiguracionGeneral.configCxp.notasDebitoAutomatica
       var ObjProRow =  datos.chequesDetalleList.filter((ee) => ee.abono>0 && ee.descuento >0);
          var LISTA_NOTA_DB=[];



        await Promise.all(ObjProRow.map(async (element) => {   

          var VALORPORCIENTO =0;

          if(element.porcientodescuento=='p'){
            VALORPORCIENTO=((element.abono*element.descuento)/100)
          }else{
            VALORPORCIENTO=element.descuento
          }


var NotasDebito= {
notadebitoproveedoresPK:{ secuencia: 0, documento: 0 },
codigo : 0,
fecha : datos.fecha+"T04:00",
moneda : datos.moneda,
proveedor:datos.proveedor,
direccionEnvio : "",
concepto : "",
descuentoIndividual : 0,
tasa :datos.tasa ,
documentoproveedor:NDAUTO,
ncf : "",
valor : VALORPORCIENTO,
itbis : 0,
contabilidad:null,
referencia : "",
gasto : null,
activada : true,
activa : true,
facturasProveedores : element.facturasProveedores!=null?element.facturasProveedores:null,
notacreditoproveedores : element.notacreditoproveedores!=null?element.notacreditoproveedores:null,
gastos:element.gastos!=null?element.gastos:null,
devolucionproveedorList:[]

 }

          

            const resContab =  await this.getContabilidadNotaDB(NDAUTO.codigo,element.descuento,0);
            NotasDebito.contabilidad=resContab
            NotasDebito.contabilidad.fecha = datos.fecha+"T04:00";
            NotasDebito.contabilidad.documento = NDAUTO.codigo;
            LISTA_NOTA_DB.push(NotasDebito)
           /* console.log(NotasCredito)
             LISTA_NOTA_CR.push(NotasCredito)*/
              }));

             
   CHE.notasDebitoAutomatica=LISTA_NOTA_DB
await Promise.all(CHE.chequesDetalleList.filter((ee) => ee.abono>0 && ee.descuento >0).map(async (element) => { 
  element.descuentoInformativoNd=element.descuento
  element.descuento=0
}));

this.contSave(CHE)   
          // EventBus.$emit("loading", false);
          // this.ejecucion = true
    },


    getContabilidadNotaDB(CODDOC,VALOR,ITBIS){
        return  new Promise((resolve, reject) => {
          this.$axios.get(this.$hostname + this.$hName + '/contabilidad/crear-contabilidad-cxp/' + CODDOC + '/' + VALOR + '/' + ITBIS + '/0/0/0',
            { headers: this.$store.getters.GetheadersAxios })
            .then(res => { 
              // console.log('cont-->'+res.data)
              resolve(res.data)
              
              // this.llenarcuenta(res.data)
            })
            .catch(error => {
            resolve(null)
           });
  });

      },



contSave(CHEQUE){
  // console.log('Ver-contSave-->',CHEQUE)
  // return

console.log('Cheque--->',CHEQUE)
  
  this.$axios
          .post(
            this.$hostname + this.$hName + "/cheques/salvar",
            CHEQUE,
            { headers: this.$store.getters.GetheadersAxios }
          )
          .then((res) => {
            if (res.data.cuentaBanco.chequeimpreso==true) {
             /* res.data.valorLetra = this.letras;
              res.data.contabilidad = this.contabilidad;
              res.data.fecha =res.data.nuevaFecha*/
              
              var _this = this;
        setTimeout(function () {
          _this.datostabla(res.data)
          _this.NotifMessageImprimir() 
        }, 800);
             // res.data.fecha = new Date(res.data.fecha).toJSON().split("T")[0];

              //this.imprimirCheques(res.data, "cheque");
            }
            this.alerta("Dato guardado correctamente!", "bien")
          })
          .catch((error) => this.alerta("Dato no guardado", "error"));

},


    // Una función que se llama cuando se hace clic en un botón.
    NotifMessageAnular() {
      if (
        this.Cheques.chequesPK.secuencia != 0 &&
        this.Cheques.chequesPK.secuencia != null
      ) {
        var notif = {
          estado: true,
          nombre: "¿Seguro que desea anular este registro?",
          Exec: "anularcheques",
        };
        this.Exec_notif(notif);
      } else {
        this.alerta("Seleccione un cheque para anular", "error");
      }
    },

    NotifMessageEliminar() {
      if (
        this.Cheques.chequesPK.secuencia != 0 &&
        this.Cheques.chequesPK.secuencia != null
      ) {
        var notif = {
          estado: true,
          nombre: "¿Seguro que desea eliminar este registro?",
          Exec: "eliminarcheques",
        };

        this.Exec_notif(notif);
      } else {
        this.alerta("Seleccione un cheque para eliminar", "error");
      }
    },

    NotifMessageImprimir() {
  
  var notif = {
    estado: true,
    nombre: "¿ Desea Imprimir este registro?",
    Exec: "imprimirAutoCheque",
  };
  this.Exec_notif(notif);
},


imprimirAutomCheque(){

        var _this = this;
        setTimeout(function () {
          _this.ReimprimirCheq()
        }, 500);
    },


   // Convertir el número a letras.
    numerosLetras(e) {
      this.letras = this.NumeroALetras(e);
    },
    Exec_notif(e) {
      //console.log(e)
      this.notif = JSON.parse(JSON.stringify(e));
    },

   // Envío de una solicitud POST al servidor.
   async eliminar() {
      if (this.Cheques.chequesPK.secuencia != 0) {
        EventBus.$emit("loading", true);
        if (this.Cheques.proveedor.codigo === undefined) {
          this.Cheques.beneficiario = this.Cheques.proveedor;
          this.Cheques.proveedor = null;
        } else {
          this.Cheques.beneficiario = this.Cheques.proveedor.nombre;
        }

        const result =  await this.verificarMovimientoCheque();
    if(result==true){this.alerta("Dato no eliminar correctamente, Cheque conciliado", "error")
      return ;
    }


        this.$axios
          .post(
            this.$hostname + this.$hName + "/cheques/borrar",
            this.Cheques,
            { headers: this.$store.getters.GetheadersAxios }
          )
          .then((res) => {
            this.alerta("Datos eliminados correctamente!", "bien");
          })
          .catch((error) => this.alerta("Dato no eliminado", "error"));
      }
    },

verificarMovimientoCheque(){

  var bool=false;
  //False: No tiene movimiento/True:contieneMovimiento
  return  new Promise((resolve, reject) => {
  var linkverificar=`/${this.Cheques.chequesPK.secuencia}/${this.Cheques.chequesPK.documento}`
        this.$axios.get(
          this.$hostname + this.$hName +"/cheques/VerificarMovimientoConciliacion"+linkverificar,
          { headers: this.$store.getters.GetheadersAxios }
        )
        .then((res) => {
          // if(res.data==true){this.alerta("Dato no anulado correctamente, Cheque conciliado", "error")}
         // else{this.VerificarMovimNotDebito(linkverificar)}
          resolve(res.data)
        })
        .catch((error) => {
          resolve(false)
          this.alerta(error, "error")}); 
});

// return bar.then(() => {return bool});

},

    // Envío de una solicitud POST al servidor.
    async anular() {
      
      if (this.Cheques.chequesPK.secuencia != 0) {
        EventBus.$emit("loading", true);
        if (this.Cheques.proveedor.codigo === undefined) {
          this.Cheques.beneficiario = this.Cheques.proveedor;
          this.Cheques.proveedor = null;
        } else {
          this.Cheques.beneficiario = this.Cheques.proveedor.nombre;
        }

    const result =  await this.verificarMovimientoCheque();
    if(result==true){this.alerta("Dato no anulado correctamente, Cheque conciliado", "error")
      return ;
    }

        this.$axios
          .post(
            this.$hostname + this.$hName + "/cheques/anular",
            this.Cheques,
            {
              headers: this.$store.getters.GetheadersAxios,
            }
          )
          .then((res) => this.alerta("Dato anulado correctamente!", "bien"))
          .catch((error) => this.alerta("Dato no anulado", "error"));
      }
    },
    imprimirList() {
      this.datosImprecion.codigo = this.Cheques.chequesPK.secuencia;
      this.datosImprecion.documento = this.Cheques.chequesPK.documento;
      this.datosImprecion.letras = this.letras;

      this.dialogimprimirList = true;
    },
    // Retorna los datos del componente ModalReporteBasico
    DDataReporteBasico(e) {
      this.dialogimprimirList = e;
    },
    // Crear un nuevo objeto y asignarlo a la propiedad de datos de la instancia de Vue.
    newwnuevo() {

      this.editando=false
      this.disabledEditarFecha=false
      this.BarraTablaDinamicaAprir = false;
      this.RLisCuenta = [];
      this.TotalCuenta = { credito: 0, debito: 0 };

      this.Cheques = {
        chequesPK: { secuencia: 0, documento: 0 },
        moneda: null,
        documentobancos: null,
        proveedor: null,
        referencia: "",
        beneficiario: "",
        concepto: "",
        cuentaBanco: null,
        valor: 0,
        tasa: 0,
        fecha: fechaNueva(),
        activo: true,
        solicitud: null,
        descripcion: "",
        periodocontable: null,
        impreso: false,
        anulado: false,
        marca: false,
        conciliacion: 0,
        chequesDetalleList: [],
        borrado: false,
        contabilidad: null,
        secuenciaSecundaria:0
      };


    this.TotalesCompVerFacturas={
        totalAbono:0,
  totalAb:0,
  abono:0,
  descuento:0,
  retencion:0,
  isr:0,
  totalPendiente:0,
  TotalAPagar:0,
  original:0,
  pendiente:0,       
  notadebito:0,
  valorpago:0,
  balance:0
      }

this.listFactVent=[]
this.listFactModificar=[]

      EventBus.$emit("loading", false);
      this.getMonedaBase()

      //    EventBus.$emit("actualizaBarraBusqueda2");
    },
    // Llamar a una API y obtener los datos de la API y almacenarlos en la variable RCuentaBanco.
    cargarCuentaBanco() {
      this.$axios
        .get(this.$hostname + this.$hName + "/cuentabanco/findallactivo", {
          headers: this.$store.getters.GetheadersAxios,
        })
        .then((res) => (this.RCuentaBanco = res.data));
    },

    // Comprobando si el valor de la selección es nulo o indefinido.
    SelctCbCuentaBanco() {
      if (this.Cheques.cuentaBanco == null) {
        this.Cheques.cuentaBanco = "";
      }
      if (this.Cheques.cuentaBanco.codigo == undefined) {
        this.alerta("Seleccione un Cuenta Banco", "error");
        this.Cheques.cuentaBanco = "";
        return;
      }
    },

    // Comprobando si el valor de la selección es nulo o indefinido.
    SelctCbCuentaBancoFiltro() {
      if (this.Modallfiltro.cuentaBanco == null) {
        this.Modallfiltro.cuentaBanco = "";
      }
      if (this.Modallfiltro.cuentaBanco.codigo == undefined) {
        this.alerta("Seleccione un Cuenta Banco", "error");
        this.Modallfiltro.cuentaBanco = "";
        return;
      }
    },

    // Comprobando si el valor de la selección es nulo o indefinido.
    SelctCbCuentaBancoReimprimir() {
      if (this.ModallReimprCheqRan.cuentaBanco == null) {
        this.ModallReimprCheqRan.cuentaBanco = "";
      }
      if (this.ModallReimprCheqRan.cuentaBanco.codigo == undefined) {
        this.alerta("Seleccione un Cuenta Banco", "error");
        this.ModallReimprCheqRan.cuentaBanco = "";
        return;
      }
    },

    // Obtener los datos de la base de datos y completar el cuadro de selección.
    findDocumentoBanco() {
      if (
        this.Cheques.cuentaBanco == null ||
        this.Cheques.cuentaBanco.codigo === undefined
      ) {
        return;
      }

      this.$axios
        .get(
          this.$hostname +
          this.$hName +
          "/documentobanco/documentocheques/" +
          this.Cheques.cuentaBanco.codigo,
          { headers: this.$store.getters.GetheadersAxios }
        )
        .then((res) => {
          if (res.data == "") {
            this.alerta("Esta cuenta no tiene documento de cheque!", "error");
            return;
          }
          if (res.data.codigo == undefined) {
            this.alerta("Esta cuenta no tiene documento de cheque!", "error");
            return;
          }
          this.Cheques.documentobancos = res.data;
        });
    },
   // Obtener los datos de la base de datos y llenar el formulario con los datos.
    getCrear() {
      if (this.Cheques.valor <= 0 && this.Cheques.anulado == false) {
        return;
      }
      if (this.$refs.formCheques.validate()) {
        if (this.Cheques.chequesPK.secuencia == 0) {
          this.newCont();
        }
        if (this.Cheques.chequesPK.secuencia != 0) {
          this.$axios
            .get(
              this.$hostname +
              this.$hName +
              "/contabilidad/findContabilidad/" +
              this.Cheques.chequesPK.documento +
              "/" +
              this.Cheques.chequesPK.secuencia +
              "/" +
              this.Cheques.documentobancos.operacion.codigo,
              { headers: this.$store.getters.GetheadersAxios }
            )
            .then((res) => {
              if (res.data.toString().length > 0) {
                this.llenarcuenta(res.data);
              }
            });
        }
      } else {
      }
    },
    // Llamando a un método que está en el backend, el cual se encarga de crear un nuevo registro
    // contable.
    newCont() {
      this.$axios
        .get(
          this.$hostname +
          this.$hName +
          "/contabilidad/crear-contabilidad-ban-multimoneda/" +
          this.Cheques.documentobancos.codigo +
          "/" +
          this.Cheques.valor +
          "/"+this.TotalesCompVerFacturas.retencion+
          "/"+this.TotalesCompVerFacturas.descuento+
          "/"+this.TotalesCompVerFacturas.isr+
          "/0/1",
          { headers: this.$store.getters.GetheadersAxios }
        )
        .then((res) => {
          this.llenarcuenta(res.data);
        });
    },
   // Tomando los datos del componente secundario y colocándolos en el componente principal.
    llenarcuenta(e) {
      if (this.contabilidad === null) {
        this.contabilidad = e;
        this.contabilidad.fecha = this.Cheques.fecha;
        this.contabilidad.documento = this.Cheques.documentobancos.codigo;
      }
      this.RLisCuenta = JSON.parse(JSON.stringify(e.contabilidadDetalleList));
      // this.form.RLisCuenta=this.RLisCuenta
      this.objeTem = JSON.parse(JSON.stringify(this.RLisCuenta));
      this.calcularTotalcuenta(1);
    },
    // Cálculo del total de las columnas de crédito y débito.
    calcularTotalcuenta(e) {
      this.TotalCuenta.credito = 0;
      this.TotalCuenta.debito = 0;
      if (e.toString().length > 0) {
        this.RLisCuenta.forEach((element) => {
          this.TotalCuenta.credito += element.credito;
          this.TotalCuenta.debito += element.debito;
        });
      }
    },
    // Llamar a una API y obtener los datos de la API y almacenarlos en la variable Rproveedor.
    cargarProveedor() {
      this.$axios
        .get(
          this.$hostname + this.$hName + "/proveedores/findallbyDesc/25/0/*",
          { headers: this.$store.getters.GetheadersAxios }
        )
        .then((res) => (this.Rproveedor = res.data));
    },
   // Un método que se llama cuando el usuario selecciona un valor de una lista desplegable.
    SelctCbProveedor() {
      if (
        this.Cheques.proveedor != null &&
        this.Cheques.proveedor.codigo != undefined
      ) {
        this.datosProveedor.nombre = this.Cheques.proveedor.nombre;
        this.datosProveedor.codigo = this.Cheques.proveedor.codigo;
        this.findDetalle();
      }
    },
    // Hacer una llamada al backend para obtener los datos que se mostrarán en la tabla.
    findDetalle() {
      if (this.Cheques.chequesPK.secuencia == 0) {
        this.$axios
          .get(
            this.$hostname +
            this.$hName +
            "/cheques/detalle-pagar/" +
            this.Cheques.proveedor.codigo,
            { headers: this.$store.getters.GetheadersAxios }
          )
          .then((res) => (this.listFactVent = res.data));
      } else if (this.Cheques.chequesPK.secuencia != 0) {
        this.$axios
          .get(
            this.$hostname +
            this.$hName +
            "/cheques/detalle-pagar/" +
            this.Cheques.proveedor.codigo,
            { headers: this.$store.getters.GetheadersAxios }
          )
          .then((res) => (this.listFactModificar = res.data));
      }
    },
    // Hacer una llamada API al servidor y devolver los datos al cliente.
    CbFilProveedor(e) {
      if (e.target.value.length >= 0) {
        this.$axios
          .get(
            this.$hostname +
            this.$hName +
            "/proveedores/findallbyDesc/25/0/" +
            (e.target.value === "" ? "*" : e.target.value),
            { headers: this.$store.getters.GetheadersAxios }
          )
          .then((res) => (this.Rproveedor = res.data));
      }
    },

    buscarPeriodo(e) {
      if (this.Cheques.fecha != null) {
        this.$axios
          .get(
            this.$hostname +
            this.$hName +
            "/contabilidad/findPeriodo/" +
            this.Cheques.fecha,
            { headers: this.$store.getters.GetheadersAxios }
          )
          .then((res) => {
            if (res.data === "") {
              this.alerta("No existen periodo para esta fecha", "error");
            }
            this.periodoDetalle = res.data;
            if (this.periodoDetalle.cerrado === true) {
              this.alerta(
                "Periodo esta cerrado no puede trabajar en esta fecha",
                "error"
              );
            }
          })
          .catch((err) => {
            console.log(err),
              this.alerta("No existen periodo para esta fecha", "error");
          });
      }
      this.getCrear();
    },
    // Envío de una solicitud POST al servidor.
    imprimirCheques(datos, operacion) {
      datos.operacion = operacion;
      this.$axios
        .post("http://localhost:15999/impresora/imprimir", datos)
        .then((res) => {
          ///this.limpiarPuntoVentaCompleto();
        })
        .catch((error) =>{

        } );
    },
    getMonedaBase(){
var MONEDA=JSON.parse(JSON.stringify(this.$store.getters.GetConfiguracionGeneral.config.monedabase))
  this.Cheques.moneda= MONEDA
  this.Cheques.tasa=MONEDA.tasa
},

cargarMoneda() {
      this.$axios
        .get(this.$hostname + this.$hName + "/monedas/findallActivos/", {
          headers: this.$store.getters.GetheadersAxios,
        })
        .then((res) => (this.Rmoneda = res.data));
    },
    SelctCbMoneda() {
var _this = this;
      setTimeout(function () {
        if (_this.Cheques.moneda == null) {
          _this.Cheques.moneda = "";
        }

        if (!_this.Cheques.moneda.hasOwnProperty('codigo')) {
          _this.Cheques.moneda = "";
          _this.Cheques.moneda = null;
          _this.getMonedaBase()
          return;
        }
      }, 300);
},

ChangeMonedaTasa(e) {
  if (typeof e === 'object' && e != null) {
      this.Cheques.tasa = JSON.parse(JSON.stringify(e.tasa))
 
  }
},


    vercuenta() {
      if (!this.$refs.formDoc.validate() || !this.$refs.formValor.validate()) {
        this.alerta("Los campos en rojo son requeridos", "error");
        return;
      }

      if (this.$refs.formCheques.validate()) {
        this.objeTem = JSON.parse(JSON.stringify(this.RLisCuenta));
        this.TotalCuenta = JSON.parse(JSON.stringify(this.TotalCuenta));
        this.acti_Modal_cuenta = !this.acti_Modal_cuenta;
      } else {
        this.alerta("Los campos en rojo son requeridos", "error");
      }
    },
    // Convertir el valor de la entrada a mayúsculas.
    convertirMayusculaReferencia(e) {
      if (e.target.value.length > 0) {
        this.Cheques.referencia = JSON.parse(
          JSON.stringify(e.target.value.toString().toUpperCase())
        );
      }
    },
// Un método que se llama desde Vue HTML.
    CCuentasContables(e, t) {
      this.RLisCuenta = JSON.parse(JSON.stringify(e));
      this.TotalCuenta = JSON.parse(JSON.stringify(t));
    },
    getImgUrl(e) {
      var images;
      images = require("@/assets/img/" + e);
      return images;
    },

  // Hacer una llamada al backend para obtener una lista de cheques y luego imprimirlos.
    ReimprimirChequesRango() {
      this.$axios
        .get(
          this.$hostname +
          this.$hName +
          "/cheques/findbyrango/" +
          this.ModallReimprCheqRan.cuentaBanco.cuenta +
          "/" +
          this.ModallReimprCheqRan.Secini +
          "/" +
          this.ModallReimprCheqRan.Secfin,
          { headers: this.$store.getters.GetheadersAxios }
        )
        .then((res) => {
          res.data.forEach(d => {
          //  d.fecha = new Date(d.fecha).toJSON().split("T")[0]
            d.fecha =d.fecha.nuevaFecha
            d.valorLetra = this.NumeroALetras(d.valor);
            d.impresora = "";
          });
          this.listCheques = res.data;
          var datos = { lista: res.data };
          this.imprimirCheques(datos, "listaCheque");
        });
    },

    // Creando un nuevo objeto llamado Cheques y asignándole los valores de contabilidad y valorLetra.
    ReimprimirCheq() {
      this.Cheques.contabilidad = this.contabilidad;
      this.Cheques.valorLetra = this.letras;
      this.imprimirCheques(this.Cheques, "cheque");
    },

    // Crear un nuevo objeto y asignarle valores.
    RehacerCheque() {
      this.Cheques.chequesPK = { secuencia: 0, documento: 0 };
      this.Cheques.anulado = false;
      this.Cheques.activo = true;
      if (this.Cheques.proveedor.codigo === undefined) {
        this.Cheques.beneficiario = this.Cheques.proveedor;
        this.Cheques.proveedor = null;
      } else {
        this.Cheques.beneficiario = this.Cheques.proveedor.nombre;
      }
      this.$axios
        .get(
          this.$hostname +
          this.$hName +
          "/contabilidad/crear-contabilidad-ban-multimoneda/" +
          this.Cheques.documentobancos.codigo +
          "/" +
          this.Cheques.valor +
          "/0/0/0/0/1",
          { headers: this.$store.getters.GetheadersAxios }
        )
        .then((res) => {
          this.llenarcuenta(res.data);
        });
      this.Cheques.chequesDetalleList = this.listFactVent;
      this.Cheques.contabilidad = this.contabilidad;
      this.$axios
        .post(this.$hostname + this.$hName + "/cheques/salvar", this.Cheques, {
          headers: this.$store.getters.GetheadersAxios,
        })
        .then((res) => this.alerta("Dato guardado correctamente!", "bien"))
        .catch((error) => this.alerta("Dato no guardado", "error"));
    },

    ImprimRehacerCheque() {
      alert("Imprimiendo cheque");
    },

    // Establecer la fecha a la fecha de hoy si el usuario no ha ingresado una fecha.
    BlurDesde() {
      if (this.datosImprecionAll.desde.toString().length == 0) {
        this.datosImprecionAll.desde = this.fechaNueva();
      }
    },
 // Establecer la fecha a la fecha de hoy si el usuario no ha ingresado una fecha.
    BlurHasta() {
      if (this.datosImprecionAll.hasta.toString().length == 0) {
        this.datosImprecionAll.hasta = this.fechaNueva();
      }
    },

    abrirTabla2() {
      // console.log("entro");
      this.BarraSecundariaAprir = !this.BarraSecundariaAprir;
    },

   // muestra un panel con una tabla de datos
    abrirTabla() {
      this.BarraTablaDinamicaAprir = !this.BarraTablaDinamicaAprir;
      if (this.BarraTablaDinamicaAprir == true) {
        this.Limpiarfiltro();
      }
    },

   // Un método que se llama cuando se hace clic en un botón
   // y muestra un modal con una tabla de datos.
    actualiza_tb_ver() {
      this.acti_tb_data = !this.acti_tb_data;
      this.Limpiarfiltro();
    },

    // Un método que se llama cuando el usuario hace clic en el botón 
    // y restablace las variable de la paginacion de la tabla de datos
    Limpiarfiltro() {
      var Pg = {
        itemsLength: this.TbPaginator.itemsLength,
        itemsPerPage: this.TbPaginator.itemsPerPage,
        page: 1,
        pageCount: 1,
        pageStart: 0,
        pageStop: this.TbPaginator.itemsPerPage,
      };

      this.Modallfiltro = {
        codigo: null,
        Desde: null,
        Hasta: null,
        proveedor: null,
        cuentaBanco: null,
      };

      this.ListItems = [];
      this.TbTotalItem = 0;
      this.TbPaginator = JSON.parse(JSON.stringify(Pg));
      this.CargarListTb();
      this.dialogFiltros = false;
    },

    BuscarFiltros() {
      var Pg = {
        itemsLength: this.TbPaginator.itemsLength,
        itemsPerPage: this.TbPaginator.itemsPerPage,
        page: 1,
        pageCount: 1,
        pageStart: 0,
        pageStop: this.TbPaginator.itemsPerPage,
      };

      if (
        this.Modallfiltro.codigo != null &&
        this.Modallfiltro.codigo.toString().length == 0
      ) {
        this.Modallfiltro.codigo = null;
      }

      this.TbPaginator = JSON.parse(JSON.stringify(Pg));
      this.CargarListTb();
      this.dialogFiltros = false;
    },

    // Un método que se llama cuando el usuario cambia la cantidad de elementos por página en el
    // componente de paginación.
    cargarPaginacionAll(e) {
      if (e.itemsPerPage == -1) {
        e.itemsPerPage = 15;
      }
      this.CargarPaginacion(e, this.Modallfiltro.codigo);
    },

   // Un método que se llama cuando el usuario cambia la página de la tabla.
    CargarPaginacion(e, s) {
      this.Modallfiltro.codigo = JSON.parse(JSON.stringify(s));

      if (e.itemsPerPage == -1) {
        e.itemsPerPage = 15;
      }
      //var pg =
      this.TbPaginator = JSON.parse(JSON.stringify(e));
      this.CargarListTb();
    },

    CargarListTb() {
      if (
        this.Modallfiltro.codigo != null &&
        this.Modallfiltro.codigo.toString().length == 0
      ) {
        this.Modallfiltro.codigo = null;
      }

      var pro = null;
      var Cuen = null;
      if (this.Modallfiltro.cuentaBanco != null) {
        Cuen = this.Modallfiltro.cuentaBanco.codigo;
      }

      if (this.Modallfiltro.proveedor != null) {
        if (this.Modallfiltro.proveedor.toString().length == 0) {
          this.Modallfiltro.proveedor = null;
        } else {
          pro = this.Modallfiltro.proveedor;
        }

        if (this.Modallfiltro.proveedor.hasOwnProperty("codigo")) {
          pro = this.Modallfiltro.proveedor.nombre;
        }
      }
      var obj =
        this.TbPaginator.itemsPerPage +
        "/" +
        this.TbPaginator.pageStart +
        "/" +
        this.Modallfiltro.codigo +
        "/" +
        pro +
        "/" +
        Cuen +
        "/" +
        this.Modallfiltro.Desde +
        "/" +
        this.Modallfiltro.Hasta;
      this.$axios
        .get(
          this.$hostname + this.$hName + "/cheques/findListChequePlus/" + obj,
          { headers: this.$store.getters.GetheadersAxios }
        )
        .then((res) => {
          (this.ListItems = res.data.ListItems),
            (this.TbTotalItem = res.data.TbTotalItem);
        });
    },

   // Establecer los valores del objeto en 0.
    ChangeBeneficiario(){
      this.Cheques.concepto=""
      this.listFactVent = []
      this.Cheques.valor=0
  this.TotalesCompVerFacturas={
  totalAbono:0,
  totalAb:0,
  abono:0,
  descuento:0,
  retencion:0,
  isr:0,
  totalPendiente:0,
  TotalAPagar:0,
  original:0,
  pendiente:0,       
  notadebito:0,
  valorpago:0,
  balance:0
      }
    },

   // Llamar a un método en un componente secundario.
    MethoEjecutarCompVerFact(){
     
     if(this.Cheques.chequesPK.secuencia!= 0){
       this.modificar=false
       
       var _this = this;
             setTimeout(function () {
               _this.$refs.CompVerFactt.calculartotal()
              // _this.ListProdVer()
             }, 500);
     
     }else{
       this.modificar=true
     }
         },

 cargarDocumento() {

this.$axios.get(this.$hostname + this.$hName + '/documentobanco/findall',
  { headers: this.$store.getters.GetheadersAxios })
  .then(res => {
   this.RDocumento=[]
   this.RDocumento = res.data.filter((ee) => ee.operacion.codigo ==14 );
  
  
  })
},


  SelctCbReportDocumento() {

var _this = this;
setTimeout(function () {
  if (_this.datosImprecionAll.documentos == null) {
    _this.datosImprecionAll.documentos = "";
  }

  if (!_this.datosImprecionAll.documentos.hasOwnProperty('codigo')) {
    _this.datosImprecionAll.documentos = "";
    _this.datosImprecionAll.documentos = null;
    return;
  }
}, 300);

},




    OpenImprimirList(){
      this.dialogimprimirListFilt=true
      var _this=this;
      setTimeout(function(){ 
        _this.$refs.ModRepFilt.CrearObjecto = _this.validarObjecto;
      }, 300);
    },

    validarObjecto() {

if (!this.$refs.formImpList.validate()) {
  this.$refs.ModRepFilt.detener = true;
  return;
} else {
  this.$refs.ModRepFilt.detener = false;
}

// report: c.anulado = false and c.anulado <> true and d.operacion=14 and $P!{where}
//String where = "c.secuencia > 0  and c.fecha >='" + f1 + "' and c.fecha <= '" + f2 + "'";

// var WHERE=` c.secuencia > 0 and d.fecha >= '${this.datosImprecionAll.desde}' and d.fecha <= '${this.datosImprecionAll.hasta}' `

var WHERE=` c.secuencia > 0 and c.fecha >= '${this.datosImprecionAll.desde}' and c.fecha <= '${this.datosImprecionAll.hasta}' and d.operacion=14 `
var CONDUCION=`Desde: ${this.FormatoFecha(this.datosImprecionAll.desde)} \t Hasta: ${this.FormatoFecha(this.datosImprecionAll.hasta)} \n`

if(this.datosImprecionAll.documentos!=null){
  WHERE+=` and c.documento=${this.datosImprecionAll.documentos.codigo}`
  CONDUCION+=`Documento: ${this.datosImprecionAll.documentos.descripcion} \t`
}

if(this.datosImprecionAll.opcion==1){CONDUCION+=`Opcion: Todos ` }
if(this.datosImprecionAll.opcion==2){CONDUCION+=`Opcion: Activos`
WHERE+=` and c.activo=true and c.anulado=false `
}
if(this.datosImprecionAll.opcion==3){CONDUCION+=`Opcion: Anulados`
  WHERE+=` and c.activo=false and c.anulado=true`
 }

this.datosImprecionAll.where=WHERE
this.datosImprecionAll.condicion=CONDUCION

},

KeyUpCantidad(e) {
      if (e.target.value.length == 0) {
        e.target.value = 0;
      }
      if (e.key) {
        var mynumeral = require("numeral");
        e.target.value = mynumeral(e.target.value).value();
        if (e.target.value == null) {
          e.target.value = 0;
        }
      } else {
        e.key = "";
      }
      if (e.target.value.length == 0) {
        e.target.value = 0;
        // e.key = 0;
      }
    },

    verificarCheque(data){

var bool=false;
//False: No tiene movimiento/True:contieneMovimiento
return  new Promise((resolve, reject) => {
// var linkverificar=`/${this.Cheques.chequesPK.secuencia}/${this.Cheques.chequesPK.documento}`
var linkverificar=`/${data.sec}/${data.doc}`
this.$axios.get(
        this.$hostname + this.$hName +"/cheques/VerificarCheque"+linkverificar,
        { headers: this.$store.getters.GetheadersAxios }
      )
      .then((res) => {
        // if(res.data==true){this.alerta("Dato no anulado correctamente, Cheque conciliado", "error")}
       // else{this.VerificarMovimNotDebito(linkverificar)}
        resolve(res.data)
      })
      .catch((error) => {
        // resolve(true)
        this.Cheques.chequesPK.secuencia=0
        EventBus.$emit("loading", false);
        this.alerta(error, "error")}); 
});

// return bar.then(() => {return bool});

},


RedireccionFocus(e) {
      var _this = this;
      setTimeout(function () { _this.$refs[e].focus() }, 300)
    },

     

    alerta(e, l) {
      if (l == "bien") {
        (this.aalert.estado = true),
          (this.aalert.color = "success"),
          (this.aalert.nombre = e);

        this.newwnuevo();
      }

      if (l == "error") {
        (this.aalert.estado = true),
          (this.aalert.color = "error"),
          (this.aalert.nombre = e);
        EventBus.$emit("loading", false);
        this.ejecucion = false;
      }
    },

    async filtro() {
      await this.$axios
        .get(
          this.$hostname +
          this.$hName +
          "/cheques/findallDesc/" +
          (this.search == "" ? "A" : this.search) +
          "/15/0",
          { headers: this.$store.getters.GetheadersAxios }
        )
        .then((res) => {
          this.list = res.data;
        });
    },
  },

  computed: {
    ActivarBotones() {
      if (this.Cheques.chequesPK.secuencia != 0) {
        if (this.Cheques.anulado == true) {
          this.EstadoBtn = {
            BarraTablaDinamicaAprir: true,
            nuevo: true,
            guardar: false,
            eliminar: true,
            anular: false,
            imprimir: false,
            vercuenta: false,
            guardartemporal: false,
            restaurartemporal: false,
            importarDato: false,
            cargarProducto: false,
            ImprimirproductoInv: false,
            etiquetaXfactura: false,
          };
        } else {
          if (
            this.Cheques.cuentaBanco != null &&
            !this.Cheques.cuentaBanco.chequeimpreso
          ) {
            this.EstadoBtn = {
              BarraTablaDinamicaAprir: true,
              nuevo: true,
              guardar: true,
              eliminar: true,
              anular: true,
              imprimir: true,
              vercuenta: false,
              guardartemporal: false,
              restaurartemporal: false,
              importarDato: false,
              cargarProducto: false,
              ImprimirproductoInv: false,
              etiquetaXfactura: false,
            };
          } else {
            this.EstadoBtn = {
              BarraTablaDinamicaAprir: true,
              nuevo: true,
              guardar: true,
              eliminar: false,
              anular: true,
              imprimir: true,
              vercuenta: false,
              guardartemporal: false,
              restaurartemporal: false,
              importarDato: false,
              cargarProducto: false,
              ImprimirproductoInv: false,
              etiquetaXfactura: false,
            };
          }
        }
      } else {
        this.EstadoBtn = {
          BarraTablaDinamicaAprir: true,
          nuevo: true,
          guardar: true,
          eliminar: false,
          anular: false,
          imprimir: false,
          vercuenta: false,
          guardartemporal: false,
          restaurartemporal: false,
          importarDato: false,
          cargarProducto: false,
          ImprimirproductoInv: false,
          etiquetaXfactura: false,
        };
      }

      this.windowSize = JSON.parse(
        JSON.stringify(this.$store.getters.GetwindowSize)
      );
      //EventBus.$emit("ActDesaBotones", json);
    },
  },
};
</script>

<style>

</style>
